import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    private httpService: HttpService,
    private http: HttpClient
  ) { }

  loginLog(email: string, success: boolean, msg: string) {
    console.log(msg);
    const url = environment.apiUrl + '/login-log';
    const log = {
        'emailAddress': email,
        'isSuccessful': success,
        'errorMessage': msg
    };

    this.http.post(url, log, this.httpService.getHttpOptions()).subscribe();
  }

  /* Pass null as error for msg only error-log */
  log(component: string, method: String, error, errorMsg = '', print = false, alertPopUp = false) {
    const errType = component + '::' + method;
    if (error) {
        if (!error.error || !error.error.message) {
            errorMsg = errorMsg + '. STATUS: ' + error.status + '. NO MSG FOUND PRINTING ERROR OBJ: ' + JSON.stringify(error);
        } else {
            errorMsg = errorMsg + '. STATUS: ' + error.status + '. MSG: ' + error.error.message;
        }
    }

    if (print) {
        console.error(errType + ' - ' + errorMsg);
    }

    this.writelog(errType, errorMsg);
  }

  writelog(errType, errString) {
    const url = environment.apiUrl + '/errorlog';
    const log = {
        'errorType': errType,
        'errorString': errString
    };
    this.http.post(url, log, this.httpService.getHttpOptions())
      .subscribe(() => {
      }, () => {
        console.log('writing to error log failed!');
      });
  }

}
