import { EventEmitter, Inject, Injectable } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

import { AreaPermission } from '../dtos/areaPermission';
import { User } from '../dtos/user';
import { UserTypeEnum } from '../dtos/user';
import { GlobalService } from './global.service';
import { SessionName } from '../dtos/session';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    /** Available before company is selected */
    private userEmail: string;
    
    public signedIn = false;
    isLoggedInChanged = new EventEmitter<boolean>();

    // truth engine user as opposed to msal user
    // before a company is selected, will only contain the email
    private currentUser: User;

    areaPermissions: AreaPermission[] = [];
    subscriptionSuccess: any;
    subscriptionFail: any;

    versionIncorrect = false; // app version checked by refresh guard
    currentVersion: string;
    correctVersion: string;

    constructor(
        private globalService: GlobalService,
        private msalService: MsalService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
    ) { }

    signIn() {
        this.signedIn = true;
        this.isLoggedInChanged.emit(this.signedIn);
    }

    signOut() {
        this.signedIn = false;
        this.userEmail = null;
        sessionStorage.removeItem(SessionName);
        this.isLoggedInChanged.emit(this.signedIn);

        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.msalService.logoutPopup({
                mainWindowRedirectUri: '/'
            });
        } else {
            this.msalService.logoutRedirect();
        }
    }

    setUserEmail(email: string) {
        this.userEmail = email;
    }

    getMsalUserEmail() {
        /* assuming 1 active account at a time */
        const accs = this.msalService.instance.getAllAccounts();
        /* assuming one email per account */
        return accs.length > 0 ? accs[0].idTokenClaims['emails'][0] : null;
    }

    getUserEmail(): string {
        return this.userEmail;
    }

    getCurrentUser() {
        return this.currentUser;
    }

    setCurrentUser(inUser: any) {
        this.currentUser = inUser;
    }

    getUserId(): string {
        if (this.currentUser && this.currentUser.id) {
            return this.currentUser.id.toString();
        }
        return '0';
    }

    setPermissions(areaPermissions: AreaPermission[]) {
        this.areaPermissions = areaPermissions;
    }

    getSelectionsPermissions(permissionArea: string): string {
        let permission = 'none';
        this.areaPermissions.forEach(retPermission => {
            if (retPermission.applicationArea === permissionArea) {
                if (retPermission.permissionType) {
                    permission = retPermission.permissionType;
                }
            }
        });
        return permission;
    }

    isSuperUser() {
        return this.getCurrentUser()?.isSuperUser;
    }

    isAdminOrSuper() {
        return this.globalService.getCurrentCompany()?.userTypeId === UserTypeEnum.Admin || this.getCurrentUser()?.isSuperUser;
    }

    isClient() {
        return this.globalService.getCurrentCompany()?.userTypeId === UserTypeEnum.Client;
    }

    isAssociate() {
        return this.globalService.getCurrentCompany()?.userTypeId === UserTypeEnum.Associate;
    }
}
