import { Component, Input } from '@angular/core';


@Component({
  selector: 'js-background-logo',
  template: '<img *ngIf="showImg" class="transLogo" src="../assets/truthengineNoTagline.png">',
  styles: ['.transLogo {' +
              'min-width: 90vw;' +
              'max-width: 90vw;' +
              'position: fixed;' +
              'top: 200px;' +
              'left: 0;' +
              'margin: 0 5%;' +
              'opacity: 0.05;' +
              'z-index: -1;' +
           '}']
})
export class BackgroundLogoComponent {

  @Input() showImg: boolean;

  constructor() { }

}
