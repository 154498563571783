import { catchError, forkJoin, map, Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Company } from '../../dtos/company';
import { CompanyRole, CompanyRoleUser } from '../../dtos/roles';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { HttpService } from '../http.service';
import { OrderControl } from '../../dtos/order-control';
import { GlobalService } from '../global.service';
import { PriceFileItem } from '../../dtos/price-file-item';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    baseURL = environment.apiUrl + '/companies/';
    rolesBaseURL = environment.apiUrl + '/company-roles';

    authCompanies: Company[];
    priceFileItems: PriceFileItem[];

    constructor(private http: HttpClient,
        private httpService: HttpService,
        private globalService: GlobalService) { }

    setCurrentCompanies(comps: Company[]) {
        this.authCompanies = comps;
    }

    getCurrentCompanies() {
        return this.authCompanies;
    }

    getCompanies() {
        return this.http.get<Company[]>(this.baseURL, this.httpService.getHttpOptions());
    }

    getCompaniesByUserEmail(email: string) {
        return this.http.get<Company[]>(environment.apiUrl + '/email-addresses/' + email + '/authorised-companies',
            this.httpService.getHttpOptions());
    }

    getCompany(id) {
        return this.http.get<Company>(this.baseURL + id, this.httpService.getHttpOptions());
    }

    updateComp(company: Company) {
        const url = this.baseURL + company.id;
        return this.http.patch(url, JSON.stringify(company), this.httpService.getHttpOptions());
    }

    addComp(company: Company) {
        return this.http.post(this.baseURL, JSON.stringify(company), this.httpService.getHttpOptions());
    }


    getOrderControl(): Observable<OrderControl> {
        const url = environment.apiUrl + '/order-control';
        return this.http.get<OrderControl>(url, this.httpService.getHttpOptions());
    }

    getAllPriceFileItems(): Observable<PriceFileItem[]> {
        const url = environment.apiUrl + '/price-file-items?priceFileItemTypeId=2&activeOnly=true';  
        return this.http.get<PriceFileItem[]>(url, this.httpService.getHttpOptions()).pipe(
            tap(res => {
              this.priceFileItems = res;
            }),
            catchError(this.globalService.handleError));
    }

    getOrderControlWithPriceFileItems(): Observable<(OrderControl)> {
        return forkJoin(
            [
                this.getOrderControl(),
                this.getAllPriceFileItems()
            ]
        )
            .pipe(map(
                ([orderControl]) => {
                    return (orderControl);
                }, (err) => {
                    return this.globalService.returnError(err);
                }
            ));
    }

    updateCommissionItem(priceFileItemId: number) {
        const url = environment.apiUrl + '/order-control/commission-item?priceFileItemId=' + priceFileItemId;
        return this.http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions());
    }


    /***************************************************** ROLES ************************************************************/


    getCompanyRoles(): Observable<CompanyRole[]> {
        return this.http.get<CompanyRole[]>(this.rolesBaseURL, this.httpService.getHttpOptions());
    }

    patchCompanyRoles(roles: CompanyRole[]) {
        const observableBatch = [];
        roles.forEach(role => {
            const url = this.rolesBaseURL + role.id;
            const res = this.http.patch(url, JSON.stringify(role), this.httpService.getHttpOptions());
            observableBatch.push(res);
        });
        return observableBatch;
    }

    patchCompanyRole(role: CompanyRole) {
        const url = this.rolesBaseURL + role.id;
        return this.http.patch(url, JSON.stringify(role), this.httpService.getHttpOptions());
    }

    getCompanyRoleUsers(roleId: number): Observable<CompanyRoleUser[]> {
        const url = this.rolesBaseURL + roleId + '/users';
        return this.http.get<CompanyRoleUser[]>(url, this.httpService.getHttpOptions());
    }

    addCompanyRoleUser(companyRoleId: number, userId: number) {
        const url = this.rolesBaseURL + companyRoleId + '/users';
        return this.http.post(url, JSON.stringify({ userId: userId }), this.httpService.getHttpOptions());
    }

    deleteCompanyRoleUser(companyRoleId: number) {
        const url = environment.apiUrl + '/company-role-users/' + companyRoleId;
        return this.http.delete<CompanyRoleUser>(url, this.httpService.getHttpOptions());
    }


    /***************************************************** CONFIG ************************************************************/


    getCompanyConfigurations() {
        return this.http.get<CompanyConfiguration[]>(environment.apiUrl + '/company-configurations',
            this.httpService.getHttpOptions());
    }

}
