export enum ConfigurationEnum {
    LogoPosition = 1,
    ReportFooterImage = 2,
    SalesQuotePrintWholeAddendum = 3,
    WordingOfToBeSelected = 4,
    WordingOfToBeSelectedByConsultant = 5,
    DefaultConstructionAdminFee = 6,
    CustomerUpdateAllowed = 7,
    SalesQuoteCoverImage = 8,
    DefaultJobDepositValue = 9,
    AddendumHeadingWording = 10,
    ManualOrders = 11,
    ClientNotificationOfInfoChanges = 12,
    VariationPriceEstimatingEnabled = 13,
    InvoiceUploadEnabled = 14,
    AutoAskForFirstSalesVariation = 15,
    PriceFileUsesCatalogues = 16,
    PriceFileUsesDistricts = 17,
    AutoCreateFirstSalesOfficeVariation = 18,
    VariationIntegrationEmailAddress = 19,
    VariationReportType = 20,
    ConstructionActivitiesUsed = 21,
    ClientDashboardVisible = 22,
    JobReceiptsUsed = 23,
    JobActivityUsed = 24,
    QuoteVOSectionHeadingPrintWholeAddendum = 25,
    QuoteVOSectionHeading = 26,
    QuotePSSectionHeading = 27,
    QuoteOfficeSectionHeading = 28,
    BalanceOfDepositAmountsUsed = 29,
    EstimatorRequired = 30,
    QuoteXMLExport = 31,
    variationNumbersStartAgain = 32,
    customerAcceptReject = 33,
    EstimatingPreContractFeesMargin = 35,
    ClaimSchedule = 36,
    AskSalesToShowOnlyItemsInThisVO = 37,
    ShowFinanceCashCheckboxOnPostContractVO = 38,
    CanSalesVariationItemsBeUnChecked = 39,
    ContractPrintFromSalesPriceOnwards = 40,
    TrackingSystemActive = 41,
    PostContractPendingApprovalDueDateBlankWarning = 42,
    PurchaseOrderSystemActive = 43,
    ShowActivityOnPostContractVO = 44,
    SalesQuoteSignedDateShown = 45,
    ConstructiveIntegrationEnabled = 50,
    ActiveCampaignOrHubSpotIntegrationEnabled = 51,
    HouseTypeUseCommissionFields = 78
}
