import { Component, OnInit, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from '../../../services/notification.service';
import { CategoryApiService } from '../services/categories-api.service';
import { Category } from '../../../dtos/category';


@Component({
    selector: 'js-category-modal',
    templateUrl: './category-modal.component.html',
    styleUrls: ['./category-modal.component.scss']
  })
  export class CategoryModalComponent implements OnInit {

    @Input() mode: string;
    @Input() category: Category;

    @ViewChild('input', { static: true }) input: ElementRef;

    public refresh = new EventEmitter<boolean>();

    submitText: string;
    typeForm: UntypedFormGroup;
    header: string;
    updatedCat: Category;
    addingAnother = false;
    submitLoading = false;

    constructor(public activeModal: NgbActiveModal, private formBuilder: UntypedFormBuilder,
                private catService: CategoryApiService, private notiService: NotificationService) {}

    ngOnInit() {
        if (this.mode === 'Add') {
            this.header = 'Add Category';
            this.submitText = 'Add';
            this.setupAddForm();
        } else if (this.mode === 'Edit') {
            this.header = 'Edit Category';
            this.submitText = 'Update';
            this.typeForm = this.formBuilder.group( {
                description: [this.category.description, Validators.required],
                isActive: this.category.isActive,
            });
        }
    }

    setupAddForm() {
        this.typeForm = this.formBuilder.group( {
            description: [null, Validators.required],
            // isActive: true,
            salesPrice: 0,
            costToCompany: 0,
            lastPriceUpdate: new Date(),
            productCode: null
        });
    }

    submit() {
        this.submitLoading = true;
        if (this.mode === 'Add') {
            this.addCat();
        } else if (this.mode === 'Edit') {
            this.editCat();
        }
    }

    addAnotherSelected() {
        this.submitLoading = true;
        this.addingAnother = true;
        this.addCat();
    }

    addCat() {
        this.updatedCat = {
            id: null,
            description: this.typeForm.get('description').value,
            isActive: null,
            orderNo: null
        };
        this.catService.addCategory(this.updatedCat).subscribe( res => {
            this.refresh.emit(true);
            this.notiService.showSuccess('Category Added');
            if (this.addingAnother) {
                this.setupAddForm();
                this.input.nativeElement.focus();
                this.submitLoading = false;
            } else {
                this.close();
            }
        }, err => {
            this.notiService.notify(err);
            this.submitLoading = false;
        });
    }

    editCat() {
        this.updatedCat = {
            id: this.category.id,
            description: this.typeForm.get('description').value,
            isActive: this.typeForm.get('isActive').value,
            orderNo: null
        };
        this.catService.editCategory(this.updatedCat).subscribe( res => {
            this.refresh.emit(true);
            this.notiService.showSuccess('Category Updated');
            if (this.addingAnother) {
                this.setupAddForm();
            } else {
                this.close();
            }
        }, err => {
            this.notiService.notify(err);
            this.submitLoading = false;
        });
    }

    close() {
        this.activeModal.close();
    }

  }
