import { CompanyService } from './../../../services/felixApi/company.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { CommissionPriceFileItemForm } from '../../../dtos/order-control';
import { PriceFileItem } from '../../../dtos/price-file-item';

@Component({
  selector: 'js-set-commission-item',
  templateUrl: './set-commission-item.component.html',
  styleUrls: ['./set-commission-item.component.scss']
})
export class SetCommissionItemComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  loading = true;
  commissionPriceFileItemId: number;
  commissionPriceFileItemForm: CommissionPriceFileItemForm;
  priceFileItems: PriceFileItem[];

  constructor(
    public activeModal: NgbActiveModal,
    private companyService: CompanyService,
    private notiService: NotificationService,
    public globalService: GlobalService) { }

  ngOnInit() {
    this.setUpData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this.activeModal.close();
  }

  setUpData() {
    // get the recipes for the list
    this.subscriptions.push(
      this.companyService.getOrderControlWithPriceFileItems()
        .subscribe(
          orderControl => {
            if (!orderControl) {
              this.notiService.showWarning('No order control found');
            } else {
              this.commissionPriceFileItemId = orderControl.commissionPriceFileItemId;
              this.commissionPriceFileItemForm = new CommissionPriceFileItemForm();
              this.commissionPriceFileItemForm.priceFileItemId = this.commissionPriceFileItemId;
              this.commissionPriceFileItemForm.modifiedDate = orderControl.modifiedDate;
              this.commissionPriceFileItemForm.modifiedUserId = orderControl.modifiedUserId;

              this.priceFileItems = this.companyService.priceFileItems.filter(i => !i.hasSizes);
            }
            this.loading = false;
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );
  }

  onPriceFileItemChanged(dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      this.commissionPriceFileItemForm.priceFileItemId = event.selectedRowsData[0]?.id;
      dropDownBox.close();
    } else {
      this.commissionPriceFileItemForm.priceFileItemId = null;
    }
  }

  update() {
    this.loading = true;
    this.subscriptions.push(
      this.companyService.updateCommissionItem(this.commissionPriceFileItemForm.priceFileItemId)
        .subscribe(
          () => {
            this.close();
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );
  }

  onDropDownChanged(e) {
    if (!e.value) {
      this.commissionPriceFileItemForm.priceFileItemId = null;
    }
  }
}
