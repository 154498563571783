import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import { RefreshGuard } from './services/refresh-guard.service';

import { LoginComponent } from './login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { OptionListStartComponent } from './option-lists/option-list-start/option-list-start.component';
import { OptionListBoardStartComponent } from './option-lists/boards/option-list-board-start.component';
import { OptionListBoardsFirstComponent } from './option-lists/boards/option-list-boards-first.component';
import { HouseTypesComponent } from './maintenance/house-types/house-types.component';
import { CategoriesComponent } from './option-lists/categories/categories.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationControlComponent } from './notifications/notification-control/notification-control.component';
import { HouseTypeModsComponent } from './maintenance/house-types/house-type-mods/house-type-mods.component';
import { CompanyActivitiesComponent } from './maintenance/company-activities/company-activities.component';
import { InformationTypesComponent } from './maintenance/information-types/information-types.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login/:comp', component: LoginComponent },
  { path: 'welcome', component: WelcomeComponent, canActivate: [RefreshGuard] }, // msal stopped working when this guarded
  { path: 'welcome/:comp', component: WelcomeComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'optionlists', component: OptionListStartComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'maintenance', component: MaintenanceComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'categories', component: CategoriesComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'house-types', component: HouseTypesComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'house-type-mods', component: HouseTypeModsComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'notificationControl', component: NotificationControlComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'optionBoards', component: OptionListBoardStartComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'optionBoardSelections', component: OptionListBoardsFirstComponent, canActivate: [MsalGuard, RefreshGuard] },
  { path: 'company-activities', component: CompanyActivitiesComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always' },
  { path: 'information-types', component: InformationTypesComponent, canActivate: [MsalGuard, RefreshGuard], runGuardsAndResolvers: 'always'
  },
  { path: 'login', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
