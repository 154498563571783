import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationControl, EmailControlType } from '../../../dtos/notifications';
import NotificationsConfig from '../../../../config/notificationConfig';

import { NotificationService } from '../../../services/notification.service';
import { GlobalService } from '../../../services/global.service';
import { TextHighlighterDirective } from '../../../shared/textHighlighter.directive';
import { ConfirmationModalComponent } from '../../../shared/confirmation-modal.component';
import { DefaultBtnEnum } from '../../../dtos/notifications';
import { NotificationControlService } from '../../services/noti-control.service';


@Component({
  selector: 'js-client-email',
  templateUrl: './client-email.component.html',
  styleUrls: ['./client-email.component.scss']
})
export class ClientEmailComponent implements OnInit, OnDestroy {

  @ViewChild('messageArea')
  messageArea: ElementRef;
  @ViewChild('subjectAreaClient')
  subjectAreaClient: ElementRef;
  @ViewChildren(TextHighlighterDirective)
  textHighlighters: TextHighlighterDirective[];
  @ViewChild('focusElement')
  focusElement: ElementRef;

  notificationControl: NotificationControl;
  notificationsConfig = NotificationsConfig;

  defaultBtnText: string;
  messageText: string;
  subjectText: string;
  textVariableStyling = 'color:var(--bootstrap-blue);';

  submitting = false;
  sendingTest = false;
  loading = true;
  noControl = false;
  dirtyForm = false;

  subscriptions: Subscription[] = [];
  defaultBtnEnum = DefaultBtnEnum;


  constructor(
    private notiControlService: NotificationControlService, private globalService: GlobalService,
    private notiService: NotificationService, private modalService: NgbModal,
    private router: Router
  ) {

      this.subscriptions.push(
        this.notiControlService.getClientControl().subscribe(control => {
          this.notificationControl = control;
          this.setupForm();
          this.loading = false;
          this.focus();
        }, err => {
          if (err.status === 422 && err.error.message.indexOf('Missing Email Control Master Record') > -1) {
            this.noControl = true;
            this.setupForm();
            // this.dirtyForm = true; // allow submit of defaults
            this.loading = false;
          } else {
            this.notiService.notify(err);
            this.router.navigate(['notifications']);
          }
        })
      );
    }

  ngOnInit() {
  }

  setupForm() {
    this.messageText = this.notificationControl && this.notificationControl.message ? this.notificationControl.message
        : this.notificationsConfig.DefaultClientMessage;
    this.subjectText = this.notificationControl && this.notificationControl.subject ? this.notificationControl.subject
        : this.notificationsConfig.DefaultClientSubject;

    if (!this.notificationControl ||
         (this.notificationControl && this.notificationControl.message === this.notificationsConfig.DefaultClientMessage
            && this.notificationControl.subject) === this.notificationsConfig.DefaultClientSubject) {
         this.defaultBtnText = DefaultBtnEnum.default;
       } else {
         this.defaultBtnText = DefaultBtnEnum.custom;
       }
  }

  submit() {
    this.submitting = true;
    const updatedControl = this.setupdatedControl();

    if (this.notificationControl) {
      this.patchControl(updatedControl);
    } else {
      this.postControl(updatedControl);
    }
  }

  updateSubjectText(event) {
    this.subjectText = event;
    this.dirtyForm = true;

    if (this.messageText === this.notificationsConfig.DefaultClientMessage
      && this.subjectText === this.notificationsConfig.DefaultClientSubject) {
      this.defaultBtnText = DefaultBtnEnum.default;
    } else {
      this.defaultBtnText = DefaultBtnEnum.custom;
    }
  }

  updateMessageText(event) {
    this.messageText = event;
    this.dirtyForm = true;

    if (this.messageText === this.notificationsConfig.DefaultClientMessage
      && this.subjectText === this.notificationsConfig.DefaultClientSubject) {
      this.defaultBtnText = DefaultBtnEnum.default;
    } else {
      this.defaultBtnText = DefaultBtnEnum.custom;
    }
  }

  setupdatedControl(): NotificationControl {
    return {
      id: this.notificationControl ? this.notificationControl.id : null,
      emailControlTypeId: EmailControlType.ClientStatus,
      subject: this.subjectText,
      message: this.messageText
    };
  }

  patchControl(updatedControl: NotificationControl) {
    this.notiControlService.patchNotiControl(updatedControl).subscribe( res => {
      this.success();
    }, err => {
      this.error(err);
    });
  }

  postControl(updatedControl: NotificationControl) {
    this.notiControlService.postNotiControl(updatedControl).subscribe( res => {
      this.success();
    }, err => {
      this.error(err);
    });
  }

  testVariationEmail() {
    this.sendingTest = true;
    this.notiControlService.testClientEmail().subscribe( res => {
      this.notiService.showSuccess('Test Email sent');
      this.sendingTest = false;
    }, err => {
      this.notiService.showError('There was an error sending the test email. Please try again later');
      this.sendingTest = false;
    });
  }

  restoreDefaultSelected() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-middle' });
    modalRef.componentInstance.confirmationText = this.notificationsConfig.DefaultConfirmation;

    modalRef.result.then((confirmed) => {
      if (confirmed) {
        this.restoreDefaults();
      }
    }, (reason) => {
    });
  }

  restoreDefaults() {
   if (this.subjectAreaClient.nativeElement.innerHTML !== this.notificationsConfig.DefaultClientSubject ||
       this.messageArea.nativeElement.innerHTML !== this.notificationsConfig.DefaultClientMessage) {

      this.dirtyForm = true;
      this.subjectAreaClient.nativeElement.innerHTML = this.notificationsConfig.DefaultClientSubject;
      this.messageArea.nativeElement.innerHTML = this.notificationsConfig.DefaultClientMessage;
    }

    this.textHighlighters.forEach(highlightDirective => {
      highlightDirective.colorize();
    });

    this.defaultBtnText = DefaultBtnEnum.default;
  }

  focus() {
    setTimeout( () => {
      this.focusElement?.nativeElement?.focus();
    }, 100);
  }

  success() {
    this.noControl = false;
    this.dirtyForm = false;
    this.notiService.showSuccess('Email settings successfully updated');
    this.submitting = false;
  }

  error(err) {
    this.notiService.notify(err);
    this.submitting = false;
  }

  configureScenarios() {
    this.router.navigate(['notifications']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach( sub => {
      sub.unsubscribe();
    });
  }

}

