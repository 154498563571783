import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceHelperService {

  sections = ['Houses', 'Specifications', 'House Types', 'Activities', 'Info Types', 'Attachments', 'Claims', 'Client Welcome', 'Job Divisions', 'Banks'];
  selectedSection = 'House Types';

  houseTypeModMode = new BehaviorSubject(false);

  constructor() { }

}
