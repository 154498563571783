import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsComponent } from './notifications.component';
import { NotificationControlComponent } from './notification-control/notification-control.component';
import { GenericEmailComponent } from './notification-control/generic-email/generic-email.component';
import { VariationEmailComponent } from './notification-control/variation-email/variation-email.component';
import { ClientEmailComponent } from './notification-control/client-email/client-email.component';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationControlComponent,
    GenericEmailComponent,
    VariationEmailComponent,
    ClientEmailComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class NotificationsModule { }
