import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { GlobalService } from '../../services/global.service';
import { BoardMaster, BoardType, BoardAttachment } from '../../dtos/boards';
import { BoardMasterService } from './services/board-master.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { BoardTypesComponent } from './board-types/board-types.component';
import { FileProcessingService } from '../../services/file-processing.service';
import { AttachmentTypeEnum } from '../../dtos/attachment-type.enum';
import { NotificationService } from '../../services/notification.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'js-option-list-board-start',
  templateUrl: './option-list-board-start.component.html',
  styleUrls: ['./option-list-board-start.component.scss']
})
export class OptionListBoardStartComponent implements OnInit, OnDestroy {
  loading = true;
  errorMessage: any;
  innerWidth: number;
  subscriptions: Subscription[] = [];
  boardMasterList: BoardMaster[] = [];
  boardForm: UntypedFormGroup;
  modalHeading: string;
  modalButton: string;
  closeResult: string;
  updatedOption: any;

  // displayedColumns = ['select', 'Config', 'Board Name'];
  displayedColumns = ['Config', 'Board Name'];
  dataSource: any;
  selection = new SelectionModel<BoardMaster>(true, []);
  boardTypes: BoardType[] = [];
  boardAttachment: BoardAttachment;
  loadingBoardAttachment: boolean;
  fileValue: any[] = [];
  deleteClicked = false;

  constructor(private globalService: GlobalService, private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private _router: Router,
    private _boardMasterService: BoardMasterService,
    private notiService: NotificationService,
    private fileService: FileProcessingService) {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(width => {
        this.innerWidth = width;
      })
    );
  }

  ngOnInit() {
    this.boardForm = this.formBuilder.group({
      boardName: ['', Validators.required],
      boardTypeId: [0, Validators.required]
    });

    this.innerWidth = this.globalService.innerWidth;

    this.getBoardTypes();
  }

  getBoardTypes() {
    this.loading = true;
    this._boardMasterService.getBoardTypes()
      .subscribe(
        boardTypes => {
          this.boardTypes = boardTypes;
          this.getBoards();
        },
        error => this.errorMessage = <any>error);
  }

  getBoards() {
    // get all the boards
    this._boardMasterService.getBoards()
      .subscribe(
        boardMasterList => {
          this.boardMasterList = boardMasterList;
          this.dataSource = new MatTableDataSource(this.boardMasterList);
          this.loading = false;
        },
        error => this.errorMessage = <any>error);
  }

  addBoard(content) {
    // add a new board
    this.modalHeading = 'Add Board';
    this.modalButton = 'Add';
    this.boardForm.patchValue({
      boardName: '',
      boardTypeId: 0
    });

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      // add the board
      this.loading = true;
      this.updatedOption = { boardName: this.boardForm.get('boardName').value, boardTypeId: this.boardForm.get('boardTypeId').value };
      this._boardMasterService.addBoard(this.updatedOption).subscribe(
        res => {
          this.getBoards();
        },
        err => {
          this.notiService.notify(err);
          this.loading = false;
        }
      );

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // console.log(this.closeResult);
    });
  }

  getBoardAttachment(boardId: number) {
    // get the current board's attachment
    this.boardAttachment = null;
    this.loadingBoardAttachment = true;
    this._boardMasterService.getBoardAttachment(boardId)
      .subscribe(
        boardAttachment => {
          this.boardAttachment = boardAttachment;
          this.loadingBoardAttachment = false;
        },
        error => {
          this.loadingBoardAttachment = false;
        });
  }

  editBoard(content, board: BoardMaster) {
    // edit a board
    this.modalHeading = 'Edit Board';
    this.modalButton = 'Edit';
    this.boardForm.patchValue({
      boardName: board.boardName,
      boardTypeId: board.boardTypeId
    });

    this.fileValue = [];
    this.deleteClicked = false;
    this.getBoardAttachment(board.id);

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loading = true;

      if (this.closeResult === 'Closed with: Delete click') {
        this.deleteBoard(content, board);
      } else {
        // change
        this.updatedOption = { boardName: this.boardForm.get('boardName').value, boardTypeId: this.boardForm.get('boardTypeId').value };
        this._boardMasterService.updateBoard(board.id, this.updatedOption).subscribe(
          res => {
            this.addBoardAttachment(board.id);
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          }
        );
      }

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // console.log(this.closeResult);
      this.loading = false;
    });
  }

  deleteBoard(content, board) {
    // edit a board
    this.modalHeading = 'Delete Board';
    this.modalButton = 'Delete';
    this.boardForm.patchValue({
      boardName: board.boardName,
      boardTypeId: board.boardTypeId
    });

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loading = true;

      this._boardMasterService.deleteBoard(board.id).subscribe(
        res => {
          this.getBoards();
        },
        err => {
          this.notiService.notify(err);
          this.loading = false;
        }
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // console.log(this.closeResult);
      this.loading = false;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  gotoSelections() {
    // go to the selections
    this._router.navigate(['/optionBoardSelections']);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  openBoardTypes() {
    this.modalService.open(BoardTypesComponent).result.then(() => {
      this.getBoardTypes();
    }, () => {
      this.getBoardTypes();
    });
  }

  openAttachment() {
    const buffers = this.fileService.base64ToArrayBuffer(this.boardAttachment.attachment);
    const fileType = AttachmentTypeEnum[this.boardAttachment.attachmentTypeId];
    saveAs(buffers[1], this.boardAttachment.attachmentName + '.' + fileType.toLowerCase());
  }

  addBoardAttachment(boardId: number) {
    if (this.fileValue && this.fileValue.length) {
      this.loading = true;
      const formData: FormData = new FormData();
      formData.append('image', this.fileValue[0], this.fileValue[0].name);

      this.subscriptions.push(
        this._boardMasterService.postBoardAttachment(boardId, formData).subscribe(res => {
          this.fileValue = [];
          this.getBoards();
        }, err => {
          this.notiService.notify(err);
          this.loading = false;
        })
      );
    } else {
      this.getBoards();
    }
  }

  deleteAttachment() {
    // delete the board attachment
    if (!this.deleteClicked) {
      this.deleteClicked = true;
    } else {
    this.subscriptions.push(
      this._boardMasterService.deleteBoardAttachment(this.boardAttachment.id).subscribe(
        res => {
          this.boardAttachment = null;
          this.deleteClicked = false;
          this.getBoards();
        },
        err => {
          this.notiService.notify(err);
          this.loading = false;
          this.deleteClicked = false;
        }
      ));
    }
  }
}
