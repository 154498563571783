import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ILibraryImgAttachment } from '../../dtos/image-library-attachment';
import { ILibraryImg } from '../../option-lists/types/option-image';
import { HttpService } from '../http.service';


@Injectable()
export class ImageLibraryService {

  attachBaseURL = environment.apiUrl + '/option-image-attachments/';
  imageBaseURL = environment.apiUrl + '/option-images';

  attachmentCache: ILibraryImgAttachment[] = [];
  libCache: ILibraryImg[] = null;


  constructor(
      private http: HttpClient,
      private httpService: HttpService) {
          this.httpService.clearCaches.subscribe(c => {
              this.attachmentCache = null;
              this.libCache = null;
          });
        }

  getLibraryImageAttachment(id): Observable<ILibraryImgAttachment> {
      const img = this.attachmentCache.filter(i => i.optionImageId === id);
      if (img[0]) {
          return of(img[0]);
      } else {
          return this.http.get<ILibraryImgAttachment>(
              this.attachBaseURL + id,
              this.httpService.getHttpOptions()
          )
          .pipe(
              tap(res => this.attachmentCache.push(res)),
          );
      }
  }

  getOptionListLibrary(): Observable<ILibraryImg[]> {
      if (this.libCache && this.libCache.length > 0) { // sometimes empty instead of null??
        return of(this.libCache);
      } else {
        const options = this.httpService.getHttpFileOptions();
        return this.http.get<ILibraryImg[]>(this.imageBaseURL, options)
        .pipe(
          tap(res => this.libCache = res),
        );
      }
    }

  // getImageLibrary() {
  //     return this.http.get<IImageLibraryEntry[]>(this.globalService.getApiUrl() + '/option-images',
  //       this.globalService.getHttpOptions()).pipe(
  //         catchError(this.handleError));
  // }

  // getImageLibraryEntry(id) {
  //     return this.http.get<IImageLibraryEntry>(this.globalService.getApiUrl() + '/option-images/' + id,
  //       this.globalService.getHttpOptions()).pipe(
  //         catchError(this.handleError));
  // }

  // patchLibraryImageEntry(libraryEntry: IImageLibraryEntry) {
  //     return this.http.patch<IImageLibraryEntry>(this.globalService.getApiUrl() + '/option-images/' + libraryEntry.id,
  //     libraryEntry,
  //     this.globalService.getHttpOptions());
  // }

  // postLibraryImageEntry(libraryEntry) {
  //     return this.http.post<IImageLibraryEntry>(this.globalService.getApiUrl() + '/option-images',
  //     libraryEntry,
  //     this.globalService.getHttpOptions());
  // }

  // deleteLibraryImageEntry(id) {
  //     return this.http.delete<IImageLibraryEntry>(this.globalService.getApiUrl() + '/option-images/' + id,
  //     this.globalService.getHttpOptions());
  // }

  // patchLibraryImageAttachment(id, image) {
  //     return this.http.patch<IImageLibraryAttachment>(this.globalService.getApiUrl() + '/option-image-attachments/' + id,
  //     image,
  //     this.globalService.getHttpFileOptions());
  // }

  // postLibraryImageAttachment(id, image) {
  //     return this.http.post<IImageLibraryAttachment>(this.globalService.getApiUrl() + '/option-image-attachments/' + id,
  //     image,
  //     this.globalService.getHttpFileOptions());
  // }

  // deleteLibraryImageAttachment(id) {
  //     return this.http.delete<IImageLibraryEntry>(this.globalService.getApiUrl() + '/option-image-attachments/' + id,
  //     this.globalService.getHttpOptions());
  // }

}

