import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AttachmentSettingAPIService } from '../attachment-settings-api.service';
import { NotificationService } from '../../../services/notification.service';
import { FileProcessingService } from '../../../services/file-processing.service';
import { AttachmentTypeEnum } from '../../../dtos/attachment-type.enum';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { ClaimsService } from '../../claims/services/claims.service';
import { UtilsService } from '../../../services/utils.service';
import { saveAs } from 'file-saver';


@Component({
  selector: 'js-manage-attach-file',
  templateUrl: './manage-attach-file.component.html',
  styleUrls: ['./manage-attach-file.component.scss']
})
export class ManageAttachFileComponent implements OnInit, OnDestroy {

  claimMode = false;
  title: string;
  acceptedTypes: string;

  @ViewChild('fileUploader')
  fileUploader: DxFileUploaderComponent;

  attachmentParentRecordId: number;
  attachmentFile; // CompanyAttachmentSetting | ClaimMasterAttachment;

  subscriptions: Subscription[] = [];
  uploadDisabled = false;
  badFileType = false;
  fileChosen = false;
  submitting = false;
  loading = true;
  fileType: string;

  constructor(
    public activeModal: NgbActiveModal,
    private attachService: AttachmentSettingAPIService,
    private claimService: ClaimsService,
    private notiService: NotificationService,
    private fileService: FileProcessingService,
    private util: UtilsService
  ) { }

  ngOnInit() {
    let obs;
    if (this.claimMode) {
      this.title = 'Manage Claim Set Attachment';
      this.acceptedTypes = '.doc, .docx, .xlsx, .xlsm, .xls or .pdf';
      obs = this.claimService.getClaimAttachment(this.attachmentParentRecordId);
    } else {
      this.title = 'Manage Attachment Setting File';
      this.acceptedTypes = '.doc, .docx, .xlsx, .xlsm, .xls or .pdf';
      obs = this.attachService.getAttachmentSettingFile(this.attachmentParentRecordId.toString());
    }
    this.subscriptions.push(
      obs.subscribe(f => {
        this.attachmentFile = f;
        this.loading = false;
      }, err => {
        this.loading = false;
        this.notiService.notify(err);
      })
    );
  }

  submit() {
    if ((this.fileType === 'xlsx' || this.fileType === 'xls' || this.fileType === 'xlm')
      && !this.claimMode && !this.attachmentFile.printSeparate) {
      this.notiService.showInfo('Can only use spreadsheets for Separte attachments');
    } else {
      this.submitting = true;
      this.uploadDisabled = true;

      const formData = new FormData();
      for (const file of this.fileUploader.value) {
        formData.append(file.name, file);
      }

      let obs;
      if (this.claimMode) {
        obs = this.claimService.addOrUpdateClaimAttachment(this.attachmentParentRecordId.toString(), formData);
      } else {
        obs = this.attachService.patchCompanySettingDoc(this.attachmentParentRecordId.toString(), formData);
      }

      this.subscriptions.push(
        obs.subscribe(res => {
          this.notiService.showSuccess('File Updated');
          this.submitting = false;
          this.close();
        }, err => {
          this.loading = false;
          this.notiService.notify(err);
        })
      );
    }
  }

  download() {
    if (this.attachmentFile.attachment === null) {
      this.notiService.showError('No attachment found');
      return;
    }
    const buffers = this.fileService.base64ToArrayBuffer(this.attachmentFile.attachment);
    const fileType = AttachmentTypeEnum[this.attachmentFile.attachmentTypeId];
    const str = this.claimMode
      ? 'Truth-Engine_Claim-Set - ' + this.util.removeFileType(this.attachmentFile.attachmentName)
      : 'Truth-Engine_Attach-Setting - ' + this.attachmentFile.description;
    saveAs(buffers[1], str + '.' + (fileType?.toLowerCase() ?? 'unknown'));
  }

  delete() {
    this.claimService.deleteClaimAttachment().subscribe(e => {
      this.notiService.showSuccess('Attachment Deleted');
      this.close();
    }, err => this.notiService.showError('There was an error - please try again in a moment'));
  }

  fileSelected(e) {
    if (e.value.length === 1) {
      const fileName = e.value[0].name;
      this.fileType = this.util.getFileType(fileName);

      if (this.fileType !== 'pdf' && this.fileType !== 'doc' && this.fileType !== 'docx'
        && this.fileType !== 'xlsx' && this.fileType !== 'xls' && this.fileType !== 'xlsm') {
        this.badFileType = true;
      } else {
        this.fileChosen = true;
        this.badFileType = false;
      }
    }
  }

  close() {
    this.activeModal.close(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

}
