import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { AreaPermission } from '../../dtos/areaPermission';
import { User } from '../../dtos/user';
import { Company } from '../../dtos/company';
import { ApplicationVersion } from '../../shared/global-models';
import { HttpService } from '../http.service';
import { AuthService } from '../auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  appBaseURL = environment.apiUrl + '/applications/';

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private httpService: HttpService) { }

  getCurrentApplicationVersion(): Observable<ApplicationVersion> {
    const url = this.appBaseURL + environment.appId + '/latest-version';
    return this.http.get<ApplicationVersion>(url, this.httpService.getHttpOptions());
  }

  getAreaPermissions(): Observable<AreaPermission[]> {
    const url = this.appBaseURL + '1' + '/area-permissions/me';
    return this.http.get<AreaPermission[]>(url, this.httpService.getHttpOptions());
  }

  deleteAreaPermission(userID: number, areaID: number) {
    const url = environment.apiUrl + '/users/' + userID + '/application-area/' + areaID + '/permissions';
    return this.http.delete(url, this.httpService.getHttpOptions());
  }

  getAuthorisedCompanies(email?: string): Observable<Company[]> {
    const e = email ? email : this.authService.getUserEmail();
    const url = environment.apiUrl + '/email-addresses/' + e.replace('@', '%40')
        + '/authorised-companies';
    return this.http.get<Company[]>(url, this.httpService.getHttpOptions());
  }

  addUserToComp(user: Company) {
    const url = environment.apiUrl + '/companies/users';
    return this.http.post<User>(url, JSON.stringify(user), this.httpService.getHttpOptions());
  }

  updateUserCompany(user: User) {
    const url = environment.apiUrl + '/companies/users/' + user.id;

    return this.http.patch(url, JSON.stringify(user), this.httpService.getHttpOptions());
  }

  getCompanyPermission(compId: number, userId: number) {
    const url = environment.apiUrl + '/users/' + userId + '/companies/' + compId + '/user-permissions';
    return this.http.get(url, this.httpService.getHttpOptions());
  }
}
