import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { ClaimApiService } from './claim.api.service';
import { UtilsService } from '../../../services/utils.service';
import { ClaimMaster } from '../../../dtos/claim-master';
import { of } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {


  currentClaimMaster: ClaimMaster;

  constructor(
    private claimApiService: ClaimApiService,
    private utils: UtilsService
  ) { }

  getClaimAttachment(claimId: number) {
    if (this.currentClaimMaster.hasBlob) {
      return this.claimApiService.getClaimAttachment(claimId.toString());
    } else {
      return of(null);
    }
  }

  addOrUpdateClaimAttachment(claimMasterId: string, file) {
    if (this.currentClaimMaster.hasBlob) {
      return this.claimApiService.patchClaimAttachment(claimMasterId, file);
    } else {
      return this.claimApiService.postClaimAttachment(claimMasterId, file);
    }
  }

  clearCaches() {
    this.claimApiService.clearCaches();
  }

  deleteClaimAttachment() {
    return this.claimApiService.deleteClaimAttachment(this.currentClaimMaster.id.toString());
  }

  getClaimMasterDataSource(): CustomStore {
    return new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.claimApiService.getClaimMastersCached().subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.claimApiService.postClaimMaster(values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.claimApiService.patchClaimMaster(values, key).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.claimApiService.deleteClaimMaster(encodeURIComponent(key)).subscribe(res => {
            return resolve();
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      }
    });
  }

  getClaimMasterLineDataSource(claimMasterId: number): CustomStore {
    return new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.claimApiService.getClaimMasterLinesCached(claimMasterId).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.claimApiService.postClaimMasterLine(claimMasterId, values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.claimApiService.patchClaimMasterLine(values, key).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.claimApiService.deleteClaimMasterLine(encodeURIComponent(key)).subscribe(res => {
            return resolve();
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      }
    });
  }
}
