import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { HouseTypeApiService } from '../house-types/services/house-type.api.service';

@Component({
  selector: 'js-house-masters',
  templateUrl: './house-masters.component.html',
  styleUrls: ['./house-masters.component.scss']
})
export class HouseMastersComponent implements OnInit {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: DataSource;
  loading = false;
  gridHeight: number;

  constructor(
    private houseTypeApiService: HouseTypeApiService,
    private globalService: GlobalService,
    public gridService: GridService
  ) {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.gridHeight = window.innerHeight - 110;
      })
    );
  }

  ngOnInit(): void {
    this.gridHeight = window.innerHeight - 110;
    this.setUpDataSource();
  }

  refresh() {
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.getHouseMasters(false).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.addHouseMaster(values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.updateHouseMaster(encodeURIComponent(key), values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.deleteHouseMaster(encodeURIComponent(key)).subscribe(res => {
            return resolve();
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      }
    });
  }

  onInitNewRow(e) {
    e.data.isActive = true;
  }
}
