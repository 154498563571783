import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, zip } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ClientWelcome } from '../../dtos/client-welcome';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { HTML_STYLE_DEFAULT_ABOVE, HTML_STYLE_DEFAULT_BELOW, HTML_STYLE_DEFAULT_BESIDE } from '../../../config/global-config';

import { GlobalService } from '../../services/global.service';
import { FileProcessingService } from '../../services/file-processing.service';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ClientWelcomeService {

  baseURL = environment.apiUrl + '/client-selection-welcomes/';

  currentClientWelcome: ClientWelcome;

  confirmation = false;
  invalidHTML = false;
  aboveHTML: string = HTML_STYLE_DEFAULT_ABOVE;
  besideHTML: string = HTML_STYLE_DEFAULT_BESIDE;
  belowHTML: string = HTML_STYLE_DEFAULT_BELOW;


  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private auth: AuthService,
    private imgService: FileProcessingService,
  ) {
    this.httpService.clearCaches.subscribe(clear => {
      this.restoreDefaults();
    });
  }

  fetchClientWelcome(): Observable<any> {
    return this.getClientWelcome().pipe(
      mergeMap(cw => {
        if (cw.hasBlob) {
          return zip(of(cw), this.getClientWelcomeAttachment(cw.id));
        } else {
          return zip(of(cw), of(null));
        }
      }),
      tap(([clientWelcome, image]) => {
        this.currentClientWelcome = clientWelcome;

        this.aboveHTML = clientWelcome.htmlDetailAboveImage === '' ? HTML_STYLE_DEFAULT_ABOVE : clientWelcome.htmlDetailAboveImage;
        this.besideHTML = clientWelcome.htmlDetailAsideImage === '' ? HTML_STYLE_DEFAULT_BESIDE : clientWelcome.htmlDetailAsideImage;
        this.belowHTML = clientWelcome.htmlDetailBelowImage === '' ? HTML_STYLE_DEFAULT_BELOW : clientWelcome.htmlDetailBelowImage;
        this.confirmation = clientWelcome.isConfirmItem;
      })
    );
  }

  // return true if image was attached
  updateClientWelcome(image?): Observable<boolean> {
    const cw = {
      // id: this.currentClientWelcome ? this.currentClientWelcome.id : null,
      htmlDetailAboveImage: this.aboveHTML === HTML_STYLE_DEFAULT_ABOVE ? '' : this.aboveHTML,
      htmlDetailAsideImage: this.besideHTML === HTML_STYLE_DEFAULT_BESIDE ? '' : this.besideHTML,
      htmlDetailBelowImage: this.belowHTML === HTML_STYLE_DEFAULT_BELOW ? '' : this.belowHTML,
      hasBlob: this.currentClientWelcome
        ? this.currentClientWelcome.hasBlob
        : false,
      isConfirmItem: this.confirmation,
      modifiedDate: new Date(),
      modifiedUserId: this.auth.getUserId(),
      createDate: this.currentClientWelcome ? null : new Date(),
      createUserId: this.currentClientWelcome ? null : this.auth.getUserId()
    };

    const sub = this.currentClientWelcome
      ? this.patchClientWelcome(this.currentClientWelcome.id, cw)
      : this.postClientWelcome(cw);

    return sub.pipe(
      mergeMap((res: ClientWelcome) => {
        if (image && this.currentClientWelcome.hasBlob) {
          return this.imgService.compressImage(image)
            .pipe(
              mergeMap(compressed => this.patchClientWelcomeAttachment(res.id, compressed)),
              map(() => true)
            );
        } else if (image) {
          return this.imgService.compressImage(image)
            .pipe(
              mergeMap(compressed => this.postClientWelcomeAttachment(res.id, compressed)),
              map(() => true)
            );
        } else {
          return of(true);
        }
      })
    );
  }

  deleteCurrentWelcomeAttachment() {
    if (this.currentClientWelcome) {
      return this.deleteClientWelcomeAttachment(this.currentClientWelcome.id);
    } else {
      return throwError(new Error('no current welcome haeder to delete image from'));
    }
  }

  restoreDefaults() {
    this.confirmation = false;
    this.invalidHTML = false;
    this.aboveHTML = HTML_STYLE_DEFAULT_ABOVE;
    this.besideHTML = HTML_STYLE_DEFAULT_BESIDE;
    this.belowHTML = HTML_STYLE_DEFAULT_BELOW;
    this.currentClientWelcome = null;
  }

  //// APIs ////

  getClientWelcome(): Observable<ClientWelcome> {
    return this.http.get<ClientWelcome>(this.baseURL, this.httpService.getHttpOptions());
  }

  postClientWelcome(cw) {
    return this.http.post(this.baseURL, cw, this.httpService.getHttpOptions());
  }

  patchClientWelcome(id: string, cw) {
    return this.http.patch(this.baseURL + id, cw, this.httpService.getHttpOptions());
  }

  getClientWelcomeAttachment(cwId: string): Observable<any> {
    const url = this.baseURL + cwId + '/attachments';
    return this.http.get<ClientWelcome>(url, this.httpService.getHttpFileOptions());
  }

  postClientWelcomeAttachment(cwId: string, image) {
    const url = this.baseURL + cwId + '/attachments';
    return this.http.post(url, image, this.httpService.getHttpFileOptions());
  }

  patchClientWelcomeAttachment(cwId: string, image) {
    const url = this.baseURL + cwId + '/attachments';
    return this.http.patch(url, image, this.httpService.getHttpFileOptions());
  }

  deleteClientWelcomeAttachment(cwId: string) {
    const url = this.baseURL + cwId + '/attachments';
    return this.http.delete(url, this.httpService.getHttpOptions());
  }
}
