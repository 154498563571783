import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, Subscription, of, forkJoin } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import { UserService } from './felixApi/user.service';
import { NotificationService } from './notification.service';
import { AuthService } from './auth.service';
import { TruthEngineSession } from '../dtos/session';
import { CompanyService } from './felixApi/company.service';
import { AuthApiService } from './felixApi/auth-api.service';
import { LogService } from './log.service';


@Injectable({
    providedIn: 'root'
})
export class RefreshGuard implements CanActivate {

    subscriptions: Subscription[] = [];

    constructor(
        private router: Router, private globalService: GlobalService,
        private auth: AuthService, private authApi: AuthApiService,
        private userService: UserService, private notiService: NotificationService,
        private compService: CompanyService, private logger: LogService
    ) { }

    canActivate() {
        if (!this.globalService.isHttps()) {
            this.router.navigate(['']);
        }

        this.auth.currentVersion = environment.fullVersion;

        this.checkCorrectVersion();

        if (this.auth.getUserEmail()) {
            return true;
        } else {
            const session = this.globalService.getSessionObject();
            const email = this.auth.getMsalUserEmail();
            if (session.currentCompanyId && email) {
                return this.restoreFromCache(session, email);
            } else {
                this.router.navigate(['']);
            }
        }
    }

    checkCorrectVersion() {
        this.authApi.getCurrentApplicationVersion().subscribe(appVersion => {
            if (appVersion.version.trim() !== environment.majorVersion) {
                this.auth.versionIncorrect = true;
                this.auth.correctVersion = appVersion.version.trim();
                console.log('version incorrect so routing to login');
            } else {
                this.auth.versionIncorrect = false;
            }
        },
        err => {
            this.logger.log('refresh-guard', '', err, 'couldn\'t get app version', true);
        });
    }

    restoreFromCache(session: TruthEngineSession, email: string): Observable<boolean> {
        return this.authApi.getAuthorisedCompanies(email)
            .pipe(mergeMap(
                comps => {
                    const company = comps.filter(x => x.id === session.currentCompanyId);
                    if (company.length !== 1) {
                        return of(this.error());
                    }
                    this.globalService.setCurrentCompany(company[0]);
                    return forkJoin([
                        this.userService.getUser(company[0].userId),
                        this.compService.getCompanyConfigurations()
                    ])
                        .pipe(map(
                            ([user, config]) => {
                                this.auth.setCurrentUser(user);
                                this.auth.signIn();
                                this.globalService.setCurrentCompany(company[0]); // run again to get perms
                                this.globalService.setAreaSelected(session.areaSelected);
                                // reread the configs
                                this.globalService.companyConfiguration = config;
                                this.globalService.compConfigChanged.next(null);
                                return true;
                            }, (err) => {
                                return this.error();
                            }
                        ));
                }
            ));
    }

    error(): boolean {
        this.router.navigate(['']);
        this.notiService.showError('There was an error fetching data from the session so you will have to login again');
        return false;
    }
}
