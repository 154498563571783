import { Directive, ElementRef, Input, OnInit, EventEmitter, HostListener, Renderer2, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[TextHighlight]'
})
export class TextHighlighterDirective implements OnInit {
  @Input() searchStrings: string[];
  @Input() styleToApply: string;
  @Input() initialText: string;
  @Input() highlightDebounce = 500;

  @Output() styleStrippedText = new EventEmitter<string>();

  regex: any;
  regexSearchStrings: string[] = [];
  caretIndex: number;

  private searchDebounceSubject: Subject<any> = new Subject();

  @HostListener('keyup', ['$event']) parse(event: KeyboardEvent) {
    if (event.key !== 'Enter') { // prevent caret resetting to previous line after pressing enter without typing anything
      this.searchDebounceSubject.next(null);
    }
  }


  constructor(private el: ElementRef, private renderer: Renderer2) {}


  ngOnInit() {

    this.searchStrings.forEach( (s, index) => {
      this.regexSearchStrings[index] = this.escapeString(s);
    }, this);

    this.searchDebounceSubject.pipe(
      debounceTime(this.highlightDebounce)
    ).subscribe(x => {
      this.colorize();
    });

    if (this.initialText) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.initialText);
      this.colorize();
    }
  }


  public colorize() {

    this.caretIndex = this.getCaretCharacterOffsetWithin(this.el.nativeElement);

    this.el.nativeElement.innerHTML = this.removePreviousStylings();

    for (let i = 0; i < this.searchStrings.length; i++) {
      this.replace(i);
    }

    this.setCurrentCursorPosition(this.caretIndex);

    let updatedStrippedText = this.removePreviousStylings();
    updatedStrippedText = updatedStrippedText.replace('&lt;', '<');
    updatedStrippedText = updatedStrippedText.replace('&gt;', '>');

    if (updatedStrippedText !== this.initialText) {
      this.styleStrippedText.emit(updatedStrippedText);
    }
  }


  replace(index: number) {
    this.regex = new RegExp(this.regexSearchStrings[index], 'g');
    const originalText = this.el.nativeElement.innerHTML;
    let match;
    const matches = [];

    while ((match = this.regex.exec(originalText)) != null) {
      matches.push(match.index);
    }

    let i;
    let start = 0;
    let content = '';
    const element = '<span style="' + this.styleToApply + '">' + this.searchStrings[index] + '</span><!--highlighterDir-->';

    for (i = 0; i < matches.length; i++) {
      content += originalText.substring(start, matches[i]) + element;
      start = matches[i] + this.searchStrings[index].length;
    }
    content += originalText.substring(matches[i - 1] + this.searchStrings[index].length, originalText.length);

    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', content);
  }


  removePreviousStylings(): string {
    const escapedStyle = this.escapeString('<span style="' + this.styleToApply + '">');
    let reg = new RegExp(escapedStyle, 'gm');
    let stripped = this.el.nativeElement.innerHTML.replace(reg, '');

    const closingTag = this.escapeString('</span><!--highlighterDir-->');
    reg = new RegExp(closingTag, 'gm');
    stripped = stripped.replace(reg, '');
    return stripped;
  }

  escapeString(str: string) {
    const matchOperatorsRe = /[|\\{}()[\]^$+*?.]/g;
    return str.replace(matchOperatorsRe, '\\$&');
  }

  getCaretCharacterOffsetWithin(element) {
    let caretOffset = 0;
    const doc = element.ownerDocument || element.document;
    const win = doc.defaultView || doc.parentWindow;
    let sel;
    if (typeof win.getSelection !== 'undefined') {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
            const range = win.getSelection().getRangeAt(0);
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            caretOffset = preCaretRange.toString().length;
        }
    } else if ( (sel = doc.selection) && sel.type !== 'Control') {
        const textRange = sel.createRange();
        const preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint('EndToEnd', textRange);
        caretOffset = preCaretTextRange.text.length;
    }
    return caretOffset;
  }

  setCurrentCursorPosition(chars) {
    if (chars >= 0) {
        const selection = window.getSelection();

        const range = this.createRange(this.el.nativeElement, { count: chars });

        if (range) {
            range.collapse(false);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }
}

  createRange(node, chars, range = null) {
    if (!range) {
        range = document.createRange();
        range.selectNode(node);
        range.setStart(node, 0);
    }

    if (chars.count === 0) {
        range.setEnd(node, chars.count);
    } else if (node && chars.count > 0) {
        if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent.length < chars.count) {
                chars.count -= node.textContent.length;
            } else {
                range.setEnd(node, chars.count);
                chars.count = 0;
            }
        } else {
           for (let lp = 0; lp < node.childNodes.length; lp++) {
                range = this.createRange(node.childNodes[lp], chars, range);

                if (chars.count === 0) {
                    break;
                }
            }
        }
    }

    return range;
  }

}


