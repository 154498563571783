import { Component, OnInit } from '@angular/core';
import { START_ATTACHMENT_NAME, END_ATTACHMENT_NAME, SEPARATE_ATTACHMENT_NAME } from './attachment-settings-api.service';


@Component({
  selector: 'js-attachment-settings',
  templateUrl: './attachment-settings.component.html',
  styleUrls: ['./attachment-settings.component.scss']
})
export class AttachmentSettingsComponent implements OnInit {

  startMode = START_ATTACHMENT_NAME;
  endMode = END_ATTACHMENT_NAME;
  separateMode = SEPARATE_ATTACHMENT_NAME;

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
