import { NotificationService } from './../../services/notification.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridComponent } from 'devextreme-angular';

import { GridService } from '../../services/grid.service';
import { ClaimsService } from './services/claims.service';
import { ClaimLinesComponent } from './claim-lines/claim-lines.component';
import { ManageAttachFileComponent } from '../attachment-settings/manage-attach-file/manage-attach-file.component';
import { GlobalService } from '../../services/global.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss']
})
export class ClaimsComponent implements OnInit {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: any = {};
  loading = true;
  gridHeight: number;

  constructor(
    private claimsService: ClaimsService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private notiService: NotificationService,
    public gridService: GridService
  ) {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.gridHeight = window.innerHeight - 110;
      })
    );
  }

  ngOnInit(): void {
    this.gridHeight = window.innerHeight - 110;
    this.dataSource = this.claimsService.getClaimMasterDataSource();
    this.loading = false;
  }

  refresh() {
    this.claimsService.clearCaches();
    this.grid.instance.refresh();
  }

  openClaimSet(e) {
    if (e.row.isNewRow) {
      this.notiService.showInfo('Save record before adding claim lines');
    } else {
      const modalRef = this.modalService.open(ClaimLinesComponent, { windowClass: 'modal-1300' });
      modalRef.componentInstance.claimMasterId = e.data.id;
      modalRef.componentInstance.claimMasterDescription = e.data.description;

      modalRef.result.then((refresh) => {
        if (refresh) {
          this.refresh();
        }
      });
    }
  }

  manageFile(e) {
    if (e.row.isNewRow) {
      this.notiService.showInfo('Save record before adding file');
    } else {
      this.claimsService.currentClaimMaster = e.data;
      const modalRef = this.modalService.open(ManageAttachFileComponent);
      modalRef.componentInstance.attachmentParentRecordId = e.data.id;
      modalRef.componentInstance.claimMode = true;

      modalRef.result.then((refresh) => {
        this.claimsService.currentClaimMaster = null;
        if (refresh) {
          this.refresh();
        }
      });
    }
  }

  onInitNewRow(e) {
    e.data.isActive = true;
    e.data.isRoundAmounts = true;
  }
}
