import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalService } from '../../services/global.service';
import { NotificationControl, EmailControlType } from '../../dtos/notifications';
import { environment } from '../../../environments/environment';
import { HttpService } from '../..//services/http.service';


@Injectable()
export class NotificationControlService {

    emailControlBaseURL = environment.apiUrl + '/email-control/';
    subscriptions: Subscription[] = [];

    genericControl: NotificationControl;
    clientControl: NotificationControl;
    variationControl: NotificationControl;


    constructor(
        private http: HttpClient,
        private globalService: GlobalService,
        private httpService: HttpService
    ) {
        this.subscriptions.push(
            this.globalService.companySelected.subscribe(selectedComp => {
                this.genericControl = null;
                this.clientControl = null;
                this.variationControl = null;
            })
        );
    }

    getGenericControl(forceUpdate = false) {
        if (!forceUpdate && this.genericControl) {
            return of(this.genericControl);
        }
        return this.getNotiControlApi(EmailControlType.Generic)
        .pipe(
            map(con => {
                this.genericControl = con;
                return con;
            })
        );
    }

    getClientControl() {
        if (this.clientControl) {
            return of(this.clientControl);
        }
        return this.getNotiControlApi(EmailControlType.ClientStatus)
        .pipe(
            map(con => {
                this.clientControl = con;
                return con;
            })
        );
    }

    getVariationControl() {
        if (this.variationControl) {
            return of(this.variationControl);
        }
        return this.getNotiControlApi(EmailControlType.VariationStatus)
        .pipe(
            map(con => {
                this.variationControl = con;
                return con;
            })
        );
    }

    private getNotiControlApi(controlType: number): Observable<NotificationControl> {
        const url = this.emailControlBaseURL + '?emailControlTypeId=' + controlType.toString();
        return this.http.get<NotificationControl>(url, this.httpService.getHttpOptions());
    }

    /* backend not returning updated obj so caching passed updated obj */
    patchNotiControl(control: NotificationControl): Observable<NotificationControl> {
        const url = this.emailControlBaseURL + control.id;
        return this.http.patch<NotificationControl>(url, control, this.httpService.getHttpOptions())
                .pipe(map(c => {
                    this.updateCache(control); // change to c once backend working
                    return c;
                })
            );
    }

    /* backend not returning updated obj so caching passed updated obj */
    postNotiControl(control: NotificationControl): Observable<NotificationControl> {
        return this.http.post<NotificationControl>(this.emailControlBaseURL, control, this.httpService.getHttpOptions())
                .pipe(map(c => {
                    this.updateCache(control); // change to c once backend working
                    return c;
                })
            );
    }


    private updateCache(control: NotificationControl) {
        if (control.emailControlTypeId === EmailControlType.Generic) {
            this.genericControl = control;
        } else if (control.emailControlTypeId === EmailControlType.VariationStatus) {
            this.variationControl = control;
        } else {
            this.clientControl = control;
        }
    }

    patchNotiControlLogo(image, id) {
        const url = this.emailControlBaseURL + id + '/logo';
        return this.http.patch(url, image, this.httpService.getHttpFileOptions())
        .pipe(map((c: NotificationControl) => {
            this.genericControl = c;
            return c;
        }));
    }

    testVariationEmail() {
        const fakeVarId = 123;
        const url = environment.apiUrl + '/emails/job-variations/' + fakeVarId + '?sendTest=true';
        return this.http.post(url, null, this.httpService.getHttpOptions());
    }

    testClientEmail() {
        // const fakeVarId = 123;
        const url = environment.apiUrl + '/emails/client-notification/?sendTest=true';
        return this.http.post(url, null, this.httpService.getHttpOptions());
    }

}
