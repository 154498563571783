import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { AuthService } from '../../services/auth.service';
import { OptionHeaderFormService } from './option-header-form.service';
import { OptionHeaderService } from './option-header.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';

@Component({
  selector: 'js-option-header-form',
  templateUrl: './option-header-form.component.html',
  styleUrls: ['./option-header-form.component.scss']
})
export class OptionHeaderFormComponent implements OnInit, OnDestroy {

  htmlPositionMode = 'above';
  headerStylingEnabled = false;
  customerUpdateAllowed: boolean;

  constructor(
    public headerFormService: OptionHeaderFormService,
    public headerService: OptionHeaderService,
    private _globalService: GlobalService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    // check configs
    this._globalService.companyConfiguration.forEach(config => {
      if (config.configurationId === ConfigurationEnum.CustomerUpdateAllowed && config.configurationValue === 1) {
        this.customerUpdateAllowed = true;
      }
    });

    if (this.auth.isAdminOrSuper() && this.customerUpdateAllowed) {
      this.headerStylingEnabled = true;
    }

    this.headerFormService.inOptionsModal = true;
  }

  invalidHTML(e) {
    if (e) {
      this.headerService.htmlInvalid = true;
    } else {
      this.headerService.htmlInvalid = false;
    }
  }

  ngOnDestroy() {
    this.headerFormService.inOptionsModal = false;
  }
}
