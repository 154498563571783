import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { JobDocumentType } from '../../dtos/job-document-type';
import { UtilsService } from '../../services/utils.service';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';


@Injectable({
  providedIn: 'root'
})
export class InformationTypeService {

  baseURL = environment.apiUrl + '/job-document-types/';
  cache: JobDocumentType[];

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private utilsService: UtilsService
  ) {}

  getJobDocumentTypes() {
    if (this.cache) {
      return of(this.cache);
    } else {
      return this.http.get<JobDocumentType[]>(this.baseURL, this.httpService.getHttpOptions()).pipe(
        tap(res => this.cache = res),
        catchError(this.handleError));
    }
  }

  addJobDocumentType(jobDocumentType: JobDocumentType) {
    return this.http.post<JobDocumentType>(this.baseURL, JSON.stringify(jobDocumentType), this.httpService.getHttpOptions())
    .pipe(
      tap(res => {
          this.cache.push(res);
      })
    );
  }

  updateJobDocumentType(id: string, updatedRecord: any) {
    return this.http.patch<JobDocumentType>(this.baseURL + id, JSON.stringify(updatedRecord), this.httpService.getHttpOptions())
    .pipe(
      tap(res => {
          this.utilsService.editCache(res, this.cache);
      })
    );
  }

  deleteJobDocumentType(id: string) {
    return this.http.delete(this.baseURL + id, this.httpService.getHttpOptions())
    .pipe(
      tap(res => {
          this.utilsService.deleteFromCache(id, this.cache);
      })
    );
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
