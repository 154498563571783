import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationControl, EmailControlType } from '../../../dtos/notifications';
import NotificationsConfig from '../../../../config/notificationConfig';

import { NotificationService } from '../../../services/notification.service';
import { GlobalService } from '../../../services/global.service';
import { TextHighlighterDirective } from '../../../shared/textHighlighter.directive';
import { ConfirmationModalComponent } from '../../../shared/confirmation-modal.component';
import { DefaultBtnEnum } from '../../../dtos/notifications';
import { NotificationControlService } from '../../services/noti-control.service';


@Component({
  selector: 'js-variation-email',
  templateUrl: './variation-email.component.html',
  styleUrls: ['./variation-email.component.scss']
})
export class VariationEmailComponent implements OnInit, OnDestroy {

  @ViewChild('messageArea')
  messageArea: ElementRef;
  @ViewChild('subjectAreaVariation')
  subjectAreaVariation: ElementRef;
  @ViewChildren(TextHighlighterDirective)
  textHighlighters: TextHighlighterDirective[];
  @ViewChild('focusElement')
  focusElement: ElementRef;

  notificationControl: NotificationControl;
  // notiControlForm: FormGroup;
  notificationsConfig = NotificationsConfig;

  defaultBtnText: string;
  messageText: string;
  subjectText: string;
  textVariableStyling = 'color:var(--bootstrap-blue);';

  submitting = false;
  sendingTest = false;
  loading = true;
  noControl = false;
  dirtyForm = false;

  subscriptions: Subscription[] = [];
  defaultBtnEnum = DefaultBtnEnum;


  constructor(
    private notiControlService: NotificationControlService, private globalService: GlobalService,
    private notiService: NotificationService, private modalService: NgbModal,
    private router: Router, private formBuilder: UntypedFormBuilder,
  ) {

      this.subscriptions.push(
        this.notiControlService.getVariationControl().subscribe(control => {
          this.notificationControl = control;
          this.setupForm();
          this.loading = false;
          this.focus();
        }, err => {
          if (err.status === 422 && err.error.message.indexOf('Missing Email Control Master Record') > -1) {
            this.noControl = true;
            this.setupForm();
            // tslint:disable-next-line: forin
            // for (const i in this.notiControlForm.controls) {
            //  this.notiControlForm.controls[i].markAsTouched();
            // }
            // this.dirtyForm = true; // allow submit of defaults
            this.loading = false;
          } else {
            this.notiService.notify(err);
            this.router.navigate(['notifications']);
          }
        })
      );
    }

  ngOnInit() {
  }

  setupForm() {
    /*this.notiControlForm = this.formBuilder.group({
      subject: [ this.notificationControl && this.notificationControl.subject ?
                this.notificationControl.subject : this.notificationsConfig.DefaultSubject,
                Validators.required ],
      message: [ this.notificationControl && this.notificationControl.message ?
                this.notificationControl.message : this.notificationsConfig.DefaultMessage,
                Validators.required ]
    }); */

    this.messageText = this.notificationControl && this.notificationControl.message ? this.notificationControl.message
        : this.notificationsConfig.DefaultVariationMessage;
    this.subjectText = this.notificationControl && this.notificationControl.subject ? this.notificationControl.subject
        : this.notificationsConfig.DefaultVariationSubject;

    if (!this.notificationControl ||
         (this.notificationControl && this.notificationControl.message === this.notificationsConfig.DefaultVariationMessage
            && this.notificationControl.subject) === this.notificationsConfig.DefaultVariationSubject) {
         this.defaultBtnText = DefaultBtnEnum.default;
       } else {
         this.defaultBtnText = DefaultBtnEnum.custom;
       }
  }

  submit() {
    this.submitting = true;
    // this.notiControlForm.disable();
    const updatedControl = this.setupdatedControl();

    if (this.notificationControl) {
      this.patchControl(updatedControl);
    } else {
      this.postControl(updatedControl);
    }
  }

  updateSubjectText(event) {
    this.subjectText = event;
    this.dirtyForm = true;

    if (this.messageText === this.notificationsConfig.DefaultVariationMessage
      && this.subjectText === this.notificationsConfig.DefaultVariationSubject) {
      this.defaultBtnText = DefaultBtnEnum.default;
    } else {
      this.defaultBtnText = DefaultBtnEnum.custom;
    }
  }

  updateMessageText(event) {
    this.messageText = event;
    this.dirtyForm = true;

    if (this.messageText === this.notificationsConfig.DefaultVariationMessage
      && this.subjectText === this.notificationsConfig.DefaultVariationSubject) {
      this.defaultBtnText = DefaultBtnEnum.default;
    } else {
      this.defaultBtnText = DefaultBtnEnum.custom;
    }
  }

  setupdatedControl(): NotificationControl {
    return {
      id: this.notificationControl ? this.notificationControl.id : null,
      emailControlTypeId: EmailControlType.VariationStatus,
      // subject: this.notiControlForm.get('subject').value,
      // message: this.notiControlForm.get('message').value,
      subject: this.subjectText,
      message: this.messageText
    };
  }

  patchControl(updatedControl: NotificationControl) {
    this.notiControlService.patchNotiControl(updatedControl).subscribe( res => {
      this.success();
    }, err => {
      this.error(err);
    });
  }

  postControl(updatedControl: NotificationControl) {
    this.notiControlService.postNotiControl (updatedControl).subscribe( res => {
      this.success();
    }, err => {
      this.error(err);
    });
  }

  testVariationEmail() {
    this.sendingTest = true;
    this.notiControlService.testVariationEmail().subscribe( res => {
      this.notiService.showSuccess('Test Email sent');
      this.sendingTest = false;
    }, err => {
      this.notiService.showError('There was an error sending the test email. Please try again later');
      this.sendingTest = false;
    });
  }

  restoreDefaultSelected() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-middle' });
    modalRef.componentInstance.confirmationText = this.notificationsConfig.DefaultConfirmation;

    modalRef.result.then((confirmed) => {
      if (confirmed) {
        this.restoreDefaults();
      }
    }, (reason) => {
    });
  }

  restoreDefaults() {
    /*  USING STANDARD FORM FIELDS (NON_VARIABLE-HIGHLIGHTING)
    if (this.notiControlForm.controls['subject'].value !== this.notificationsConfig.DefaultSubject ||
        this.notiControlForm.controls['message'].value !== this.notificationsConfig.DefaultMessage) {
      this.dirtyForm = true;
      this.notiControlForm.controls['subject'].setValue(this.notificationsConfig.DefaultSubject);
      this.notiControlForm.controls['message'].setValue(this.notificationsConfig.DefaultMessage);
    } else {
      this.notiService.showInfo('The default Subject and Message fields are already being used.');
    }
    */
   if (this.subjectAreaVariation.nativeElement.innerHTML !== this.notificationsConfig.DefaultVariationSubject ||
       this.messageArea.nativeElement.innerHTML !== this.notificationsConfig.DefaultVariationMessage) {

      this.dirtyForm = true;
      this.subjectAreaVariation.nativeElement.innerHTML = this.notificationsConfig.DefaultVariationSubject;
      this.messageArea.nativeElement.innerHTML = this.notificationsConfig.DefaultVariationMessage;
    }

    this.textHighlighters.forEach(highlightDirective => {
      highlightDirective.colorize();
    });

    this.defaultBtnText = DefaultBtnEnum.default;
  }

  focus() {
    setTimeout( () => {
      this.focusElement?.nativeElement?.focus();
    }, 100);
  }

  success() {
    this.noControl = false;
    this.dirtyForm = false;
    this.notiService.showSuccess('Email settings successfully updated');
    this.submitting = false;
    // this.notiControlForm.enable();
  }

  error(err) {
    this.notiService.notify(err);
    // this.notiControlForm.enable();
    this.submitting = false;
  }

  configureScenarios() {
    this.router.navigate(['notifications']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach( sub => {
      sub.unsubscribe();
    });
  }

}
