export const SessionName = 'TruthEngineConfigSession';

export class TruthEngineSession {
    currentCompanyId?: number;
    isGetChildren?: boolean;
    areaSelected?: string;
    currentJobNo?: string;
    currentJobItemAbove?: number;
    passedCompanyId?: string;
    loginProcessed?: boolean;
    login?: boolean;
    passedVariationNumber?: string;
    isDebugMode?: boolean;
}
