import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { User } from '../../dtos/user';
import { HttpService } from '../http.service';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    baseURL = environment.apiUrl + '/users/';

    constructor(
        private http: HttpClient,
        private httpService: HttpService
    ) { }

    getCurrCompUsers(userTypeId: number = null): Observable<User[]> {
        let url = environment.apiUrl + '/companies/users';
        if (userTypeId !== null) {
            url += '?userTypeId';
        }

        return this.http.get<User[]>(url, this.httpService.getHttpOptions());
    }

    getUser(id: number): Observable<User> {
        return this.http.get<User>(this.baseURL + id, this.httpService.getHttpOptions());
    }

    getUserByEmail(email: string) {
        return this.http.get<User>(this.baseURL + email, this.httpService.getHttpOptions());
    }

}
