import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { DEFAULT_HEADER_HTML_BACKGROUND_COLOR,
  HTML_STYLE_DEFAULT_ABOVE, HTML_STYLE_DEFAULT_BELOW,
  HTML_STYLE_DEFAULT_BESIDE } from '../../../config/global-config';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { OptionHeader } from '../types/option-header';
import { GlobalService } from '../../services/global.service';
import { FileProcessingService } from '../../services/file-processing.service';
import { NotificationService } from '../../services/notification.service';
import { LogService } from '../../services/log.service';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class OptionHeaderService {

  headerFetched = new EventEmitter();
  missingHeaderOptionId: string;

  htmlInvalid = false;
  aboveHTML: string = HTML_STYLE_DEFAULT_ABOVE;
  besideHTML: string = HTML_STYLE_DEFAULT_BESIDE;
  belowHTML: string = HTML_STYLE_DEFAULT_BELOW;

  currentHeader: OptionHeader;
  backGrndColor = DEFAULT_HEADER_HTML_BACKGROUND_COLOR;
  // customBackground = false;
  mandatory = false;
  confirmation = false;

  baseURL = environment.apiUrl + '/client-selection-option-headers/';

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private auth: AuthService,
    private imgService: FileProcessingService,
    private notiService: NotificationService,
    private logger: LogService
  ) { }

  fetchHeader(optionId: string) {
    this.getOptionHeaderByOption(optionId)
      .subscribe((header: OptionHeader) => {
        this.currentHeader = header;

        this.aboveHTML = header.htmlDetailAboveImage === '' ? HTML_STYLE_DEFAULT_ABOVE : header.htmlDetailAboveImage;
        this.besideHTML = header.htmlDetailAsideImage === '' ? HTML_STYLE_DEFAULT_BESIDE : header.htmlDetailAsideImage;
        this.belowHTML = header.htmlDetailBelowImage === '' ? HTML_STYLE_DEFAULT_BELOW : header.htmlDetailBelowImage;
        this.backGrndColor = header.backgroundColor === null ? DEFAULT_HEADER_HTML_BACKGROUND_COLOR : header.backgroundColor;
        // this.customBackground = header.backgroundColor && header.backgroundColor.length > 0;
        this.mandatory = header.isCompulsory;
        this.confirmation = header.isConfirmItem;

        this.headerFetched.emit(null);
        this.missingHeaderOptionId = null;
      }, err => {
        if (err.status === 404) {
          this.missingHeaderOptionId = optionId;
        } else {
          this.notiService.showError('There was an error retrieving the header record. Please try again in a moment');
          this.logger.log('option-header-service', 'fetchHeader()', err);
        }
      });
  }

  fetchImage(): Observable<any> {
    if (this.currentHeader && this.currentHeader.hasBlob) {
      return this.getheaderImage(this.currentHeader.id);
    } else {
      return of(null);
    }
  }

  // try get header for option if current not already set
  // delete if found
  deleteHeaderIfExists(optionId: string): Observable<any> {
    if (!this.currentHeader) {
      return this.getOptionHeaderByOption(optionId)
        .pipe(
          map(res => this.currentHeader = res),
          mergeMap(res => this.deleteCurrentHeader())
        );
    } else {
      return this.deleteCurrentHeader();
    }
  }

  deleteCurrentHeader(): Observable<any> {
    let sub;
    if (this.currentHeader.hasBlob) {
      sub = this.deleteCurrentHeaderImage()
        .pipe(
          mergeMap(res => this.deleteHeader(this.currentHeader.id))
        );
    } else {
      sub = this.deleteHeader(this.currentHeader.id);
    }
    return sub;
  }

  deleteCurrentHeaderImage(): Observable<any> {
    if (this.currentHeader) {
      return this.deleteHeaderImage(this.currentHeader.id);
    }
  }

  addOptionHeader(optionId: string, image?): Observable<any> {
    const header: OptionHeader = {
      id: null,
      optionListId: optionId,
      htmlDetailAboveImage: this.aboveHTML === HTML_STYLE_DEFAULT_ABOVE ? '' : this.aboveHTML,
      htmlDetailAsideImage: this.besideHTML === HTML_STYLE_DEFAULT_BESIDE ? '' : this.besideHTML,
      htmlDetailBelowImage: this.belowHTML === HTML_STYLE_DEFAULT_BELOW ? '' : this.belowHTML,
      hasBlob: false,
      isConfirmItem: this.confirmation,
      isCompulsory: this.mandatory,
      backgroundColor: this.backGrndColor,
      modifiedDate: new Date(),
      modifiedUserId: this.auth.getUserId(),
      createDate: new Date(),
      createUserId: this.auth.getUserId()
    };

    return this.postOptionHeader(header).pipe(
      mergeMap((res: OptionHeader) => {
        if (image) {
          return this.imgService.compressImage(image)
            .pipe(
              mergeMap(compressed =>
                this.postHeaderImage(res.id, compressed)
              ),
              map(() => true)
            );
        } else {
          return of(true);
        }
      })
    );
  }

  editHeader(image?): Observable<any> {
    // for options set to notSelectable (type header) before these changes
    if (this.missingHeaderOptionId) {
      return this.addOptionHeader(this.missingHeaderOptionId)
        .pipe(tap(this.missingHeaderOptionId = null));
    } else {
      const header = {
        id: this.currentHeader.id,
        htmlDetailAboveImage: this.aboveHTML === HTML_STYLE_DEFAULT_ABOVE ? '' : this.aboveHTML,
        htmlDetailAsideImage: this.besideHTML === HTML_STYLE_DEFAULT_BESIDE ? '' : this.besideHTML,
        htmlDetailBelowImage: this.belowHTML === HTML_STYLE_DEFAULT_BELOW ? '' : this.belowHTML,
        hasBlob: this.currentHeader.hasBlob,
        isConfirmItem: this.confirmation,
        isCompulsory: this.mandatory,
        backgroundColor: this.backGrndColor,
        modifiedDate: new Date(),
        modifiedUserId: this.auth.getUserId(),
      };

      return this.patchOptionHeader(header).pipe(
        mergeMap((res: OptionHeader) => {
          if (image && this.currentHeader.hasBlob) {
            return this.imgService.compressImage(image)
              .pipe(
                mergeMap(compressed => this.patchHeaderImage(res.id, compressed)),
                map(() => true)
              );
          } else if (image) {
            return this.imgService.compressImage(image)
              .pipe(
                mergeMap(compressed => this.postHeaderImage(res.id, compressed)),
                map(() => true)
              );
          } else {
            return of(true);
          }
        })
      );
    }

  }

  // restoreDefaults() {
  //   this.htmlInvalid = false;
  //   this.confirmation = false;
  //   this.mandatory = false;
  //   this.customBackground = false;
  //   this.backGrndColor = DEFAULT_HTML_BACKGROUND_COLOR;
  //   this.aboveHTML = HTML_STYLE_DEFAULT_ABOVE;
  //   this.besideHTML = HTML_STYLE_DEFAULT_BESIDE;
  //   this.belowHTML= HTML_STYLE_DEFAULT_BELOW;
  //   this.currentHeader = null;
  //   this.missingHeaderOptionId = null;
  // }

  //// APIs ////

  getOptionHeaderByOption(optionId: string): Observable<any> {
    const url = environment.apiUrl + '/option-list/' + optionId + '/client-selection-option-header';
    return this.http.get(url, this.httpService.getHttpOptions());
  }

  postOptionHeader(header: OptionHeader): Observable<OptionHeader> {
    return this.http.post<OptionHeader>(this.baseURL, header, this.httpService.getHttpOptions());
  }

  patchOptionHeader(header): Observable<any> {
    const url = this.baseURL + header.id;
    return this.http.patch(url, header, this.httpService.getHttpOptions());
  }

  deleteHeader(headerId: string): Observable<any> {
    const url = this.baseURL + headerId;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }

  getheaderImage(headerId: string): Observable<any> {
    const url = this.baseURL + headerId + '/attachments';
    return this.http.get(url, this.httpService.getHttpFileOptions());
  }

  postHeaderImage(headerId: string, imageFile): Observable<any> {
    const url = this.baseURL + headerId + '/attachments';
    return this.http.post(url, imageFile, this.httpService.getHttpFileOptions());
  }

  patchHeaderImage(headerId: string, imageFile): Observable<any> {
    const url = this.baseURL + headerId + '/attachments';
    return this.http.patch(url, imageFile, this.httpService.getHttpFileOptions());
  }

  deleteHeaderImage(headerId: string): Observable<any> {
    const url = this.baseURL + headerId + '/attachments';
    return this.http.delete(url, this.httpService.getHttpFileOptions());
  }
}
