import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { IJob } from '../dtos/job';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class JobService {

    baseURL = environment.apiUrl + '/jobs/';

    constructor(private http: HttpClient, private httpService: HttpService) {}

    getJobsForCompany() {
        // /jobs API is used as search API and requires search option
        return this.http.get<IJob[]>(this.baseURL + '?jobSearchType=ContractName', this.httpService.getHttpOptions());
    }

}
