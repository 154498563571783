import { Bank } from './../../dtos/bank';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BankService {

  baseURL = environment.apiUrl + '/banks/';
  banks: Bank[];

  constructor(private http: HttpClient,
    private httpService: HttpService
  ) { }

  getBanks() {
    return this.http.get<Bank[]>(this.baseURL, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.banks = res;
      }),
      catchError(this.httpService.handleHTTPError));
  }

  addBank(bank: Bank) {
    return this.http.post<Bank>(this.baseURL, JSON.stringify(bank), this.httpService.getHttpOptions());
  }

  updateBank(id: string, updatedRecord: any) {
    return this.http.patch<Bank>(this.baseURL + id, JSON.stringify(updatedRecord), this.httpService.getHttpOptions());
  }

  deleteBank(id: string) {
    return this.http.delete(this.baseURL + id, this.httpService.getHttpOptions());
  }
}
