import { Component, Input, TemplateRef, OnInit, OnDestroy, Injectable } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import HelpConfig from '../../config/help';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';


@Injectable()
export class HelpService {

    public showHelp: Subject<boolean> = new Subject<boolean>();

    constructor() {}

}

@Component({
    selector: 'js-help-modal',
    template: `
        <div *ngIf="title" style="height: 50px; padding-top: 8px;" class="modal-header">
            <h3 class="modal-title" style="font-size: 22px;">{{title}}</h3>
            <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="font-size: 15px;">
            <div [innerHTML]="sanitisedTemplate"></div>
        </div>
    `
})
export class HelpModalComponent implements OnInit {

    @Input() title: string;
    @Input() text: string;
    @Input() textTemplate: TemplateRef<any>;

    sanitisedTemplate: SafeHtml;

    constructor(private sanitiser: DomSanitizer, private activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.sanitisedTemplate = this.sanitiser.bypassSecurityTrustHtml(this.text);
    }

    cancel() {
        this.activeModal.close();
    }
}


@Component({
    selector: 'js-help',
    template: `
    <ng-container *ngIf="showHelp">
        <i (click)="help()" class="material-icons md-18 help">help_outline</i>
    </ng-container>
    <ng-container *ngIf="!showHelp && invisiblePlaceholder">
        <div style="width: 18px; height: 18px"></div>
    </ng-container>
    `,
    styles: ['.help {color: #b9b7b7;} .help:hover {color: grey; cursor: pointer;}']
})
export class HelpComponent implements OnInit {

    /* FORMAT: 'helpSection.helpText' */
    @Input() configTextPath: string;
    /* Uses 'helpSection: helpText' format if not provided */
    @Input() title: string;
    /*  configTextPath used in favour of custom text*/
    @Input() text: string;

    @Input() alwaysShow = false;

    @Input() invisiblePlaceholder = true;

    showHelp = false;

    constructor(private modalService: NgbModal, private helpService: HelpService) {}

    ngOnInit() {
        this.showHelp = this.alwaysShow;
        if (!this.alwaysShow) {
            this.helpService.showHelp.subscribe( show => {
                this.showHelp = show;
            });
        }
    }

    help() {
        let helpText: string;
        let titleText: string;
        if (this.configTextPath) {
            const split = this.configTextPath.split('.');
            helpText = HelpConfig[split[0]][split[1]];
            titleText = split[0] + ': ' + split[1];
        } else {
            helpText = this.text;
        }

        const modalRef = this.modalService.open(HelpModalComponent, {windowClass: 'modal-help'});
        modalRef.componentInstance.title = this.title ? this.title : titleText;
        modalRef.componentInstance.text = helpText;

        modalRef.result.then(res => {
        }, (reason) => {
        });
    }

}


@Component({
    selector: 'js-help-activate',
    template: '<i (click)="toggleHelp()" class="material-icons md-18 help" [class.active]="showHelp">help</i>',
    styles: ['.help {color: grey;} .help:hover {color: var(--bootstrap-blue); cursor: pointer;} .active {color: var(--bootstrap-blue)}']
})
export class HelpActivateComponent implements OnDestroy {

    showHelp = false;

    constructor(private helpService: HelpService) {}

    toggleHelp() {
        this.showHelp = !this.showHelp;
        this.helpService.showHelp.next(this.showHelp);
    }

    ngOnDestroy() {
        this.helpService.showHelp.next(false);
    }
}


