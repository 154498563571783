import { IOptionList } from './option-list';


export class OptionModalEventType {
    option?: IOptionList;
    addingAsChild?: boolean;
    failed?: boolean;
    deleteImage?: boolean;
    // addedRecord?: boolean;
    forceRefresh?: boolean;
}

export class OptionModalReturnType {
    option?: IOptionList;
    refresh?: boolean;
    delete?: boolean;
}

export class BulkPriceUploadResponse {
    pdfReport?: string;
    pdfReportName?: string;
}

export enum OptionModalMode {
    edit,
    add
}

//////////   BULK PRICING    ////////////
export class LoadTemplateResponse {
    pdfReport: Blob;
    pdfReportName: string;
}

export enum GetBulkTemplateMode {
    'Get Standard and House Opts',
    'Get House Opts only',
    'Get Standard Opts only'
}

export class UpdateModeDropDown {
    id: number;
    name: string;
}
////////////////////////////////////////
