import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { locale } from 'devextreme/localization';
import { MsalService } from '@azure/msal-angular';

import { GlobalService } from './services/global.service';
import { AuthService } from './services/auth.service';
import { NotificationService } from './services/notification.service';
import dxDataGrid from 'devextreme/ui/data_grid';


@Component({
  selector: 'js-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  innerWidth: number;
  isIframe = false;

  @ViewChild('ParentDiv') parentDiv: ElementRef;

  @HostListener('window:resize') onResize() {
    this.innerWidth = window.innerWidth;
    this.globalService.setInnerWidth(this.innerWidth);
  }

  constructor(
    private globalService: GlobalService,
    private msalService: MsalService, // needs to stay here
    private notiService: NotificationService,
    public authService: AuthService
  ) {
    locale('en-AU'); // can use navigator.language (browser language) but is mostly left on US default
    // config({ defaultCurrency: 'AUD' });

    // data grid default
    dxDataGrid.defaultOptions({
      options: {
        filterRow: { showAllText: '' }
      }
    });
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    this.innerWidth = window.innerWidth;
    this.globalService.setInnerWidth(this.innerWidth);

    const browser = window.navigator.userAgent.toLowerCase();
    if (browser.indexOf('firefox') > 0) {
      this.notiService.showWarning(
        'While this app functions on Firefox, Chrome is the officially supported browser. <br><br> If you encounter any issues please test on Chrome before raising it with support.',
        'Warning',
        {timeOut: 0, enableHtml: true, positionClass: 'toast-bottom-full-width'}
      );
    }
  }

  refresh(): void {
    window.location.reload();
  }

}
