import { Injectable } from '@angular/core';
import { HouseTypeApiService } from './house-type.api.service';
import { UtilsService } from '../../../services/utils.service';
import CustomStore from 'devextreme/data/custom_store';

@Injectable()
export class HouseTypeService {

  constructor(private houseTypeApiService: HouseTypeApiService, private utils: UtilsService) { }

  clearCaches() {
    this.houseTypeApiService.clearCaches();
  }

  getHouseTypeDataSource(): CustomStore {
    return new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.getHouseTypesCached().subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.postHouseType(values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.patchHouseType(values, key).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.deleteHouseType(key).subscribe(res => {
            return resolve();
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      }
    });
  }

  getHouseModDataSource(): CustomStore {
    return new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.getHouseTypeModsCached().subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.postHouseTypeMod(values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.houseTypeApiService.patchHouseTypeMod(values, key).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
    });
  }


  updateSellingPrices(reCostingDate) {
    return this.houseTypeApiService.updateSellingPrices(reCostingDate);
  }

  calculateActualSellingPrices(roundUpTo: number, houseTypeIds: number[], lessAmount: number) {
    return this.houseTypeApiService.calculateActualSellingPrices(roundUpTo, houseTypeIds, lessAmount);
  }
}
