import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { IOptionList } from '../types/option-list';
import { LoadTemplateResponse, GetBulkTemplateMode, BulkPriceUploadResponse } from '../types/option-list-misc.type';
import { OptionListAttachmentService } from './option-list-attachment.service';
import { HttpService } from '../../services/http.service';


@Injectable()
export class OptionListService implements OnDestroy {

    subscriptions: Subscription[] = [];
    baseURL = environment.apiUrl + '/option-lists/';

    private optionsCache: IOptionList[];
    private optionListOneLevel: IOptionList[];

    public refreshOptionListEvent = new EventEmitter<boolean>();

    public mediumHeadersThreshold = 700;
    public smallHeadersThreshold = 463;

    constructor(
        private _http: HttpClient,
        private httpService: HttpService,
        private optionimgService: OptionListAttachmentService,
        // private imgLibService: ImageLibraryService
    ) {
        this.subscriptions.push(
            this.httpService.clearCaches.subscribe(selectedComp => {
                this.optionsCache = null;
            })
        );
    }


    refreshOptionList() {
        this.refreshOptionListEvent.emit(true);
    }

    getOptionListCached(): Observable<IOptionList[]> {
        if (this.optionsCache) {
            return of(this.optionsCache);
        } else {
            return this.getOptionListChildren(0, true)
                .pipe(
                    map(list => {
                        this.optionsCache = list;
                        return this.optionsCache;
                    })
                );
        }
    }

    getHouseOptionList(): Observable<IOptionList[]> {
        return this._http.get<IOptionList[]>(this.baseURL + 'house-options-export', this.httpService.getHttpOptions());
    }

    clearOptionsCache() {
        this.optionsCache = null;
        this.optionimgService.imgCache = [];
    }

    getOptionListChildren(id, getChildren): Observable<IOptionList[]> {
        if (id === 0) {
            if (getChildren) {
                return this._http.get<IOptionList[]>(this.baseURL + '?includeChildren=true', this.httpService.getHttpOptions());
            } else {
                return this._http.get<IOptionList[]>(this.baseURL, this.httpService.getHttpOptions());
            }
        } else {
            if (getChildren) {
                return this._http.get<IOptionList[]>(
                    this.baseURL + '?optionListIdAbove=' + id + '&includeChildren=true',
                    this.httpService.getHttpOptions()
                );
            }
            return this._http.get<IOptionList[]>(
                this.baseURL + '?optionListIdAbove=' + id + '&includeChildren=false',
                this.httpService.getHttpOptions()
            );
        }
    }

    getOptionListOneLevel(): IOptionList[] {
        this.optionListOneLevel = [];

        this.optionsCache.forEach(opt => {
            const thisOpt = { ...opt };
            thisOpt.children = [];
            thisOpt.optionColour = 1;
            this.optionListOneLevel.push(thisOpt);

            this.getOptionListOneLevelChildren(opt, 2);
        });

        return this.optionListOneLevel;
    }

    getOptionListOneLevelChildren(node: IOptionList, levelNo: number) {
        node.children.forEach(opt => {
            const thisOpt = { ...opt };
            thisOpt.children = [];
            thisOpt.optionColour = levelNo;
            this.optionListOneLevel.push(thisOpt);

            this.getOptionListOneLevelChildren(opt, levelNo + 1);
        });
    }

    getOption(id) {
        return this._http.get<IOptionList>(this.baseURL + id, this.httpService.getHttpOptions());
    }

    addOption(option: any) {
        return this._http.post<IOptionList>(this.baseURL, JSON.stringify(option), this.httpService.getHttpOptions());
    }

    copyOptionChildren(previousId: number, newId: number) {
        const url = this.baseURL + '/copy-children/' + previousId + '/to-new/' + newId;
        return this._http.post(url, JSON.stringify({}), this.httpService.getHttpOptions());
        // http://localhost:56340/api/v1/option-lists/copy-children/12344/to-new/17896
    }

    updateOption(option: any) {
        const url = this.baseURL + option.id;
        return this._http.patch(url, option, this.httpService.getHttpOptions());
    }

    addOptionsFromRecipes(optionId: number, recipeIds: number[]) {
        const url = this.baseURL + optionId + '/create-children';
        return this._http.post<IOptionList>(url, JSON.stringify({ recipeIds: recipeIds }), this.httpService.getHttpOptions());
    }

    moveOption(id: number, parent: number, index: number) {
        let url = '';
        if (parent) {
            url = this.baseURL + 'move/' + id + '?optionListIdAbove=' + parent + '&orderNo=' + index;
        } else {
            url = this.baseURL + 'move/' + id + '?&orderNo=' + index;
        }
        const params = {
            optionListIdAbove: parent,
            orderNo: index
        };
        return this._http.patch(url, params, this.httpService.getHttpOptions());
    }

    deleteOption(optionId: number, deleteChildren: boolean) {
        const url = this.baseURL + optionId + '?deleteChildren=' + deleteChildren;
        return this._http.delete(url, this.httpService.getHttpOptions());
    }

    ///////////////////////////////////////////    BULK PRICING UPDATES    /////////////////////////////////////////////////

    getPriceTemplate(houseTypeMode: string, houseTypeId: string): Observable<LoadTemplateResponse> {
        let url = environment.apiUrl + '/option-list-load/excel-template';
        if (houseTypeMode && parseInt(houseTypeMode, 10) === GetBulkTemplateMode['Get House Opts only']) {
            url += '?getHouseOptions=true';
        } else if (houseTypeMode && parseInt(houseTypeMode, 10) === GetBulkTemplateMode['Get Standard Opts only']) {
            url += '?getHouseOptions=false';
        }
        if (houseTypeId && !(parseInt(houseTypeMode, 10) === GetBulkTemplateMode['Get Standard and House Opts'])) {
            url += '&houseTypeId=' + houseTypeId;
        } else if (houseTypeId && parseInt(houseTypeMode, 10) === GetBulkTemplateMode['Get Standard and House Opts']) {
            url += '?houseTypeId=' + houseTypeId;
        }
        return this._http.get<LoadTemplateResponse>(url, this.httpService.getHttpOptions());
    }

    postUploadtemplate(file, updateDesc = false, testMode = false) {
        let url = environment.apiUrl + '/option-list-load/excel';
        if (updateDesc) {
            url += '?updateDescription=true';
        } else {
            url += '?updateDescription=false';
        }
        if (testMode) {
            url += '&inTestMode=true';
        }
        return this._http.post<BulkPriceUploadResponse>(url, file, this.httpService.getHttpFileOptions());
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

}

/*
    processPrices(date: string) {
        const enc = encodeURIComponent(date);
        const url = this.globalService.getApiUrl() + '/option-list-load/process?effectiveDate=' + enc;
        return this._http.post(url, {}, this.globalService.getHttpOptions())
               .pipe(catchError(this.handleError));
    }
*/
