import { Injectable } from '@angular/core';
import { CategoryApiService } from './categories-api.service';
import { UtilsService } from '../../../services/utils.service';
import CustomStore from 'devextreme/data/custom_store';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private categoriesApi: CategoryApiService, private utils: UtilsService) { }

  getCategoriesGridDatasource(): CustomStore {
    return new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.categoriesApi.getCategories().subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.categoriesApi.addCategory(values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.categoriesApi.editCategory({...values, ...{id: key}}).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      // remove: async (key) => {
      //   return new Promise((resolve, reject) =>
      //     this.houseTypeApiService.deleteHouseType(encodeURIComponent(key)).subscribe(res => {
      //       return resolve(res);
      //     }, err => {
      //       return reject(err) //this.globalService.returnError(err));
      //     }));
      // }
    });
  }

}
