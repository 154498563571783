import { NotificationService } from './../../services/notification.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Recipe } from '../../dtos/recipe';
import { Subscription } from 'rxjs';
import { IOptionList } from '../types/option-list';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'js-recipe-search',
  templateUrl: './recipe-search.component.html',
  styleUrls: ['./recipe-search.component.scss']
})
export class RecipeSearchComponent implements OnInit, OnDestroy {
  @Input() option: IOptionList;
  @Input() selectMultiple: boolean;

  subscriptions: Subscription[] = [];
  allRecipes: Recipe[] = [];
  selectedRecipes: Recipe[] = [];
  selectedRowKeys: number[] = [];
  gridMaxHeight: number;
  gridString: any;
  selectionMode: string;
  selectedRecipeIds: number[] = [];

  @HostListener('window:resize') onResize() {
    this.setWidths();
  }

  constructor(
    private activeModal: NgbActiveModal,
    private estimatingService: EstimatingService,
    private notiService: NotificationService) {
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  ngOnInit() {
    this.selectionMode = this.selectMultiple ? 'multiple' : 'single';

    this.setWidths();

    this.getRecipeGroups();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setWidths() {
    this.gridMaxHeight = window.innerHeight - 400;
  }

  close() {
    this.activeModal.dismiss();
  }

  select() {
    if (this.selectMultiple) {
      this.activeModal.close(this.selectedRecipeIds);
    } else {
      const selectedRecipe = this.allRecipes.find(i => i.id === this.selectedRowKeys[0]);
      this.activeModal.close(selectedRecipe);
    }
  }

  getRecipeGroups() {
    this.subscriptions.push(
      this.estimatingService.getRecipeGroups().subscribe(recipes => {
        this.getRecipeRates();
      }, err => {
        this.notiService.notify(err);
      })
    );
  }

  getRecipeRates() {
    this.allRecipes.forEach(recipe => {
      const costingDate = new Date();
      const currentCostingDateString = costingDate.getFullYear() + '-'
        + ('0' + (costingDate.getMonth() + 1)).toString().slice(-2) + '-'
        + ('0' + costingDate.getDate()).slice(-2);

      const recipeSelling = this.estimatingService.recipeSellingCache
        .find(i => i.recipeId === recipe.id
          && i.districtId === recipe.districtId
          && i.effectiveDate.toString().slice(0, 10) <= currentCostingDateString);

      if (recipeSelling) {
        recipe.sellingPrice = recipeSelling.rate;
        recipe.sellingPriceLastUpdated = recipeSelling.effectiveDate;
      } else {
        recipe.sellingPrice = null;
        recipe.sellingPriceLastUpdated = null;
      }
    });

    this.allRecipes = this.allRecipes.concat(this.estimatingService.recipeGroups);
    this.allRecipes = this.allRecipes.concat(this.estimatingService.recipes);
  }

  onSelectionChanged(e: any) {
    if (this.selectMultiple) {
      this.selectedRecipes = e.component.getSelectedRowsData('leavesOnly')

      if (this.selectedRecipes && this.selectedRecipes.length) {
        this.selectedRecipeIds = this.selectedRecipes.map(i => i.id);
      } else {
        this.selectedRecipeIds = [];
      }
    }
  }
}
