import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { InformationTypeService } from './information-type.service';


@Component({
  selector: 'js-information-types',
  templateUrl: './information-types.component.html',
  styleUrls: ['./information-types.component.scss']
})
export class InformationTypesComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: DataSource;
  loading = false;
  gridHeight: number;

  constructor(
    private informationTypeService: InformationTypeService,
    private globalService: GlobalService,
    public gridService: GridService
  ) {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.gridHeight = window.innerHeight - 110;
      })
    );
  }

  ngOnInit(): void {
    this.gridHeight = window.innerHeight - 110;
    this.setUpDataSource();
  }

  refresh() {
    this.informationTypeService.cache = null;
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.informationTypeService.getJobDocumentTypes().subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.informationTypeService.addJobDocumentType(values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.informationTypeService.updateJobDocumentType(encodeURIComponent(key), values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.informationTypeService.deleteJobDocumentType(encodeURIComponent(key)).subscribe(res => {
            return resolve();
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      }
    });
  }

  ngOnDestroy() {
    this.informationTypeService.cache = null;
  }

}
