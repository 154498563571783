import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Subscription } from 'rxjs';

import { NotificationService } from '../../../services/notification.service';
import { CategoryApiService } from '../services/categories-api.service';
import { IOptionList } from '../../types/option-list';
import { OptionListCategory } from '../../types/option-list-category';
import { Category } from '../../../dtos/category';


@Component({
  selector: 'js-category-modal',
  templateUrl: './option-category-modal.component.html',
  styleUrls: ['./option-category-modal.component.scss']
})
export class OptionCategoryModalComponent implements OnInit, OnDestroy {
  @Input() option: IOptionList;

  subscriptions: Subscription[] = [];
  categories: Category[];
  pendingCategories: number[] = [];
  optionCategories: OptionListCategory[];
  timeout: any = null;
  query = '';
  debounceDelay = 300;
  loading = true;

  constructor(public catService: CategoryApiService,
    private _activeModal: NgbActiveModal, private cd: ChangeDetectorRef,
    private notiService: NotificationService) { }

  ngOnInit() {
    this.getCategories();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getCategories() {
    this.subscriptions = this.subscriptions.concat(
      this.catService.getCategoriesData(this.option.id)
        .subscribe(
          optionCategories => {
            this.optionCategories = optionCategories;
            this.categories = this.catService.categories;
            this.loading = false;
            this.setCategoriesCheckedField();
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );


    // const observableBatch = [];
    // observableBatch.push(this.catService.getOptionListCategories(this.option.id));
    // observableBatch.push(this.catService.getActiveCategories());
    // forkJoin(observableBatch)
    //   .subscribe(
    //     ([optionCategories, categories]) => {
    //       this.optionCategories = optionCategories as OptionListCategory[];
    //       this.categories = categories as Category[];
    //       this.loading = false;
    //       this.setCategoriesCheckedField();
    //     },
    //     err => {
    //       this.notiService.notify(err);
    //       this.cancel();
    //     }
    //   );
  }

  setCategoriesCheckedField() {
    // we populate the checked flag for all categories already added to this option
    this.categories.forEach(category => {
      category.checked = false;
      this.optionCategories.forEach(optionCat => {
        if (category.id === optionCat.category.id) {
          category.checked = true;
          category.optionCategoryId = optionCat.id;
        }
      });
    });
    this.loading = false;
  }

  checkCategory(category: Category) {
    if (this.alreadyWaitingOnCategory(category.id)) {
      return;
    }
    this.pendingCategories.push(category.id);

    if (category.checked) {
      category.checked = false;
      // delete the record
      this.catService.deleteOptionListCategory(category.optionCategoryId).subscribe(
        res => {
          this.removePending(category.id);
          this.notiService.showSuccess('Category Removed');
        },
        err => {
          category.checked = true;
          this.removePending(category.id);
          this.notiService.notify(err);
        }
      );
    } else {
      category.checked = true;
      // add the record
      this.catService.addOptionListCategory(this.option.id, category.id).subscribe(
        res => {
          const newOptionListCategoryId = res as number;
          // set the option-list-cat id for the category
          this.categories.forEach(cat => {
            if (cat.id === category.id) {
              cat.optionCategoryId = newOptionListCategoryId;
            }
          });
          this.removePending(category.id);
          this.notiService.showSuccess('Category Added');
        },
        err => {
          category.checked = false;
          this.removePending(category.id);
          this.notiService.notify(err);
        }
      );
    }
  }

  amIDisabled(category: Category) {
    for (const catId of this.pendingCategories) {
      if (category.id === catId) {
        return true;
      } else {
        return false;
      }
    }
  }

  disableCheckboxStyling(category: Category) {
    if (this.alreadyWaitingOnCategory(category.id)) {
      return true;
    } else {
      return false;
    }
  }

  removePending(id: number) {
    this.pendingCategories = this.pendingCategories.filter(catId => {
      if (catId === id) {
        return false;
      } else {
        return true;
      }
    });
  }

  alreadyWaitingOnCategory(id: number) {
    for (const pendId of this.pendingCategories) {
      if (pendId === id) {
        return true;
      }
    }
    return false;
  }

  filterCats = (cat: Category) => {
    if (this.query === '') {
      return true;
    }
    return (cat.description.toLowerCase().indexOf(this.query) > -1);
  }

  cancelTimeout(): void {
    clearTimeout(this.timeout);
    this.timeout = undefined;
  }

  cancel() {
    this._activeModal.close();
  }
}
