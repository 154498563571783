export default {
    Options: {
        NoProvisionalSumWithQty:
            '<p>Provisional Sum is not available as the parent of this Option has' +
            ' \'Quantity Required on Child Options\' selected.</p>' +
            '<p>This means that all children are Options that require quantities, where Provisional Sums do not apply.</p>'
    },
    Notifications: {
        genericConfig: 'On this page you can configure the \'From\' email and display name that is used for all notification emails.' +
            '<br><br>Valid entries are required here before Variation and Client emails can be configured.' +
            '<br><br>If no logo is provided here, the company logo configured in the Administration Portal is used.',

        variationConfig: 'On this page you can configure the notification email that is sent based on variation events.' +
            '<br><br>Use the below \'variables\' to utilise event data in your custom messages:<br><br>' +
            '<b>[VariationNumber]<br>[VariationTitle]<br>[JobNumber]<br>[SiteAddress]<br>[StatusDescription]<br>[Contract Name]</b>' +
            '<br><br>The variables will be highlighted if successfully entered.<br><br>' +
            'The default message text has its own styling. HTML can be used to style custom message text. <br><br>' +
            'Use the <b>Test Email</b> button (available once a valid \'From\' details have been configured)' +
            ' to check the appearance of the notifiction email. <br><br> The test email will be sent to the address of the logged-in user.',

        clientConfig: 'On this page you can configure the notification email that is sent to clients based on job updates.' +
            '<br><br>Use the below \'variables\' to utilise event data in your custom messages:<br><br>' +
            '<b>[JobNumber]<br>[SiteAddress]</b>' +
            '<br><br>The variables will be highlighted if successfully entered.<br><br>' +
            'The default message text has its own styling. HTML can be used to style custom message text. <br><br>' +
            'Use the <b>Test Email</b> button (available once a valid \'From\' details have been configured)' +
            ' to check the appearance of the notifiction email. <br><br> The test email will be sent to the address of the logged-in user.'

    }
};
