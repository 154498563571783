import { Injectable } from '@angular/core';
import { TreeModel, TreeNode, TreeComponent } from '@circlon/angular-tree-component';

@Injectable({
    providedIn: 'root',
})
export class TreeService {

    constructor() { }

    /* No longer manually searching ng-tree */

    // setPathString(tree: TreeComponent, textField = 'description') {
    //     // set up nodes for search
    //     tree.treeModel.doForAll((node: TreeNode) => {
    //         node.data['path_string'] = this.pathString(node, node.data[textField] + ', ');
    //     });
    // }

    // parentStringSearch(needle: string, node: TreeNode) {
    //     if (node.data.path_string.toLowerCase().indexOf(needle.toLowerCase()) >= 0) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    // }

    // expandExactMatches(value: string, treeModel: TreeModel, textField = 'description') {
    // treeModel.collapseAll();
    // treeModel.doForAll(function (node: TreeNode) {
    //     if (node.data[textField].toLowerCase().indexOf(value.toLowerCase()) >= 0) {
    //     node.ensureVisible();
    //     const siblings = node.parent.children;
    //     siblings.forEach(sibling => {
    //         if (sibling.isLeaf && sibling.data[textField].toLowerCase().indexOf(value.toLowerCase()) < 0) {
    //             sibling.hide();
    //         }
    //     });
    //     }
    // });
    // }

    // pathString(node: TreeNode, parentString: string, textField = 'description') {
    // if (!node || !node.parent || node.parent.data[textField] === undefined) {
    //     return parentString.slice(0, -2);
    // } else {
    //     return this.pathString(node.parent, parentString + node.parent.data[textField] + ', ', textField);
    // }
    // }

}
