import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { EmailNotificationSetting } from '../../dtos/notifications';
import { HttpService } from '../../../app/services/http.service';


@Injectable({
    providedIn: 'root'
})
export class EmailNotificationsService {

    baseURL = environment.apiUrl + '/variation-status-email/users';

    constructor(
        private http: HttpClient,
        private httpService: HttpService
    ) {}

    getNotiSettings(): Observable<EmailNotificationSetting[]> {
        return this.http.get<EmailNotificationSetting[]>(this.baseURL, this.httpService.getHttpOptions());
    }

    postNotiSetting(setting: EmailNotificationSetting) {
        return this.http.post<EmailNotificationSetting>(this.baseURL, setting, this.httpService.getHttpOptions());
    }

    deleteNotiSetting(settingId: number) {
        const url = environment.apiUrl + '/variation-status-email-users/' + settingId;
        return this.http.delete(url, this.httpService.getHttpOptions());
    }

    testVariationEmail() {
        const fakeVarId = 123;
        const url = environment + '/emails/job-variations/' + fakeVarId + '?sendTest=true';
        return this.http.post(url, null, this.httpService.getHttpOptions());
    }

}
