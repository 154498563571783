import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { GridService } from '../../../services/grid.service';
import { UtilsService } from '../../../services/utils.service';
import { BoardMasterService } from '../services/board-master.service';

@Component({
  selector: 'js-board-types',
  templateUrl: './board-types.component.html',
  styleUrls: ['./board-types.component.scss']
})
export class BoardTypesComponent implements OnInit {

  dataSource: DataSource;

  constructor(
    public gridService: GridService,
    private boardService: BoardMasterService,
    private utils: UtilsService,
    private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.getDataSource();
  }

  getDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.boardService.getBoardTypes().subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.boardService.addBoardType(values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.boardService.updateBoardType(key, values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          // this.boardService.deleteBoardType(encodeURIComponent(key)).subscribe(res => {
          this.boardService.deleteBoardType(key).subscribe(res => {
            return resolve(null);
          }, err => {
            return reject(this.utils.getErrorString(err));
          }));
      }
    });
  }

  close() {
    this.activeModal.close();
  }
}
