import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ClientWelcomeAttachment } from '../../dtos/client-welcome-attachment';
import { LogService } from '../../services/log.service';
import { NotificationService } from '../../services/notification.service';
import { ClientWelcomeService } from './client-welcome.service';

@Component({
  selector: 'js-client-welcome',
  templateUrl: './client-welcome.component.html',
  styleUrls: ['./client-welcome.component.scss']
})
export class ClientWelcomeComponent implements OnInit {

  @ViewChild('ngxImgScroll', { static: true }) ngxImgScroll: ElementRef;

  submitLoading = false;
  loading = true;
  attachment: ClientWelcomeAttachment;
  fileToUpload;
  imageChangedEvent: any;

  constructor(
    public clientWelcomeService: ClientWelcomeService,
    private notiService: NotificationService,
    private logger: LogService
  ) { }

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.clientWelcomeService.fetchClientWelcome()
      .subscribe(([clientWelcome, image]) => {
        this.loading = false;
        this.submitLoading = false;
        this.attachment = image;
      }, err => {
        this.loading = false;
        if (err.status !== 404) {
          this.notiService.showError('There was an error retrieving the Client Welcome record. Please try again in a moment');
          this.logger.log('option-header-service', 'fetchHeader()', err);
        }
      });
  }

  submit() {
    this.submitLoading = true;
    this.clientWelcomeService.updateClientWelcome(this.fileToUpload)
      .subscribe(imageAttached => {
        if (imageAttached) {
          this.fileToUpload = null;
          this.fetch();
        } else {
          this.submitLoading = false;
        }
        this.notiService.showSuccess('Client Welcome Config updated');
      }, err => {
        this.submitLoading = false;
        this.notiService.showError('There was an issue updating the Client Welcome Config. Please try again in a moment.');
        this.logger.log('client-welcome', 'updateClientHeader()', err);
      });
  }

  // handleFileInput(event) {
  //   this.fileToUpload = event;
  // }

  fileChangeEvent(event: any): void {
    this.fileToUpload = event;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.fileToUpload = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  deleteImageSelected() {
    this.submitLoading = true;
    this.clientWelcomeService.deleteCurrentWelcomeAttachment()
      .subscribe(res => {
        this.attachment = null;
        this.submitLoading = false;
        this.notiService.showSuccess('Image deleted');
      }, err => {
        this.submitLoading = false;
        this.notiService.showError('There was an error deleting the image. Please try again in a moment.');
      });
  }

}
