import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild, OnChanges } from '@angular/core';
import { DxHtmlEditorComponent } from 'devextreme-angular';
import { OptionHeaderFormService } from '../../option-lists/option-header-form/option-header-form.service';
import { OPTION_AND_WELCOME_HEADER_MAX_HTML_LEN } from '../../../config/global-config';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss']
})
export class HtmlEditorComponent implements OnDestroy, OnChanges {

  @ViewChild('editor')
  editor: DxHtmlEditorComponent;

  htmlPositionMode = 'above';
  tooLongErr = false;
  hide = true;
  subscriptions: Subscription[] = [];

  @Input()  aboveHTML: string;
  @Input()  besideHTML: string;
  @Input()  belowHTML: string;

  @Output()
  aboveHTMLChange = new EventEmitter<string>();
  @Output()
  besideHTMLChange = new EventEmitter<string>();
  @Output()
  belowHTMLChange = new EventEmitter<string>();

  @Output()
  invalidState = new EventEmitter<boolean>();

  constructor(
    public headerFormService: OptionHeaderFormService
  ) {
    if (!this.headerFormService.inOptionsModal) {
      this.setValue();
    }
    this.subscriptions.push(
      this.headerFormService.htmlSectionExpanded.subscribe(open => {
        if (open) {
          this.setValue();
        }
      })
    );
  }

  setValue() {
    this.hide = false;
    setTimeout(() => {
      this.editor.instance.option('value', this.aboveHTML);
    }, 10);
  }

  ngOnChanges(x) {
    if (this.editor) {
      this.positionChanged(this.htmlPositionMode);
    }
  }

  positionChanged(pos: string) {
    this.htmlPositionMode = pos;

    switch (this.htmlPositionMode) {
      case 'above':
        this.editor.instance.option('value', this.aboveHTML);
        break;
      case 'beside':
        this.editor.instance.option('value', this.besideHTML);
        break;
      case 'below':
        this.editor.instance.option('value', this.belowHTML);
        break;
    }
  }

  editorChanged(e) {
    const length = e.value ? e.value.length : 0;
    if (length > OPTION_AND_WELCOME_HEADER_MAX_HTML_LEN) {
      this.tooLongErr = true;
    } else if (this.lengthsOkay()) {
      this.tooLongErr = false;
    }
  }

  lengthsOkay() {
    if (this.aboveHTML.length <= OPTION_AND_WELCOME_HEADER_MAX_HTML_LEN
      && this.besideHTML.length <= OPTION_AND_WELCOME_HEADER_MAX_HTML_LEN
      && this.belowHTML.length <= OPTION_AND_WELCOME_HEADER_MAX_HTML_LEN) {
      this.invalidState.emit(false);
      return true;
    }
    this.invalidState.emit(true);
    return false;
  }

  editorFocusOut(e) {
    const text: string = this.editor.instance.option('value');

    switch (this.htmlPositionMode) {
      case 'above':
        this.aboveHTML = text;
        this.aboveHTMLChange.emit(text);
        break;
      case 'beside':
        this.besideHTML = text;
        this.besideHTMLChange.emit(text);
        break;
      case 'below':
        this.belowHTML = text;
        this.belowHTMLChange.emit(text);
        break;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
