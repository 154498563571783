import { GlobalService } from './../global.service';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, forkJoin, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { Recipe } from '../../dtos/recipe';
import { RecipeSelling } from '../../dtos/recipeSelling';
import { RecipeTypeEnum } from '../../dtos/recipe-type.enum';


@Injectable({
    providedIn: 'root'
})
export class EstimatingService {

    baseURL = environment.apiUrl + '/recipes?includeRates=false&recipeTypeId=2&includeInactive=false';
    recipes: Recipe[];
    recipesCompany: number;
    recipeSellingCache: RecipeSelling[];
    recipeSellingsCompany: number;
    recipeGroups: Recipe[];
    recipeGroupsCompany: number;

    constructor(
        private _http: HttpClient,
        private httpService: HttpService,
        private globalService: GlobalService
    ) {
        this.httpService.clearCaches.subscribe(c => {
            this.recipes = null;
        });
    }

    getRecipes(useCache: boolean): Observable<Recipe[]> {
        if (useCache && this.recipes && this.recipes.length
            && this.recipesCompany === this.globalService.getCurrentCompany().id) {
            return of(this.recipes);
        } else {
            return this._http.get<Recipe[]>(this.baseURL, this.httpService.getHttpOptions()).pipe(
                tap(recipes => {
                    recipes = recipes.filter(r => r.isActive);
                    this.recipes = recipes;
                    this.recipesCompany = this.globalService.getCurrentCompany().id;
                }),
                catchError(this.handleError));
        }
    }

    getRecipeGroups(): Observable<Recipe[]> {
      if (this.recipeGroups && this.recipeGroups.length && this.recipeGroupsCompany === this.globalService.getCurrentCompany().id) {
          return of(this.recipeGroups);
      } else {
        let url = environment.apiUrl + '/recipes?includeRates=false&includeInactive=true';
        url += '&recipeTypeId=' + RecipeTypeEnum.Group;
  
        return this._http.get<Recipe[]>(url, this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.recipeGroups = res;
            this.recipeGroupsCompany = this.globalService.getCurrentCompany().id;
            this.recipeGroups.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0));
          }),
          catchError(this.handleError));
      }
    }

    getRecipeSellingRates(useCache: boolean): Observable<RecipeSelling[]> {
        if (useCache && this.recipeSellingCache && this.recipeSellingCache.length
            && this.recipeSellingsCompany === this.globalService.getCurrentCompany().id) {
            return of(this.recipeSellingCache);
        } else {
            const url = environment.apiUrl + '/recipes/all-selling-rates';
            return this._http.get<RecipeSelling[]>(url, this.httpService.getHttpOptions()).pipe(
                tap(recipeSellings => {
                    this.recipeSellingCache = recipeSellings;
                    this.recipeSellingsCompany = this.globalService.getCurrentCompany().id;

                    // sort so we can get the first one matching the effective date
                    this.recipeSellingCache
                        .sort(this.globalService.sortBy('recipeId', this.globalService.sortBy('effectiveDate', false, false)));
                }),
                catchError(this.handleError));
        }
    }

    getRecipeWithSellingRates(useCache: boolean): Observable<Recipe[]> {
        return forkJoin(
            [
                this.getRecipes(useCache),
                this.getRecipeSellingRates(useCache)
            ]
        )
            .pipe(map(
                ([recipes, data]) => {
                    return recipes;
                }, (err) => {
                    return this.globalService.returnError(err);
                }
            ));
    }

    private handleError(err: HttpErrorResponse) {
        console.log(JSON.stringify(err));
        return observableThrowError(err);
    }
}
