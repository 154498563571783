export enum ModalMode {
    add = 0,
    edit = 1
}

export class ApplicationVersion {
    applicationId: number;
    version: string;
    releaseDate: Date;
}

