import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'js-confirmation',
    template: `
    <div class="modal-header">
    </div>

    <div class="modal-body" [innerHTML]="confirmationText">
    </div>

    <div class="modal-footer">
        <button (click)="cancel()" class="btn btn-secondary">Cancel</button>
        <button (click)="confirm()" class="btn btn-primary">Confirm</button>
    </div>
    `,
    styleUrls: []
})
export class ConfirmationModalComponent implements OnInit {

    @Input() confirmationText = 'Are you sure?';

    constructor(private activeModal: NgbActiveModal) {}

    ngOnInit() {

    }

    confirm() {
        this.activeModal.close(true);
    }

    cancel() {
        this.activeModal.close(false);
    }

}
