import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CompanyAttachmentSetting } from '../../dtos/company-attachment-setting';
import { HttpService } from '../../services/http.service';
import { UtilsService } from '../../services/utils.service';

export const START_ATTACHMENT_NAME = 'Prefix Attachments';
export const END_ATTACHMENT_NAME = 'Postfix Attachments';
export const START_ATTACHMENT_NAME_SINGULAR = 'Prefix Attachment';
export const END_ATTACHMENT_NAME_SINGULAR = 'Postfix Attachment';
export const SEPARATE_ATTACHMENT_NAME = 'Separate Attachments';
export const SEPARATE_ATTACHMENT_NAME_SINGULAR = 'Separate Attachment';


@Injectable({
  providedIn: 'root'
})
export class AttachmentSettingAPIService {

  attachModes = [START_ATTACHMENT_NAME, END_ATTACHMENT_NAME, SEPARATE_ATTACHMENT_NAME];
  attachModesSingular = [START_ATTACHMENT_NAME_SINGULAR, END_ATTACHMENT_NAME_SINGULAR, SEPARATE_ATTACHMENT_NAME_SINGULAR];

  settingBaseUrl = environment.apiUrl + '/company-attachment-settings/';
  settingDocBaseUrl = environment.apiUrl + '/company-attachment-settings-document/';

  cache: CompanyAttachmentSetting[];

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private utilsService: UtilsService
  ) { }

  getAttachmentSettings() {
    if (this.cache) {
      return of(this.cache);
    } else {
      return this.http.get<CompanyAttachmentSetting[]>(this.settingBaseUrl, this.httpService.getHttpOptions())
      .pipe(map(res => {
          // res.sort((a, b) => a.orderNo < b.orderNo ? -1 : a.orderNo > b.orderNo ? 1 : 0);
          this.cache = res;
          return res;
        })
      );
    }
  }

  getAttachmentSettingFile(settingId: string) {
    // have to pass id as both url and query
    const url = this.settingBaseUrl + settingId + '/attachment?companySettingsId=' + settingId;
    return this.http.get<CompanyAttachmentSetting>(url, this.httpService.getHttpOptions());
  }

  /* Initialise setting object with the attachment (file) */
  postCompanyAttachmentSetting(file: any) {
    return this.http.post<CompanyAttachmentSetting>(this.settingBaseUrl, file, this.httpService.getHttpFileOptions());
  }

  /* Once initialised, need to immediately patch the metadata */
  patchCompanySetting(settingId, setting: Partial<CompanyAttachmentSetting>) {
    return this.http.patch<CompanyAttachmentSetting>(this.settingBaseUrl + settingId, setting, this.httpService.getHttpOptions())
    .pipe(
      tap(res => {
          this.utilsService.editCache(res, this.cache);
      })
    );
  }

  patchCompanySettingDoc(settingId: string, file: any) {
    return this.http.patch<CompanyAttachmentSetting>(this.settingDocBaseUrl + settingId, file, this.httpService.getHttpFileOptions());
  }

  deleteCompanyAttachmentSetting(settingId: string) {
    return this.http.delete<CompanyAttachmentSetting>(this.settingBaseUrl + settingId, this.httpService.getHttpOptions())
    .pipe(
      tap(res => {
          this.utilsService.deleteFromCache(settingId, this.cache);
      })
    );
  }

  addToCache(setting: CompanyAttachmentSetting) {
    this.cache.push(setting);
  }

}
