import { User } from './user';

export class CompanyRole {
    public id: number;
    public roleId: number;
    public companyRoleDescription: string;
    public roleDescription: string;
    public isActive: boolean;

    constructor(desc: string, roleId: number) {
        this.companyRoleDescription = desc;
        this.id = null;
        this.roleId = roleId;
        this.roleDescription = 'DUMMY';
        this.isActive = false;
    }
}

export class CompanyRoleUser {
    public id: number;
    public companyRoleId: number;
    public userId: number;
    public companyRoleDto: CompanyRole;
    public isActive: boolean;
    public user: User;

    public checked: boolean; // for modal
}
