import { NotificationService } from './../../../services/notification.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TrackingColourEnum } from '../../../dtos/tracking-colour.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-colour-job',
  templateUrl: './colour-job.component.html',
  styleUrls: ['./colour-job.component.scss']
})
export class ColourJobComponent implements OnInit {
  trackingColourEnum = TrackingColourEnum;
  subscriptions: Subscription[] = [];
  updating = false;

  constructor(
    private _activeModal: NgbActiveModal,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
  }

  cancel() {
    this._activeModal.dismiss();
  }

  markColour(colourId: number) {
    this._activeModal.close(colourId);
  }

}
