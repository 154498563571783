import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { TreeComponent, TREE_ACTIONS, TreeNode } from '@circlon/angular-tree-component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { OptionColourEnum } from '../types/option-list';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ILibraryImg } from '../types/option-image';
import { TreeService } from '../../services/tree.service';
import { ImageLibraryService } from '../../services/felixApi/image-library.service';

@Component({
  selector: 'js-option-image-modal',
  templateUrl: './option-image-modal.component.html',
  styleUrls: ['./option-image-modal.component.scss']
})
export class OptionImageModalComponent implements OnInit {

  @ViewChild('tree') tree: TreeComponent;

  treeOptionNodes: ILibraryImg[] = [];
  treeOptionOptions = {};
  optionColourEnum = OptionColourEnum;
  filterText: string;
  searchDebounceSubject: Subject<string> = new Subject();
  searchDebounceTimeMillis = 500;
  loading = true;

  constructor(
    private treeService: TreeService,
    private cd: ChangeDetectorRef,
    private imgLibService: ImageLibraryService,
    private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.setupOptionNodes();
    this.imgLibService.getOptionListLibrary().subscribe(
      (library) => {
        this.treeOptionNodes = library;
        this.loading = false;
        this.cd.detectChanges();
        // this.treeService.setPathString(this.tree, 'label');
      });
    this.searchDebounceSubject.pipe(
      debounceTime(this.searchDebounceTimeMillis)
    ).subscribe(searchTextValue => {
      this.handleSearch(searchTextValue);
    });
  }

  setupOptionNodes() {
    this.treeOptionOptions = {
      idField: 'id',
      displayField: 'label',
      childrenField: 'children',
      // useVirtualScroll: true,
      nodeHeight: 22,
      actionMapping: {
        mouse: {
          click: (tree, node, $event) => {
            if (node.hasChildren) {
              TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
            }
          }
        }
      }
    };
  }

  imageSelected(imageId) {
    this.close(imageId);
  }

  searchKeyup(searchString: string) {
    this.searchDebounceSubject.next(searchString);
  }

  handleSearch(searchString: string) {
    if (searchString === '') {
      this.tree.treeModel.clearFilter();
      this.tree.treeModel.collapseAll();
    } else if (searchString.length > 1) {
      // this.searching = true;
      setTimeout(() => {
        this.tree.treeModel.filterNodes(searchString, true);
        // this.tree.treeModel.filterNodes((node: TreeNode) => this.treeService.parentStringSearch(searchString, node), false);
        // // for some reason this required (not with options filtering)
        // this.tree.treeModel.doForAll(n => {
        //   if (!n.isRoot) {
        //     n.show();
        //   }
        // });
        // this.treeService.expandExactMatches(searchString, this.tree.treeModel, 'label');
        // this.searching = false;
      }, 10);
    }
  }

  clearSearch($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.filterText = '';
    this.tree.treeModel.clearFilter();
    this.tree.treeModel.collapseAll();
  }

  treeNodeClicked(e) {
    if (e.isExpanded) {
      const node: TreeNode = e.node;
      node.children.forEach(c => c.show());
    }
  }

  close(imageId) {
    this.activeModal.close(imageId);
  }
}
