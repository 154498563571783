import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NotificationService } from '../../services/notification.service';
import { GlobalService } from '../../services/global.service';
import { CategoryApiService } from './services/categories-api.service';
import { Category } from '../../dtos/category';
import { CategoryModalComponent } from './category-modal/category-modal.component';
import { CategoriesService } from './services/categories.service';
import { GridService } from '../../services/grid.service';
import { DxDataGridComponent } from 'devextreme-angular';


@Component({
  selector: 'js-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, AfterViewInit, OnDestroy {

  SMALL_WIDTH_THRESHOLD = 600;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  @ViewChild('search')
  search: ElementRef;

  categories: Category[];
  dataSource;

  noTypes = false;
  typesHeight: number;
  loading = true;
  innerWidth: number;
  subscriptions: Subscription[] = [];
  query = '';
  statusFilterMode = 'all';

  constructor(
    private catService: CategoriesService,
    private catApiService: CategoryApiService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    public gridService: GridService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(width => {
        this.innerWidth = width;
      })
    );

    this.dataSource = this.catService.getCategoriesGridDatasource();
  }

  ngOnInit() {
    this.innerWidth = this.globalService.innerWidth;
    this.refreshList();
  }

  ngAfterViewInit() {
    if (this.innerWidth < this.SMALL_WIDTH_THRESHOLD) {
      setTimeout(() => {
        this.search.nativeElement.focus();
      }, 10);
    }
  }

  gridRefresh() {
    this.catApiService.clearCache();
    this.grid.instance.refresh();
  }

  refreshList() {
    this.loading = true;
    this.catApiService.clearCache();
    this.catApiService.getCategories().subscribe(cats => {
      if (cats.length === 0) {
        this.noTypes = true;
      }
      this.typesHeight = 36 * cats.length;
      this.categories = cats;
      this.loading = false;
    }, err => {
      this.notiService.notify(err);
      this.router.navigate(['optionlists']);
    });
  }

  editCat(cat: Category) {
    const modalRef = this.modalService.open(CategoryModalComponent);
    modalRef.componentInstance.mode = 'Edit';
    modalRef.componentInstance.category = cat;
    modalRef.componentInstance.refresh.subscribe(update => {
      this.refreshList();
    });

    modalRef.result.then(() => {
      //
    }, (reason) => {
    });
  }

  addCat() {
    const modalRef = this.modalService.open(CategoryModalComponent);
    modalRef.componentInstance.mode = 'Add';
    modalRef.componentInstance.refresh.subscribe(update => {
      this.refreshList();
    });

    modalRef.result.then(() => {
      //
    }, (reason) => {
    });
  }

  filterBtn(mode: string) {
    this.statusFilterMode = mode;
  }

  filterCats = (cat: Category) => {
    if (this.statusFilterMode === 'all') {
      if (this.query === '') {
        return true;
      }
      return (cat.description.toLowerCase().indexOf(this.query) > -1);
    } else {
      let activeBool: Boolean;
      this.statusFilterMode === 'active' ? activeBool = true : activeBool = false;
      if (this.query === '' && cat.isActive === activeBool) {
        return true;
      }
      return (cat.description.toLowerCase().indexOf(this.query) > -1) && cat.isActive === activeBool;
    }
  }

  back() {
    this.router.navigate(['optionlists']);
  }

  ngOnDestroy() {
    this.catApiService.clearCache();
  }
}
