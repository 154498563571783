import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UtilsService } from '../../services/utils.service';
import { CompanyActivity } from '../../dtos/company-activity';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CompanyActivityService {

  baseURL = environment.apiUrl + '/activities/';
  cache: CompanyActivity[];

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private utilsService: UtilsService
  ) { }

  getCompanyActivities(activeOnly: boolean = false) {
    if (this.cache) {
      return of(this.cache);
    } else {
      const url = this.baseURL + '?activeOnly=' + activeOnly;
      return this.http.get<CompanyActivity[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(a => this.cache = a)
      );
    }
  }

  addCompanyActivity(companyActivity: CompanyActivity) {
    return this.http.post<CompanyActivity>(this.baseURL, JSON.stringify(companyActivity), this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
            this.cache.push(res);
        })
    );
  }

  updateCompanyActivity(id: string, updatedRecord: any) {
    return this.http.patch(this.baseURL + id, JSON.stringify(updatedRecord), this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
            this.utilsService.editCache(res, this.cache);
        })
      );
  }

  deleteCompanyActivity(id: string) {
    return this.http.delete(this.baseURL + id, this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
            this.utilsService.deleteFromCache(id, this.cache);
        })
      );
  }

  runUpdateToLeadManagement(dateFrom: string, stageMustBeDifferentToActivity: boolean) {
    const baseURL = environment.apiUrl + '/lead-management/update-lead-management?dateFrom=' + dateFrom + '&stageMustBeDifferentToActivity=' + stageMustBeDifferentToActivity;
    return this.http.patch(baseURL, JSON.stringify({}), this.httpService.getHttpOptions());
  }
}
