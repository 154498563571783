import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, of, Observable } from 'rxjs';

import { HouseMaster, HouseSpecification, HouseType, HouseTypeModification, UpdateHouseType } from '../../../dtos/house-type';
import { catchError, tap } from 'rxjs/operators';
import { HttpService } from '../../../services/http.service';
import { GlobalService } from '../../../services/global.service';
import { environment } from '../../../../environments/environment';


@Injectable()
export class HouseTypeApiService {

    baseURL = environment.apiUrl + '/house-types/';
    modBaseURL = environment.apiUrl + '/house-modifications/';

    private houseTypeCache: HouseType[];
    private houseModCache: HouseTypeModification[];
    houseMasters: HouseMaster[];
    houseSpecifications: HouseSpecification[];
    houseTypeCompany: string;
    houseModCompany: string;
    houseMasterCompany: string;
    houseSpecificationCompany: string;

    constructor(
        private http: HttpClient,
        private httpService: HttpService,
        private globalService: GlobalService
    ) {
        this.globalService.companySelected.subscribe(c => {
            this.houseTypeCache = null;
        });
    }

    getHouseTypes(activeOnly = false): Observable<HouseType[]> {
        const url = activeOnly ? this.baseURL + '?activeOnly=' + activeOnly : this.baseURL;
        return this.http.get<HouseType[]>(url, this.httpService.getHttpOptions());
    }

    getHouseTypesCached(): Observable<HouseType[]> {
        if (this.houseTypeCache && this.houseTypeCompany === this.globalService.getCurrentCompanyId()) {
            return of(
                this.houseTypeCache.map(x => Object.assign({}, x)) // return copy!
            );
        } else {
            const typesObs = this.http.get<HouseType[]>(this.baseURL, this.httpService.getHttpOptions());
            typesObs.subscribe(types => {
                this.houseTypeCache = types;
                this.houseTypeCompany = this.globalService.getCurrentCompanyId();
            });
            return typesObs;
        }
    }

    postHouseType(type: UpdateHouseType): Observable<HouseType> {
        type.lastPriceUpdateDate = new Date();
        return this.http.post<HouseType>(this.baseURL, type, this.httpService.getHttpOptions())
            .pipe(
                tap(res => {
                    this.houseTypeCache.push(res);
                })
            );
    }

    patchHouseType(type: UpdateHouseType, id?): Observable<HouseType> {
        const url = this.baseURL + (id ? id : type.id);
        type.lastPriceUpdateDate = new Date();

        return this.http.patch<HouseType>(url, type, this.httpService.getHttpOptions())
            .pipe(
                tap(res => {
                    this.editTypeCache(res);
                })
            );
    }

    deleteHouseType(id: number) {
        const url = this.baseURL + id;

        return this.http.delete(url, this.httpService.getHttpOptions())
            .pipe(
                tap(() => {
                    const thisType = this.houseTypeCache.findIndex(i => i.id === id);
                    this.houseTypeCache.splice(thisType);
                })
            );
    }

    updateSellingPrices(reCostingDate: Date) {
        const effectiveDate = reCostingDate.getFullYear() + '-'
            + ('0' + (reCostingDate.getMonth() + 1)).toString().slice(-2) + '-'
            + ('0' + reCostingDate.getDate()).slice(-2);

        const url = this.baseURL + 're-price?effectiveDateString=' + effectiveDate;

        return this.http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions());
    }

    calculateActualSellingPrices(roundUpTo: number, houseTypeIds: number[], lessAmount: number) {
        const url = this.baseURL + 'calculate-actual-selling-prices?roundUpTo=' + roundUpTo;

        return this.http.patch(url,
            JSON.stringify({ roundUpTo: roundUpTo, lessAmount: lessAmount, houseTypeIds: houseTypeIds }),
            this.httpService.getHttpOptions());
    }

    getHouseTypeModsCached(activeOnly = false): Observable<HouseTypeModification[]> {
        if (this.houseModCache && this.houseModCompany === this.globalService.getCurrentCompanyId()) {
            return of(
                this.houseModCache.map(x => Object.assign({}, x)) // return copy!
            );
        } else {
            // if (activeOnly) {
            //     url += '?activeOnly=true';
            // }
            const modObs = this.http.get<HouseTypeModification[]>(this.modBaseURL, this.httpService.getHttpOptions());
            modObs.subscribe(mods => {
                this.houseModCache = mods;
                this.houseModCompany = this.globalService.getCurrentCompanyId();
            });
            return modObs;
        }
    }

    postHouseTypeMod(mod: HouseTypeModification): Observable<HouseTypeModification> {
        return this.http.post<HouseTypeModification>(this.modBaseURL, mod, this.httpService.getHttpOptions())
            .pipe(
                tap(res => {
                    this.houseModCache.push(res);
                })
            );
    }

    patchHouseTypeMod(mod: HouseTypeModification, id?): Observable<HouseTypeModification> {
        const url = this.modBaseURL + (id ? id : mod.id);

        return this.http.patch<HouseTypeModification>(url, mod, this.httpService.getHttpOptions())
            .pipe(
                tap(res => {
                    this.editModCache(res);
                })
            );
    }

    clearCaches() {
        this.houseTypeCache = null;
        this.houseModCache = null;
    }

    editTypeCache(inType: HouseType) {
        const type = this.houseTypeCache.filter(t => t.id === inType.id)[0];
        if (type) {
            const index = this.houseTypeCache.indexOf(type);
            this.houseTypeCache[index] = inType;
        } else {
            console.log('couldnt edit type cache!');
        }
    }

    editModCache(inMod: HouseTypeModification) {
        const mod = this.houseModCache.filter(t => t.id === inMod.id)[0];
        if (mod) {
            const index = this.houseModCache.indexOf(mod);
            this.houseModCache[index] = inMod;
        } else {
            console.log('couldnt edit mod cache!');
        }
    }


    // house master
    getHouseMasters(useCache: boolean) {
        if (useCache && this.houseMasters && this.houseMasterCompany === this.globalService.getCurrentCompanyId()) {
            return of(this.houseMasters);
        } else {
            return this.http.get<HouseMaster[]>(environment.apiUrl + '/house-masters/', this.httpService.getHttpOptions()).pipe(
                tap(res => {
                    this.houseMasters = res;
                    this.houseMasterCompany = this.globalService.getCurrentCompanyId();
                }),
                catchError(this.handleError));
        }
    }

    addHouseMaster(jobDocumentType: HouseMaster) {
        return this.http.post<HouseMaster>(environment.apiUrl + '/house-masters/',
            JSON.stringify(jobDocumentType), this.httpService.getHttpOptions());
    }

    updateHouseMaster(id: string, updatedRecord: any) {
        return this.http.patch<HouseMaster>(environment.apiUrl + '/house-masters/' + id,
            JSON.stringify(updatedRecord), this.httpService.getHttpOptions());
    }

    deleteHouseMaster(id: string) {
        return this.http.delete(environment.apiUrl + '/house-masters/' + id, this.httpService.getHttpOptions());
    }


    // house specifications
    getHouseSpecifications(useCache: boolean) {
        if (useCache && this.houseSpecifications && this.houseSpecificationCompany === this.globalService.getCurrentCompanyId()) {
            return of(this.houseSpecifications);
        } else {
            return this.http.get<HouseSpecification[]>(environment.apiUrl + '/house-specifications/',
                this.httpService.getHttpOptions()).pipe(
                    tap(res => {
                        this.houseSpecifications = res;
                        this.houseSpecificationCompany = this.globalService.getCurrentCompanyId();
                    }),
                    catchError(this.handleError));
        }
    }

    addHouseSpecification(jobDocumentType: HouseSpecification) {
        return this.http.post<HouseSpecification>(environment.apiUrl + '/house-specifications/',
            JSON.stringify(jobDocumentType), this.httpService.getHttpOptions());
    }

    updateHouseSpecification(id: string, updatedRecord: any) {
        return this.http.patch<HouseSpecification>(environment.apiUrl + '/house-specifications/' + id,
            JSON.stringify(updatedRecord), this.httpService.getHttpOptions());
    }

    deleteHouseSpecification(id: string) {
        return this.http.delete(environment.apiUrl + '/house-specifications/' + id, this.httpService.getHttpOptions());
    }


    private handleError(err: HttpErrorResponse) {
        console.log(JSON.stringify(err));
        return observableThrowError(err);
    }
}
