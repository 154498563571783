export enum emailUserType {
    User = 1,
    Role = 2,
    Trade = 3,
    Client = 4
}

export class EmailNotificationSetting {
    id: number;
    companyId: number;
    variationType: number;
    emailUserTypeId: number;
    userId: number;
    roleId: number;
    variationStatusId: number;

    constructor(varType: number, userType: number, userId: number, roleId: number, varStatus: number) {
        this.variationType = varType;
        this.emailUserTypeId = userType;
        this.userId = userId;
        this.roleId = roleId;
        this.variationStatusId = varStatus;
    }
}

export enum EmailControlType {
    Generic = 1,
    VariationStatus = 2,
    ClientStatus  = 3
}

export class NotificationControl {
    id?: number;
    emailControlTypeId: EmailControlType;
    companyId?: number;
    fromEmailAddress?: string;
    fromDisplayName?: string;
    logo?: string;
    subject?: string;
    message?: string;
}

export enum DefaultBtnEnum {
    default = 'Using Defaults',
    custom = 'Restore Defaults'
}

