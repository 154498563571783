import { ClaimMasterLine } from './../../../dtos/claim-master-line';
import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';

import { NotificationService } from '../../../services/notification.service';
import { ClaimsService } from '../services/claims.service';
import { ClaimApiService } from '../services/claim.api.service';
import { GridService } from '../../../services/grid.service';
import { CompanyActivityService } from '../../company-activities/company-activity.service';
import { CompanyActivity } from '../../../dtos/company-activity';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'js-claim-lines',
  templateUrl: './claim-lines.component.html',
  styleUrls: ['./claim-lines.component.scss']
})
export class ClaimLinesComponent implements OnInit, OnDestroy {
  @Input() claimMasterId: number;
  @Input() claimMasterDescription: string;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  uploadDisabled = false;
  badFileType = false;
  fileChosen = false;
  submitting = false;
  dataSource: any = {};
  loading = true;
  activities: CompanyActivity[];

  claimTriggers = [
    { id: 1, description: 'By Activity' },
    { id: 2, description: 'Manually Raised' }
  ];
  invoiceTos = [
    { id: 2, description: 'From Loan' },
    { id: 3, description: 'Direct to Client' }
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private claimsService: ClaimsService,
    private claimApiService: ClaimApiService,
    private companyActivityService: CompanyActivityService,
    private notiService: NotificationService,
    public gridService: GridService
  ) {
    this.reorder = this.reorder.bind(this);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.companyActivityService.getCompanyActivities().subscribe(activities => {
        this.activities = activities;
      })
    );

    this.dataSource = this.claimsService.getClaimMasterLineDataSource(this.claimMasterId);
    this.loading = false;
  }

  refresh() {
    this.claimApiService.clearCaches();
    this.grid.instance.refresh();
  }

  close() {
    if (this.grid.instance.hasEditData()) {
      this.notiService.showInfo('Please Save or Cancel the edited data (or saving is in progress)');
    } else {
      this.activeModal.close();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  reorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newOrderIndex = visibleRows[e.toIndex].data.orderNo;
    this.grid.instance.beginCustomLoading('Re-ordering');

    this.subscriptions.push(
      this.claimApiService.moveClaimMasterLine(e.itemData.id, newOrderIndex).subscribe(() => {
        this.grid.instance.endCustomLoading();
        e.component.refresh();
      }, () => {
        this.notiService.showInfo('Cannot drop here');
        this.grid.instance.endCustomLoading();
      })
    );
  }

  calculateInvoiceToValue(data: ClaimMasterLine) {
    return data.isInvoiceToClient ? 'Direct to Client' : 'From Loan';
  }

  setInvoiceToCellValue(rowData, value, originalData) {
    rowData.isInvoiceToClient = value === 'Direct to Client';
  }
}
