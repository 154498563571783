import { Component, OnDestroy, OnInit } from '@angular/core';
import { DivisionService } from '../../../services/felixApi/division.service';
import { Division } from '../../../dtos/division';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import DataSource from 'devextreme/data/data_source';
import { GlobalService } from '../../../services/global.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../services/felixApi/user.service';
import { User, UserTypeEnum } from '../../../dtos/user';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'js-default-role-user',
  templateUrl: './default-role-user.component.html',
  styleUrls: ['./default-role-user.component.scss']
})
export class DefaultRoleUserComponent implements OnInit, OnDestroy {
  division: Division;
  loading = true;
  gridHeight: number;
  dataSource: DataSource;
  subscriptions: Subscription[] = [];
  users: User[];

  constructor(
    private divisionService: DivisionService,
    private userService: UserService,
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
    this.division = this.divisionService.currentDivision;
    this.gridHeight =  window.innerHeight < 800 ? 400 : window.innerHeight - 400;
    this.gridHeight = this.gridHeight > 500 ? 500 : this.gridHeight;
    this.getUsers();
  }

  close() {
    this.activeModal.close();
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getUsers() {
    this.subscriptions.push(
      this.userService.getCurrCompUsers()
        .subscribe(
          data => {
            this.users = data.filter(u => u.isActive && (u.userTypeId === UserTypeEnum.Admin || u.userTypeId === UserTypeEnum.Office));
            this.loading = false;
            this.setUpDataSource();
          },
          err => {
            this.notiService.notify(err);
            this.loading = false;
          })
    );
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'roleId',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.divisionService.getDivisionRoles(this.division.id).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.divisionService.updateDivisionRole(this.division.id, encodeURIComponent(key), values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      }
    });
  }
}
