import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HouseTypeApiService } from '../services/house-type.api.service';
import { Subscription, Observable } from 'rxjs';
import { HouseTypeModification } from '../../../dtos/house-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HouseModModalComponent } from '../house-mod-modal/house-mod-modal.component';
import { ModalMode } from '../../../shared/global-models';

@Component({
  selector: 'js-house-type-mods',
  templateUrl: './house-type-mods.component.html',
  styleUrls: ['./house-type-mods.component.scss']
})
export class HouseTypeModsComponent implements OnInit, OnDestroy {

  @Input()
  query: string;
  @Input()
  filterTypeFunc: () => boolean;
  @Input()
  statusFilterMode: string;
  @Input()
  addEvent: Observable<void>;

  subscriptions: Subscription[] = [];
  mods: HouseTypeModification[];
  height: number;
  noMods = false;
  loading = true;

  constructor(private houseTypeService: HouseTypeApiService, private modalService: NgbModal) { }

  ngOnInit() {
    this.refreshList();
    this.subscriptions.push(
      this.addEvent.subscribe(e => {
        this.add();
      })
    );
  }

  refreshList() {
    this.loading = true;
    this.subscriptions.push(
      this.houseTypeService.getHouseTypeModsCached().subscribe(mods => {
        this.mods = mods;
        if (mods.length === 0) {
          this.noMods = true;
        }
        this.height = 36 * mods.length;
        this.loading = false;
      })
    );
  }

  add() {
    const modalRef = this.modalService.open(HouseModModalComponent);
    modalRef.componentInstance.mode = ModalMode.add;

    modalRef.componentInstance.refresh.subscribe(update => {
      this.refreshList();
    });

    modalRef.result.then(() => {
      //
    }, (reason) => {
    });
  }

  edit(mod: HouseTypeModification) {
    const modalRef = this.modalService.open(HouseModModalComponent);
    modalRef.componentInstance.mode = ModalMode.edit;
    modalRef.componentInstance.houseMod = mod;

    modalRef.componentInstance.refresh.subscribe(update => {
      this.refreshList();
    });

    modalRef.result.then(() => {
      //
    }, (reason) => {
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
