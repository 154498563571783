import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { NotificationControl, EmailControlType } from '../../../dtos/notifications';
import NotificationsConfig from '../../../../config/notificationConfig';

import { NotificationService } from '../../../services/notification.service';
import { TextHighlighterDirective } from '../../../shared/textHighlighter.directive';
import { FileProcessingService } from '../../../services/file-processing.service';
import { NotificationControlService } from '../../services/noti-control.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
// import { NgxImgComponent } from 'ngx-img';

export enum defaultBtnEnum {
  default = 'Using Defaults',
  custom = 'Restore Defaults'
}


@Component({
  selector: 'js-generic-email',
  templateUrl: './generic-email.component.html',
  styleUrls: ['./generic-email.component.scss']
})
export class GenericEmailComponent implements OnInit, OnDestroy {

  @Output()
  haveGenericControls = new EventEmitter<boolean>();

  @ViewChild('messageArea')
  messageArea: ElementRef;
  @ViewChild('subjectArea')
  subjectArea: ElementRef;
  @ViewChildren(TextHighlighterDirective)
  textHighlighters: TextHighlighterDirective[];
  @ViewChild('focusElement')
  focusElement: ElementRef;
  // @ViewChild('imgUpload')
  // imgUpload: NgxImgComponent;

  notificationControl: NotificationControl;
  notiControlForm: UntypedFormGroup;
  notificationsConfig = NotificationsConfig;

  defaultBtnText: string;
  messageText: string;
  subjectText: string;
  textVariableStyling = 'color:var(--bootstrap-blue);';

  submitting = false;
  sendingTest = false;
  loading = true;
  noControl = false;
  dirtyForm = false;

  subscriptions: Subscription[] = [];
  fileToUpload: string;
  existingLogo: any;
  stringLogo: string;
  defaultBtnEnum = defaultBtnEnum;
  imageChangedEvent: any;


  constructor(
    private notiControlService: NotificationControlService,
    private notiService: NotificationService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private imgService: FileProcessingService) {

    this.subscribe();
  }

  ngOnInit() {
  }

  subscribe() {
    this.loading = true;
    this.subscriptions.push(
      this.notiControlService.getGenericControl().subscribe(control => {
        this.notificationControl = control;
        this.existingLogo = control ? control.logo : null;
        this.setupForm();
        this.loading = false;
        this.haveGenericControls.emit(true);
        this.focus();
      }, err => {
        if (err.status === 422 && err.error.message.indexOf('Missing Email Control Master Record') > -1) {
          this.noControl = true;
          this.setupForm();
          // tslint:disable-next-line: forin
          for (const i in this.notiControlForm.controls) {
            this.notiControlForm.controls[i].markAsTouched();
          }
          this.loading = false;
        } else {
          this.notiService.notify(err);
          this.router.navigate(['notifications']);
        }
      })
    );
  }

  setupForm() {
    this.notiControlForm = this.formBuilder.group({
      fromDisplayName: [this.notificationControl ? this.notificationControl.fromDisplayName : null,
      Validators.required],
      fromEmailAddress: [this.notificationControl ? this.notificationControl.fromEmailAddress : null,
      [Validators.required, Validators.email]],
    });

    this.messageText = this.notificationControl && this.notificationControl.message ? this.notificationControl.message
      : this.notificationsConfig.DefaultVariationMessage;
    this.subjectText = this.notificationControl && this.notificationControl.subject ? this.notificationControl.subject
      : this.notificationsConfig.DefaultVariationSubject;

    if (!this.notificationControl ||
      (this.notificationControl && this.notificationControl.message === this.notificationsConfig.DefaultVariationMessage
        && this.notificationControl.subject) === this.notificationsConfig.DefaultVariationSubject) {
      this.defaultBtnText = defaultBtnEnum.default;
    } else {
      this.defaultBtnText = defaultBtnEnum.custom;
    }
  }

  submit() {
    this.submitting = true;
    this.notiControlForm.disable();
    const updatedControl = this.setupdatedControl();

    if (this.notificationControl) {
      this.patchControl(updatedControl);
    } else {
      this.postControl(updatedControl);
    }
  }

  setupdatedControl(): NotificationControl {
    return {
      id: this.notificationControl ? this.notificationControl.id : null,
      emailControlTypeId: EmailControlType.Generic,
      fromDisplayName: this.notiControlForm.get('fromDisplayName').value,
      fromEmailAddress: this.notiControlForm.get('fromEmailAddress').value,
    };
  }

  patchControl(updatedControl: NotificationControl) {
    this.notiControlService.patchNotiControl(updatedControl).subscribe(res => {
      if (this.fileToUpload) {
        this.saveImage(updatedControl.id);
      } else {
        this.success();
      }
    }, err => {
      this.error(err);
    });
  }

  postControl(updatedControl: NotificationControl) {
    this.notiControlService.postNotiControl(updatedControl).subscribe(res => {
      if (this.fileToUpload) {
        this.saveImage(res.id);
      } else {
        this.success();
      }
    }, err => {
      this.error(err);
    });
  }

  // handleFileInput(event) {
  //     this.existingLogo = null;
  //     this.fileToUpload = event;
  //     this.dirtyForm = true;
  // }

  fileChangeEvent(event: any): void {
    this.existingLogo = null;
    this.dirtyForm = true;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.fileToUpload = event.base64;
  }

  fileReset() {
    this.existingLogo = this.notificationControl ? this.notificationControl.logo : null;
  }

  saveImage(controlId) {
      const blobData = this.imgService.dataURItoBlob(this.fileToUpload);

      this.notiControlService.patchNotiControlLogo(this.imgService.blobToFormData(blobData, 'logo.jpg'), controlId).subscribe(control => {
        this.notificationControl = control;
        this.existingLogo = control ? control.logo : null;
        this.success();
      }, err => {
        this.notiService.showWarning('The Image was not able to be uploaded but any other changes were successful. ' +
          'Please try to add the image again in a moment.');
        this.notiControlForm.enable();
        this.submitting = false;
      });
  }

  focus() {
    setTimeout(() => {
      this.focusElement?.nativeElement?.focus();
    }, 100);
  }

  success() {
    this.noControl = false;
    this.dirtyForm = false;
    this.fileToUpload = null;
    this.notiService.showSuccess('Email settings successfully updated');
    this.submitting = false;
    // this.imgUpload.reset();
    this.haveGenericControls.emit(true);
    this.router.navigate(['notifications']);
  }

  error(err) {
    this.notiService.notify(err);
    this.notiControlForm.enable();
    this.submitting = false;
  }

  blob2file(blobData) {
    const fd = new FormData();
    fd.set(blobData.name, blobData);
    return fd.get(blobData.name);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  /*
  deleteImageSelected() {
    this.emailNotiService.deleteNotiControlLogo().subscribe( res => {
      this.existingLogo = null;
      this.notiService.showSuccess('Image removed');
    }, err => {
      this.notiService.showError('Could not remove image. Please try again later.');
    });
  }
  */

}
