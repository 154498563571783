import { GlobalService } from './../global.service';
import { EstimatingService } from './estimating.service';
import { Division } from './../../dtos/division';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { throwError as observableThrowError, forkJoin, Observable } from 'rxjs';
import { IOptionListAttachment } from '../../option-lists/types/option-list-attachment';
import { catchError, map, tap } from 'rxjs/operators';
import { TemplateTaskHeader } from '../../dtos/templateTaskHeader';
import { DivisionRole } from '../../dtos/division-role';
import { District } from '../../dtos/district';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {

  baseURL = environment.apiUrl + '/divisions/';
  baseRolesURL = environment.apiUrl + '/division-roles/';
  currentDivision: Division;
  taskHeaders: TemplateTaskHeader[];
  districts: District[];

  constructor(private http: HttpClient,
    private estimatingService: EstimatingService,
    private httpService: HttpService,
    private globalService: GlobalService
  ) { }

  getDivisions() {
    return this.http.get<Division[]>(this.baseURL, this.httpService.getHttpOptions());
  }

  getDivisionsAndRecipes(): Observable<Division[]> {
    return forkJoin(
      [
        this.getDivisions(),
        this.estimatingService.getRecipes(true),
        this.getTemplateTaskHeaders(),
        this.getDistricts()
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  addDivision(division: Division) {
    return this.http.post<Division>(this.baseURL, JSON.stringify(division), this.httpService.getHttpOptions());
  }

  updateDivision(id: string, updatedRecord: any) {
    return this.http.patch<Division>(this.baseURL + id, JSON.stringify(updatedRecord), this.httpService.getHttpOptions());
  }

  deleteDivision(id: string) {
    return this.http.delete(this.baseURL + id, this.httpService.getHttpOptions());
  }


  getDivisionLogo(id): Observable<IOptionListAttachment> {
    const url = environment.apiUrl + '/division-attachments/' + id;
    const options = this.httpService.getHttpFileOptions();
    return this.http.get<IOptionListAttachment>(url, options);
  }

  postDivisionLogo(id: number, file) {
    const url = environment.apiUrl + '/divisions/' + id + '/attachments/upload';
    return this.http.post(url, file, this.httpService.getHttpFileOptions());
  }

  deleteDivisionLogo(id) {
    const url = environment.apiUrl + '/division-attachments/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions());
  }

  moveDivision(id: number, orderNumber: number): Observable<Division> {
    const url = this.baseURL + 'move/' + id + '?orderNumber=' + orderNumber;

    return this.http.patch<Division>(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  getDivisionRoles(divisionId: number) {
    return this.http.get<DivisionRole[]>(this.baseRolesURL + '?divisionId=' + divisionId, this.httpService.getHttpOptions());
  }

  updateDivisionRole(divisionId: number, roleId: string, updatedRecord: any) {
    return this.http.patch<DivisionRole>(this.baseRolesURL + '?divisionId=' + divisionId + '&roleId=' + roleId, JSON.stringify(updatedRecord), this.httpService.getHttpOptions());
  }

  getTemplateTaskHeaders(): Observable<TemplateTaskHeader[]> {
    const url = environment.apiUrl + '/template-task-headers/';
    return this.http.get<TemplateTaskHeader[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.taskHeaders = res;
      }),
      catchError(this.handleError));
  }

  getDistricts(): Observable<District[]> {
    const url = environment.apiUrl + '/districts';

    return this.http.get<District[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.districts = res;
      }),
      catchError(this.handleError));
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
