import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { BoardItem } from '../../../dtos/boards';
import { BoardItemRow } from '../../../dtos/boards';
import { HttpService } from '../../../services/http.service';


@Injectable()
export class BoardItemService {

  baseURL = environment.apiUrl + '/boards/';
  itemsBaseURL = environment.apiUrl + '/board-items/';

  constructor(
    private _http: HttpClient,
    private httpService: HttpService
  ) { }

  getBoardItemRows(): Observable<BoardItemRow[]> {
    return this._http.get<BoardItemRow[]>(this.itemsBaseURL, this.httpService.getHttpOptions());
  }

  getBoardItem(boardId: number, optionListId: number): Observable<BoardItem> {
    const url = this.baseURL + boardId + '/items?optionListId=' + optionListId;
    return this._http.get<BoardItem>(url, this.httpService.getHttpOptions());
  }

  addBoardItem(boardId: number, itm: any): Observable<BoardItem> {
    const url = this.baseURL + boardId + '/items';
    return this._http.post<BoardItem>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateBoardItem(boardId: number, itm: any) {
    const url = this.itemsBaseURL + boardId;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteBoardItem(id: number) {
    const url = this.itemsBaseURL + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

}
