import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'js-copy-children',
  templateUrl: './copy-children.component.html',
  styleUrls: ['./copy-children.component.scss']
})
export class CopyChildrenComponent implements OnInit {

  copyChildren = false;
  constructor(private _activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  cancel() {
    this._activeModal.dismiss();
  }

  copy() {
    this._activeModal.close(this.copyChildren);
  }

}
