import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GlobalService } from '../../services/global.service';
import { BulkUpdateComponent } from '../bulk-update/bulk-update.component';
import { OptionListService } from '../services/option-list.service';

@Component({
  templateUrl: './option-list-start.component.html',
  styleUrls: ['./option-list-start.component.scss']
})
export class OptionListStartComponent implements OnInit, OnDestroy {

  @ViewChild('bulkUpdateTemplate')
  bulkUpdateTemplate: ElementRef;

  showImages = false;
  innerWidth: number;
  subscriptions: Subscription[] = [];
  modalRef;
  smallWidth;
  medWidth;

  constructor(private router: Router,
              private globalService: GlobalService,
              private optionService: OptionListService,
              private modalService: NgbModal) {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(width => {
        this.innerWidth = width;
      })
    );
   }

  ngOnInit() {
    this.innerWidth = this.globalService.innerWidth;
    this.medWidth = this.optionService.mediumHeadersThreshold;
    this.smallWidth = this.optionService.smallHeadersThreshold;
  }

  bulkUpdate() {
    this.modalRef = this.modalService.open(BulkUpdateComponent);
  }

  showImagesChange() {
    this.showImages = !this.showImages;
  }

  editCategories() {
    this.router.navigate(['categories']);
  }

  ngOnDestroy() {
    this.subscriptions.forEach( sub => {
      sub.unsubscribe();
    });
  }
}
