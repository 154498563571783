import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
// this entire service here because dx-html-editor gets confused about width
// if it is created before the expansion panel is opened in the optoins modal
export class OptionHeaderFormService {

  inOptionsModal = false;
  htmlSectionExpanded = new EventEmitter();

  constructor() { }

  openHTMLEditor() {
    this.htmlSectionExpanded.emit(true);
  }

  closeHTMLEditor() {
    this.htmlSectionExpanded.emit(false);
  }

}
