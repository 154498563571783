import { Component, OnInit, ViewChild } from '@angular/core';
import { MaintenanceHelperService } from './maintenance-helper.service';
import { GlobalService } from '../services/global.service';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'js-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  @ViewChild('panel')
  panel;

  modMode: boolean;
  constructionActivitiesUsed: boolean;
  claimScheduleEnabled: boolean;
  sections: string[];
  customerUpdateAllowed: boolean;

  constructor(
    public helper: MaintenanceHelperService,
    private globalService: GlobalService,
    private auth: AuthService
    ) {
    this.helper.houseTypeModMode.subscribe(modMode => {
      this.modMode = modMode ? true : false;
    });
    this.helper.selectedSection = 'House Types';
  }

  ngOnInit() {
    // check configs
    this.globalService.companyConfiguration.forEach(config => {
      if (config.configurationId === ConfigurationEnum.ConstructionActivitiesUsed && config.configurationValue === 1) {
        this.constructionActivitiesUsed = true;
      }
      if (config.configurationId === ConfigurationEnum.ClaimSchedule && config.configurationValue === 1) {
        this.claimScheduleEnabled = true;
      }
      if (config.configurationId === ConfigurationEnum.CustomerUpdateAllowed && config.configurationValue === 1) {
        this.customerUpdateAllowed = true;
      }
    });

    this.sections = [];
    this.helper.sections.forEach(section => {
      if (section === 'Activities') {
        if (this.constructionActivitiesUsed) {
          this.sections.push(section);
        }
      } else if (section === 'Claims') {
        if (this.claimScheduleEnabled) {
          this.sections.push(section);
        }
      } else if (section === 'Client Welcome') {
        if (this.auth.isAdminOrSuper() && this.customerUpdateAllowed) {
          this.sections.push(section);
        }
      } else {
        this.sections.push(section);
      }
    });
  }

  tabSelected(e) {
    this.helper.selectedSection = e.itemData;
  }
}
