import { Component, OnInit, Input, EventEmitter, OnDestroy } from '@angular/core';
import { ModalMode } from '../../../shared/global-models';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HouseTypeApiService } from '../services/house-type.api.service';
import { NotificationService } from '../../../services/notification.service';
import { HouseTypeModification } from '../../../dtos/house-type';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-house-mod-modal',
  templateUrl: './house-mod-modal.component.html',
  styleUrls: ['./house-mod-modal.component.scss']
})
export class HouseModModalComponent implements OnInit, OnDestroy {

  @Input() mode: ModalMode;
  @Input() houseMod: HouseTypeModification;

  refresh = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];
  header: string;
  submitText: string;
  typeForm: UntypedFormGroup;

  modalMode = ModalMode;
  addingAnother = false;
  submitting = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private houseTypeService: HouseTypeApiService,
    private notiService: NotificationService
  ) { }

  ngOnInit() {
    if (this.mode === ModalMode.add) {
      this.header = 'Add House Modification';
      this.submitText = 'Add';

      this.setupAdd();
    } else if (this.mode === ModalMode.edit) {
      this.header = 'Edit House Modification';
      this.submitText = 'Update';

      this.typeForm = this.formBuilder.group({
        description: [this.houseMod.description, Validators.required],
        isActive: this.houseMod.isActive,
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  addMod() {
    const updatedMod = {
      id: null,
      description: this.typeForm.get('description').value,
      isActive: true
    } as HouseTypeModification;

    this.houseTypeService.postHouseTypeMod(updatedMod).subscribe(res => {
      this.refresh.emit(true);
      this.notiService.showSuccess('House Mod Added');
      if (this.addingAnother) {
        this.setupAdd();
        this.submitting = false;
        this.typeForm.enable();
      } else {
        this.close();
      }
    }, err => {
      this.notiService.notify(err);
      this.submitting = false;
      this.typeForm.enable();
    }
    );
  }

  editMod() {
    const updatedMod = {
      id: this.houseMod.id,
      description: this.typeForm.get('description').value,
      isActive: this.typeForm.get('isActive').value
    } as HouseTypeModification;

    this.subscriptions.push(
      this.houseTypeService.patchHouseTypeMod(updatedMod).subscribe(res => {
        this.refresh.emit(true);
        this.notiService.showSuccess('House Mod Updated');
        if (this.addingAnother) {
          this.setupAdd();
          this.submitting = false;
          this.typeForm.enable();
        } else {
          this.close();
        }
      }, err => {
        this.notiService.notify(err);
        this.submitting = false;
        this.typeForm.enable();
      })
    );
  }

  submit() {
    this.submitting = true;
    this.typeForm.disable();
    if (this.mode === ModalMode.add) {
      this.addMod();
    } else if (this.mode === ModalMode.edit) {
      this.editMod();
    }
  }

  setupAdd() {
    this.typeForm = this.formBuilder.group({
      description: [null, Validators.required],
    });
  }

  addAnotherSelected() {
    this.addingAnother = true;
    this.addMod();
  }

  close() {
    this.activeModal.close();
  }

}
