import { AuthService } from './../../../services/auth.service';
import { NotificationService } from './../../../services/notification.service';
import { DivisionService } from './../../../services/felixApi/division.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Division } from '../../../dtos/division';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IOptionListAttachment } from '../../../option-lists/types/option-list-attachment';
import { FileProcessingService } from '../../../services/file-processing.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'js-division-logo',
  templateUrl: './division-logo.component.html',
  styleUrls: ['./division-logo.component.scss']
})
export class DivisionLogoComponent implements OnInit, OnDestroy {

  division: Division;
  loading = true;
  subscriptions: Subscription[] = [];
  imageFound = false;
  logo: IOptionListAttachment;
  isAdmin: boolean;
  fileToUpload: any;
  imageChangedEvent: any;

  constructor(
    private divisionService: DivisionService,
    private notiService: NotificationService,
    private auth: AuthService,
    public activeModal: NgbActiveModal,
    private imgService: FileProcessingService
  ) { }

  ngOnInit() {
    this.division = this.divisionService.currentDivision;
    this.isAdmin = this.auth.isAdminOrSuper();
    this.loadLogo();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadLogo() {
    this.logo = null;
    this.subscriptions.push(
      this.divisionService.getDivisionLogo(this.division.id).subscribe(attachment => {
        if (attachment) {
          this.logo = attachment;
        }
        this.loading = false;
      }, err => {
        this.notiService.notify(err);
        this.loading = false;
      })
    );
  }

  close() {
    this.activeModal.close();
  }

  fileChangeEvent(event: any): void {
    this.fileToUpload = event;
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.fileToUpload = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  fileSelected() {
    if (this.fileToUpload) {
      this.loading = true;
      this.imgService.compressImage(this.fileToUpload, 600, 400).subscribe(compImageFormData => {
        this.subscriptions.push(
          this.divisionService.postDivisionLogo(this.division.id, compImageFormData).subscribe(() => {
            this.loadLogo();
          }, err => {
            this.notiService.notify(err);
            this.loading = false;
          })
        );
      }, err => {
        this.notiService.notify(err);
        this.loading = false;
      });
    }
  }

  deleteLogo() {
    this.loading = true;
    this.subscriptions.push(
      this.divisionService.deleteDivisionLogo(this.division.id).subscribe(() => {
        this.logo = null;
        this.loading = false;
      }, err => {
        this.notiService.notify(err);
        this.loading = false;
      })
    );
  }
}
