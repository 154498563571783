import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { NotificationControlService } from '../services/noti-control.service';
import { SUBHEADER_SMALL_WIDTH } from '../../../config/global-config';


@Component({
  selector: 'js-notification-control',
  templateUrl: './notification-control.component.html',
  styleUrls: ['./notification-control.component.scss'],
  providers: [NotificationControlService]  // cache controls only for notification tab
})
export class NotificationControlComponent implements OnInit, OnDestroy  {

  subscriptions: Subscription[] = [];
  innerWidth: number;
  mode = 'generic';
  noGenericControls = true;

  cssSmallWidth = SUBHEADER_SMALL_WIDTH;

  constructor(private globalService: GlobalService, private router: Router) {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe( width => {
        this.innerWidth = width;
      })
    );
  }

  ngOnInit() {
    this.innerWidth = this.globalService.innerWidth;
  }

  configureScenarios() {
    this.router.navigate(['notifications']);
  }

  loadedGenericPage(haveGenericControls) {
    if (haveGenericControls) {
      this.noGenericControls = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

}
