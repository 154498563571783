import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { User } from '../dtos/user';
import { VariationTypeEnum } from '../dtos/variations';
import { VariationStatusEnum } from '../dtos/variations';
import { CompanyRole } from '../dtos/roles';
import { EmailNotificationSetting } from '../dtos/notifications';

import { UserService } from '../services/felixApi/user.service';
import { CompanyService } from '../services/felixApi/company.service';
import { EmailNotificationsService } from './services/email-notifications.service';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { SUBHEADER_SMALL_WIDTH } from '../../config/global-config';



@Component({
  selector: 'js-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  cssSmallWidth = SUBHEADER_SMALL_WIDTH;
  tableData: any[];
  tableCols: string[];

  users: User[];
  roles: CompanyRole[];

  notiSettings: EmailNotificationSetting[];
  pendingNotiSettings: EmailNotificationSetting[] = [];

  variationTypes = VariationTypeEnum;
  variationTypeKeys: string[];
  variationStatuses = VariationStatusEnum;

  loading = true;
  userMode = true;
  innerWidth: number;
  subscriptions: Subscription[] = [];
  currVariationType: any;

  constructor(
    private userService: UserService, private companyService: CompanyService,
    private emailNotiService: EmailNotificationsService, private globalService: GlobalService,
    private notiService: NotificationService, private cd: ChangeDetectorRef,
    private router: Router) {

      this.subscribeToUsersRolesNotiSettings();

      this.subscriptions.push(
        this.globalService.innerWidthChanged.subscribe( width => {
          this.innerWidth = width;
        })
      );

      this.tableCols = Object.keys(this.variationStatuses).filter(k => isNaN(Number(k)));
      this.variationTypeKeys = Object.keys(this.variationTypes).filter(k => isNaN(Number(k)));
      this.currVariationType = 1;
      this.tableCols.unshift('Name');
   }

  ngOnInit() {
    this.innerWidth = this.globalService.innerWidth;
  }

  modeChanged(mode: boolean) {
    this.cd.detectChanges();
    this.userMode = mode;
    if (this.userMode) {
      this.tableData = this.users;
    } else {
      this.tableData = this.roles;
    }
  }

  varTypeChanged() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 0);
  }

  checked($event, entity, column: string) {
    const adding = $event.checked;
    const varStatusNum = VariationStatusEnum[column];
    let newSetting: EmailNotificationSetting;
    // user:
    if (entity.email) {
      newSetting = this.createUserNoti(entity, this.currVariationType, varStatusNum);
    // client or trade:
    } else if (entity.roleDescription && entity.roleDescription === 'DUMMY') {
      newSetting = this.createTypeNoti(entity, this.currVariationType, varStatusNum);
    // role:
    } else if (entity.roleDescription) {
      newSetting = this.createRoleNoti(entity, this.currVariationType, varStatusNum);
    }

    /* can get 2 events from 1 click */
    if (this.alreadyWaitinOnSetting(newSetting)) {
      return;
    }

    this.pendingNotiSettings.push(newSetting);

    if (adding) {
      this.addSetting(newSetting);
    } else {
      this.deleteSetting(newSetting);
    }

  }

  addSetting(newSetting: EmailNotificationSetting) {
      this.emailNotiService.postNotiSetting(newSetting).subscribe( addedSetting => {
        this.removePending(newSetting);
        this.notiSettings.push(addedSetting);
        this.notiService.showSuccess('Notification Setting Added', null, {timeOut: 2000});
      }, err => {
        this.notiService.showError(err);
        this.removePending(newSetting);
      });

  }

  removePending(setting: EmailNotificationSetting) {
    this.pendingNotiSettings = this.pendingNotiSettings.filter( pending => {
      if (this.compareNotiSetting(setting, pending)) {
        return false;
      } else {
        return true;
      }
    });
  }

  deleteSetting(delSetting: EmailNotificationSetting) {
    // find existing record to get id
    let deleteId: number;
    for (const setting of this.notiSettings) {
      if (this.compareNotiSetting(setting, delSetting)) {
        deleteId = setting.id;
      }
    }
    if (deleteId) {
      this.emailNotiService.deleteNotiSetting(deleteId).subscribe( res => {
        this.removeDeleted(delSetting);
        this.removePending(delSetting);
        this.notiService.showSuccess('Notification Setting Deleted', null, {timeOut: 2000});
      }, err => {
        this.removePending(delSetting);
        this.notiService.showError(err);
      });
    } else {
      console.log('couldnt find following setting to delete');
      console.log(delSetting);
      console.log('from the list:');
      console.log(this.notiSettings);
    }
  }

  removeDeleted(setting: EmailNotificationSetting) {
    this.notiSettings = this.notiSettings.filter( pending => {
      if (this.compareNotiSetting(setting, pending)) {
        return false;
      } else {
        return true;
      }
    });
  }

  amIdisabled(entity, col: string) {
    const varStatusNum = VariationStatusEnum[col];

    for (const setting of this.pendingNotiSettings) {
      // user
      if (entity.email) {
         const match = this.compareNotiSettingToUser(setting, entity, varStatusNum, this.currVariationType);
         if (match) {
           return true;
         }
      // client or trade
      } else if (entity.roleDescription && entity.roleDescription === 'DUMMY') {
        const match = this.compareNotiSettingToUserType(setting, entity, varStatusNum, this.currVariationType);
        if (match) {
          return true;
        }
      // role
      } else if (entity.roleDescription) {
        const match = this.compareNotiSettingToRole(setting, entity, varStatusNum, this.currVariationType);
         if (match) {
           return true;
         }
      }
    }
    return false;
  }

  amIChecked(entity, column: string): boolean {
    const varStatusNum = VariationStatusEnum[column];

    if (entity.email) { // user
      return this.findMatchingNotiSettingUser(this.currVariationType, varStatusNum, entity.id);
    } else if (entity.roleDescription && entity.roleDescription === 'DUMMY') { // client or trade
      return this.findMatchingNotiSettingUserType(this.currVariationType, varStatusNum, entity.roleId);
    } else if (entity.roleDescription) { // role
      return this.findMatchingNotiSettingRole(this.currVariationType, varStatusNum, entity.roleId);
    }
  }

  alreadyWaitinOnSetting(setting: EmailNotificationSetting): boolean {
    for (const pendingSetting of this.pendingNotiSettings) {
      if (this.compareNotiSetting(pendingSetting, setting)) {
        // console.log('already waiting')
        return true;
      }
    }
    return false;
  }

  findMatchingNotiSettingUser(varType: number, varStatusNum: number, userId: number ): boolean {
    for (const setting of this.notiSettings) {
      if (setting.variationType === varType && setting.variationStatusId === varStatusNum && setting.userId === userId) {
        return true;
      }
    }
    return false;
  }

  findMatchingNotiSettingRole(varType: number, varStatusNum: number, roleId: number ): boolean {
    for (const setting of this.notiSettings) {
      if (setting.variationType === varType && setting.variationStatusId === varStatusNum && setting.roleId === roleId) {
        return true;
      }
    }
    return false;
  }

  findMatchingNotiSettingUserType(varType: number, varStatusNum: number, userType: number): boolean {
    for (const setting of this.notiSettings) {
      if (setting.variationType === varType && setting.variationStatusId === varStatusNum && setting.emailUserTypeId === userType) {
        return true;
      }
    }
    return false;
  }

  createUserNoti(user: User, varType: number, varStatus: number) {
    return new EmailNotificationSetting(varType, 1, user.id, null, varStatus);
  }

  createRoleNoti(role: CompanyRole, varType: number, varStatus: number) {
    return new EmailNotificationSetting(varType, 2, null, role.roleId, varStatus);
  }

  createTypeNoti(role: CompanyRole, varType: number, varStatus: number) {
    const userType = role.roleId;
    return new EmailNotificationSetting(varType, userType, null, null, varStatus);
  }

  compareNotiSetting(x: EmailNotificationSetting, y: EmailNotificationSetting) {
    if (x.emailUserTypeId === y.emailUserTypeId &&
        x.roleId === y.roleId &&
        x.userId === y.userId &&
        x.variationStatusId === y.variationStatusId &&
        x.variationType === y.variationType) {
          return true;
        } else {
          return false;
        }
  }

  compareNotiSettingToUser(setting: EmailNotificationSetting, user: User, varStatus: number, varType: number) {
    if (setting.emailUserTypeId === 1 &&
        setting.roleId === null &&
        setting.userId === user.id &&
        setting.variationStatusId === varStatus &&
        setting.variationType === varType) {
      return true;
    } else {
      return false;
    }
  }

  compareNotiSettingToRole(setting: EmailNotificationSetting, role: CompanyRole, varStatus: number, varType: number) {
    if (setting.emailUserTypeId === 2 &&
        setting.roleId === role.id &&
        setting.userId === null &&
        setting.variationStatusId === varStatus &&
        setting.variationType === varType) {
      return true;
    } else {
      return false;
    }
  }

  compareNotiSettingToUserType(setting: EmailNotificationSetting, role: CompanyRole, varStatus: number, varType: number) {
    const userType = role.roleId;
    if (setting.emailUserTypeId === userType &&
        setting.roleId === null &&
        setting.userId === null &&
        setting.variationStatusId === varStatus &&
        setting.variationType === varType) {
      return true;
    } else {
      return false;
    }
  }

  configureEmail() {
    this.router.navigate(['notificationControl']);
  }

  subscribeToUsersRolesNotiSettings() {
    this.subscriptions.push(
      forkJoin([
        this.userService.getCurrCompUsers(),
        this.companyService.getCompanyRoles(),
        this.emailNotiService.getNotiSettings()
      ]).subscribe( ([users, roles, notiSettings]) => {
          this.users = users.filter(u => u.isActive);
          this.roles = roles.filter(i => i.isActive);
          this.roles.unshift(new CompanyRole('All Client Users', 4));
          this.roles.unshift(new CompanyRole('All Trade Users', 3));

          this.notiSettings = notiSettings;

          this.modeChanged(true);
          this.loading = false;

        }, err => {
          this.notiService.notify(err);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach( sub => {
      sub.unsubscribe();
    });
  }
}
