
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { BoardMaster, BoardType } from '../../../dtos/boards';
import { HttpService } from '../../../services/http.service';
import { BoardAttachment } from '../../../dtos/boards';


@Injectable()
export class BoardMasterService {

  baseURL = environment.apiUrl + '/boards';

  constructor(
    private _http: HttpClient,
    private httpService: HttpService
  ) { }

  getBoards(): Observable<BoardMaster[]> {
    return this._http.get<BoardMaster[]>(this.baseURL, this.httpService.getHttpOptions());
  }

  addBoard(itm: any) {
    return this._http.post(this.baseURL, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateBoard(id, itm: any) {
    const url = this.baseURL + '?boardId=' + id;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteBoard(itmId: number) {
    const url = this.baseURL + '?boardId=' + itmId;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }


  // Board Types
  getBoardTypes(): Observable<BoardType[]> {
    return this._http.get<BoardType[]>(environment.apiUrl + '/board-types', this.httpService.getHttpOptions());
  }

  addBoardType(itm: any) {
    return this._http.post(environment.apiUrl + '/board-types', JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateBoardType(id, itm: any) {
    return this._http.patch(environment.apiUrl + '/board-types/' + id, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteBoardType(id: number) {
    return this._http.delete(environment.apiUrl + '/board-types/' + id, this.httpService.getHttpOptions());
  }


  // board attachments
  getBoardAttachment(id): Observable<BoardAttachment> {
    return this._http.get<BoardAttachment>(this.baseURL + '/' + id + '/attachment', this.httpService.getHttpFileOptions()).pipe(
      catchError(this.handleError));
  }

  postBoardAttachment(boardId, imageFile) {
    return this._http.post(this.baseURL + '/' + boardId, imageFile, this.httpService.getHttpFileOptions());
  }

  deleteBoardAttachment(id) {
    return this._http.delete(environment.apiUrl + '/board-attachments/' + id, this.httpService.getHttpFileOptions());
  }

  private handleError(err: HttpErrorResponse) {
      console.log(JSON.stringify(err));
      return observableThrowError(err);
  }
}
