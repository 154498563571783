import { Division } from './../../../dtos/division';
import { DivisionService } from './../../../services/felixApi/division.service';
import { NotificationService } from './../../../services/notification.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentSettingAPIService } from '../attachment-settings-api.service';
import { GlobalService } from '../../../services/global.service';
import { GridService } from '../../../services/grid.service';
import { AddAttachSettingComponent } from '../add-attach-setting/add-attach-setting.component';
import { ManageAttachFileComponent } from '../manage-attach-file/manage-attach-file.component';
import { Subscription } from 'rxjs';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'js-attachment-grid',
  templateUrl: './attachment-grid.component.html',
  styleUrls: ['./attachment-grid.component.scss']
})
export class AttachmentGridComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: DataSource;
  gridHeight: number;
  descriptionTitle: string;
  attachmentTypes = [
    { id: 1, description: 'Start' },
    { id: 2, description: 'End' },
    { id: 3, description: 'Separate' }
  ];
  attachmentTypeId: number;
  divisions: Division[];

  constructor(
    public attachSettingsService: AttachmentSettingAPIService,
    private globalService: GlobalService,
    public gridService: GridService,
    private modalService: NgbModal,
    private notiService: NotificationService,
    private divisionService: DivisionService
  ) {
    this.reorder = this.reorder.bind(this);
    this.onLookupValueChanged = this.onLookupValueChanged.bind(this);

    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.gridHeight = (window.innerHeight - 110);
      })
    );
  }

  ngOnInit(): void {
    this.gridHeight = (window.innerHeight - 110);
    this.getDivisions();
    this.setUpDataSource();
  }

  getDivisions() {
    this.subscriptions.push(
      this.divisionService.getDivisions().subscribe(divisions => {
        this.divisions = divisions;
      }, err => {
        this.notiService.notify(err);
      })
    );
  }

  add() {
    const modalRef = this.modalService.open(AddAttachSettingComponent, { windowClass: 'modal-600' });
    modalRef.componentInstance.mode = '';

    modalRef.result.then(() => {
      this.refresh(false);
    }, (reason) => {
    });
  }

  refresh(fulRefresh = true) {
    if (fulRefresh) {
      this.attachSettingsService.cache = null;
    }
    this.grid.instance.refresh();
  }

  reorder(e) {
    if (e) {
      const visibleRows = e.component.getVisibleRows();
      const toIndex = visibleRows[e.toIndex].data;

      if (e.itemData.attachAtTheStart !== toIndex.attachAtTheStart || e.itemData.printSeparate !== toIndex.printSeparate) {
        this.notiService.showInfo('Cannot drop here');
      } else {
        this.attachSettingsService.cache = null; // would have to calculate full re-order logic to use cache
        this.grid.instance.beginCustomLoading('Re-ordering');

        this.subscriptions.push(
          this.attachSettingsService.patchCompanySetting(e.itemData.id, { orderNo: toIndex.orderNo }).subscribe(() => {
            this.grid.instance.endCustomLoading();
            this.setUpDataSource();
          }, err => {
            this.grid.instance.endCustomLoading();
            this.notiService.notify(err);
            this.setUpDataSource();
          })
        );
      }
    }
  }

  manageFile(e) {
    const modalRef = this.modalService.open(ManageAttachFileComponent, { windowClass: 'modal-600' });
    modalRef.componentInstance.attachmentParentRecordId = e.data.id;
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.attachSettingsService.getAttachmentSettings().subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      update: async (key, values) => {
        if (values.attachmentType) {
          if (values.attachmentType === 1 || values.attachmentType === 'Start') {
            values.attachAtTheStart = true;
            values.printSeparate = false;
          } else if (values.attachmentType === 2 || values.attachmentType === 'End') {
            values.attachAtTheStart = false;
            values.printSeparate = false;
          } else {
            values.attachAtTheStart = false;
            values.printSeparate = true;
          }
        }
        return new Promise((resolve, reject) =>
          this.attachSettingsService.patchCompanySetting(encodeURIComponent(key), values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.attachSettingsService.deleteCompanyAttachmentSetting(encodeURIComponent(key)).subscribe(res => {
            return resolve();
          }, err => {
            return reject(this.globalService.returnError(err));
          }));
      }
    });
  }

  customizeToolbar(e) {
    this.gridService.addRefreshBtnToToolbar(e, 2, 1);
    this.gridService.addItemTotoolbarPosition(e, 'addTemplate', true, 3, 1);
    // this.gridService.addItemTotoolbarPosition(e, 'modeTemplate', true, 4, 3);
  }

  ngOnDestroy() {
    this.attachSettingsService.cache = null;
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  calculateAttachType(data) {
    if (data) {
      if (data.printSeparate) {
        return 'Separate';
      } else if (data.attachAtTheStart) {
        return 'Start';
      } else {
        return 'End';
      }
    }
  }

  calculateTypeSortValue(data) {
    if (data) {
      if (data.printSeparate) {
        return 3;
      } else if (data.attachAtTheStart) {
        return 1;
      } else {
        return 2;
      }
    }
  }

  onLookupValueChanged(ea, e) {
    e.setValue(ea.value);
    this.attachmentTypeId = ea.value;
  }
}
