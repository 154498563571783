import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  removeFileType(fileName: string): string {
    const x = fileName.lastIndexOf('.');
    return fileName.substr(0, fileName.lastIndexOf('.'));
  }

  getFileType(fileName: string): string {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
  }

  moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  getErrorString(err): string {
      if (err && err.error && err.error.message) {
        return JSON.stringify(err.error.message);
      } else {
        return JSON.stringify(err);
      }
  }

  // assumes 'id' field
  editCache(object, cache: any[]) {
    if (cache) {
      const match = cache.filter(t => t.id === object.id)[0];
      if (match) {
          const index = cache.indexOf(match);
          cache[index] = object;
      }
    }
  }

  // assumes INPUT id is string and object ids are numbers
  deleteFromCache(id: string, cache: any[]) {
    const match = cache.filter(t => t.id.toString() === id)[0];
    if (match) {
        const index = cache.indexOf(match);
        cache.splice(index, 1);
    } else {
        console.log('couldnt delete from cache!');
    }
  }

}
