export enum VariationStatusEnum {
    Open = 1,
    Estimating = 2,
    'Pending Approval' = 5,
    Cancelled = 6,
    Approved = 7,
    Actioned = 8,
    Invoiced = 9
}

export enum VariationTypeEnum {
    PreConstruction = 1,
    Construction = 2,
    Office = 3,
    FinalAccount = 4,
    SalesVariation = 11,
    SalesProvisionalSum = 12,
    SalesOffice = 13,
    PreContractVariation = 21,
    PreContractProvisionalSum = 22,
    PreContractOffice = 23
}
