import { Component, OnDestroy, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OptionListService } from '../services/option-list.service';
import { FileProcessingService } from '../../services/file-processing.service';
import { NotificationService } from '../../services/notification.service';
import { GetBulkTemplateMode, UpdateModeDropDown } from '../types/option-list-misc.type';
import { HouseTypeApiService } from '../../maintenance/house-types/services/house-type.api.service';
import { HouseType } from '../../dtos/house-type';
import { ConfirmationModalComponent } from '../../shared/confirmation-modal.component';
import { Workbook } from 'exceljs';
import { IOptionList, OptionTypeEnum } from '../types/option-list';
import { Subscription } from 'rxjs';
import { EstimatingService } from '../../services/felixApi/estimating.service';
import { Recipe } from '../../dtos/recipe';


@Component({
  selector: 'js-bulk-update',
  templateUrl: './bulk-update.component.html',
  styleUrls: ['./bulk-update.component.scss']
})
export class BulkUpdateComponent implements OnInit, OnDestroy {

  houseTypes: HouseType[];
  modeDropdowns: UpdateModeDropDown[] = [];

  subscriptions: Subscription[] = [];
  closeText = 'Cancel';
  fileToUpload: any[] = [];
  updateDescs = false;
  disableHouseTypes = false;
  loading = true;
  downloading = false;
  uploading = false;
  modesEnum = GetBulkTemplateMode;
  selectedHouseType: number;
  selectedMode: number;
  selectedDate: any;
  optionListOneLevel: IOptionList[];
  recipes: Recipe[];

  constructor(
    private optionListService: OptionListService,
    private estimatingService: EstimatingService,
    private fileService: FileProcessingService,
    private houseTypeService: HouseTypeApiService,
    private notiService: NotificationService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    this.subscriptions.push(
      this.estimatingService.getRecipeWithSellingRates(true).subscribe(recipes => { this.recipes = recipes; })
    );
  }

  ngOnInit() {
    Object.keys(GetBulkTemplateMode).filter(k => isNaN(Number(k))).forEach(mode => {
      this.modeDropdowns.push({ id: this.modesEnum[mode], name: mode });
    });
    this.selectedDate = new Date();
    this.subscriptions.push(
      this.houseTypeService.getHouseTypes(true).subscribe(types => {
        this.houseTypes = types;
        this.selectedMode = GetBulkTemplateMode['Get Standard and House Opts'];
        this.loading = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  downloadTemplate() {
    this.downloading = true;
    this.optionListOneLevel = this.optionListService.getOptionListOneLevel();
    this.downloadToExcel();
  }

  uploadTemplate($event, testMode = false) {
    this.uploading = true;
    const formData: FormData = new FormData();
    formData.append('image', this.fileToUpload[0], this.fileToUpload[0].name);

    this.subscriptions.push(
      this.optionListService.postUploadtemplate(formData, this.updateDescs, testMode).subscribe(res => {
        this.uploading = false;
        this.closeText = 'Close';

        if (!testMode) {
          this.optionListService.refreshOptionList();
        }
        if (res.pdfReport) {
          const buffers = this.fileService.base64ToArrayBuffer(res.pdfReport);
          saveAs(buffers[1], res.pdfReportName + '.xls');
          if (testMode) {
            this.notiService.showWarning('Some errors found - see downloaded sheet', null, { disableTimeOut: true, closeButton: true });
          } else {
            this.notiService.showWarning(
              'Successful upload but some errors found - see downloaded sheet', null, { disableTimeOut: true, closeButton: true });
          }
        } else {
          if (testMode) {
            this.notiService.showSuccess('No Errors Found');
          } else {
            this.notiService.showSuccess('Changes Applied');
          }
        }
      }, err => {
        this.notiService.notify(err, { disableTimeOut: true, closeButton: true });
        this.uploading = false;
      })
    );
  }

  confirmProcess($event) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { windowClass: 'modal-middle' });
    modalRef.componentInstance.confirmationText = 'Do you wish to apply new prices and/or descriptions to your options?';

    modalRef.result.then((confirmed) => {
      if (confirmed) {
        this.uploadTemplate($event);
      }
    }, (reason) => {
    });
  }

  modeChanged(event) {
    if (event.value === 2) {
      this.disableHouseTypes = true;
      this.selectedHouseType = null;
    } else {
      this.disableHouseTypes = false;
    }
  }

  close() {
    this.activeModal.close();
  }

  downloadToExcel() {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Recipes', { pageSetup: { paperSize: 9, orientation: 'landscape' } });

    worksheet.columns = [
      { header: 'Product Code', key: 'productCode', width: 15 },
      { header: 'Description', key: 'description', width: 70 },
      { header: 'Added Price', key: 'addedPrice', width: 15 },
      { header: 'Changed Price', key: 'changedPrice', width: 15 },
      { header: 'Level', key: 'level', width: 10 },
      { header: 'Add Recipe', key: 'addRecipe', width: 15 },
      { header: 'Change Recipe', key: 'changeRecipe', width: 15 }
    ];

    this.optionListOneLevel.filter(
      i => (this.selectedMode === GetBulkTemplateMode['Get Standard and House Opts']
        || (this.selectedMode === GetBulkTemplateMode['Get House Opts only'] && i.optionTypeId === OptionTypeEnum['House Option'])
        || (this.selectedMode === GetBulkTemplateMode['Get Standard Opts only'] && i.optionTypeId !== OptionTypeEnum['House Option']))
    ).forEach(opt => {
      worksheet.addRow([
        opt.productCode,
        opt.description,
        opt.salesPriceIfAdded ? Math.round(opt.salesPriceIfAdded * 100) / 100 : 0,
        opt.salesPriceIfChangedInSameList ? Math.round(opt.salesPriceIfChangedInSameList * 100) / 100 : 0,
        opt.optionColour,
        this.recipes?.find(i => i.id === opt.priceIfAddedRecipeId)?.recipeCode,
        this.recipes?.find(i => i.id === opt.priceIfChangedRecipeId)?.recipeCode
      ]);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'OptionList.xlsx');
    });

    this.downloading = false;
  }
}
