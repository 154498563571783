import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { ClaimMaster } from '../../../dtos/claim-master';
import { UtilsService } from '../../../services/utils.service';
import { tap, catchError } from 'rxjs/operators';
import { HttpService } from '../../../services/http.service';
import { GlobalService } from '../../../services/global.service';
import { environment } from '../../../../environments/environment';
import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { ClaimMasterLine } from '../../../dtos/claim-master-line';


@Injectable()
export class ClaimApiService {

    baseURL = environment.apiUrl + '/claim-masters/';
    baseAttachmentURL = environment.apiUrl + '/claim-master-attachments/';
    baseLineURL = environment.apiUrl + '/claim-master-lines/';

    private claimMasterCache: ClaimMaster[];

    constructor(
        private http: HttpClient,
        private httpService: HttpService,
        private globalService: GlobalService,
        private utilsService: UtilsService
    ) {
        this.globalService.companySelected.subscribe(c => {
            this.claimMasterCache = null;
        });
    }

    // getClaimMasters(): Observable<ClaimMaster[]> {
    //     const url = this.baseURL;
    //     return this.http.get<ClaimMaster[]>(url, this.httpService.getHttpOptions());
    // }

    getClaimMastersCached(): Observable<ClaimMaster[]> {
        if (this.claimMasterCache) {
            return of(this.claimMasterCache);
        } else {
            return this.http.get<ClaimMaster[]>(this.baseURL, this.httpService.getHttpOptions()).pipe(
                tap(res => this.claimMasterCache = res),
                catchError(this.handleError));
        }
    }

    postClaimMaster(type: ClaimMaster): Observable<ClaimMaster> {
        return this.http.post<ClaimMaster>(this.baseURL, type, this.httpService.getHttpOptions())
            .pipe(
                tap(res => {
                    this.claimMasterCache.push(res);
                })
            );
    }

    patchClaimMaster(type: ClaimMaster, id?): Observable<ClaimMaster> {
        const url = this.baseURL + (id ? id : type.id);

        return this.http.patch<ClaimMaster>(url, type, this.httpService.getHttpOptions())
            .pipe(
                tap(res => {
                    this.utilsService.editCache(res, this.claimMasterCache);
                })
            );
    }

    deleteClaimMaster(id: string) {
        return this.http.delete(this.baseURL + id, this.httpService.getHttpOptions())
            .pipe(
                tap(res => {
                    this.utilsService.deleteFromCache(id, this.claimMasterCache);
                })
            );
    }

    clearCaches() {
        this.claimMasterCache = null;
    }

    getClaimAttachment(claimMasterId: string) {
        const url = this.baseAttachmentURL + claimMasterId;
        return this.http.get(url, this.httpService.getHttpFileOptions());
    }

    postClaimAttachment(claimMasterId: string, file) {
        const url = this.baseAttachmentURL + claimMasterId;
        return this.http.post(url, file, this.httpService.getHttpFileOptions());
    }

    patchClaimAttachment(claimMasterId: string, file) {
        const url = this.baseAttachmentURL + claimMasterId;
        return this.http.patch(url, file, this.httpService.getHttpFileOptions());
    }

    deleteClaimAttachment(claimMasterId: string) {
        const url = this.baseAttachmentURL + claimMasterId;
        return this.http.delete(url, this.httpService.getHttpFileOptions());
    }

    // Claim Lines

    getClaimMasterLinesCached(claimMasterId: number): Observable<ClaimMasterLine[]> {
        return this.http.get<ClaimMasterLine[]>(this.baseURL + claimMasterId + '/lines', this.httpService.getHttpOptions())
            .pipe(catchError(this.handleError));
    }

    postClaimMasterLine(claimMasterId: number, type: ClaimMasterLine): Observable<ClaimMasterLine> {
        return this.http.post<ClaimMasterLine>(this.baseURL + claimMasterId + '/lines', type, this.httpService.getHttpOptions());
    }

    patchClaimMasterLine(type: ClaimMasterLine, id?): Observable<ClaimMasterLine> {
        const url = this.baseLineURL + (id ? id : type.id);

        return this.http.patch<ClaimMasterLine>(url, type, this.httpService.getHttpOptions());
    }

    moveClaimMasterLine(id: number, orderNumber: number): Observable<ClaimMasterLine> {
        const url = this.baseLineURL + 'move/' + id + '?orderNumber=' + orderNumber;

        return this.http.patch<ClaimMasterLine>(url, JSON.stringify({}), this.httpService.getHttpOptions());
    }

    deleteClaimMasterLine(id: string) {
        return this.http.delete(this.baseLineURL + id, this.httpService.getHttpOptions());
    }

    private handleError(err: HttpErrorResponse) {
        console.log(JSON.stringify(err));
        return observableThrowError(err);
    }
}
