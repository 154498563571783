import { Directive, ElementRef, AfterViewInit, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[jsFocusElement]'
})
export class FocusElementDirective implements AfterContentInit {

  constructor(private element: ElementRef) { }

  ngAfterContentInit() {
    setTimeout(() => {
      this.element.nativeElement.focus();
    }, 10);
  }
}
