import { GlobalService } from './../../services/global.service';
import { Component, OnInit } from '@angular/core';

import { BoardMaster, BoardItemRow } from '../../dtos/boards';
import { IOptionList } from '../types/option-list';
import { OptionListService } from '../services/option-list.service';
import { BoardItemService } from './services/board-item.service';
import { BoardMasterService } from './services/board-master.service';
import { OptionColourEnum } from '../types/option-list';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'js-option-list-boards-first',
  templateUrl: './option-list-boards-first.component.html',
  styleUrls: ['./option-list-boards-first.component.scss']
})
export class OptionListBoardsFirstComponent implements OnInit {

  subscriptions: Subscription[] = [];
  boardNames: BoardMaster[] = [];
  boardItemRows: BoardItemRow[] = [];
  // shownItemRows: BoardItemRow[] = [];
  currentPage = 0;
  pageSize = 20;
  numberOfPages: number;
  optionList: IOptionList[] = [];
  currentOptionId: number;
  loading = true;
  errorMessage: any;
  containerMargin = 15;
  updatedOption: any;
  updateError = false;
  actualRow: number;
  optionColourEnum = OptionColourEnum;
  tableHeight: number;

  constructor(private _optionListService: OptionListService,
    private _boardMasterService: BoardMasterService,
    private _boardItemService: BoardItemService,
    private _router: Router,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.tableHeight = window.innerHeight - 130;

    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.tableHeight = window.innerHeight - 130;
      })
    );

    this.getBoardNames();
    this.getBoardItems();
  }

  getBoardNames() {
    this._boardMasterService.getBoards()
      .subscribe(
        boardNames => {
          this.boardNames = boardNames;
        },
        error => this.errorMessage = <any>error);
  }

  getBoardItems() {
    this.loading = true;
    this._boardItemService.getBoardItemRows()
      .subscribe(
        boardItemRows => {
          this.boardItemRows = boardItemRows;
          this.loading = false;
          this.numberOfPages = Math.floor(this.boardItemRows.length / this.pageSize);
          if ((this.boardItemRows.length / this.pageSize) === Math.floor(this.boardItemRows.length / this.pageSize)) {
            this.numberOfPages--;
          }
          // this.getCurrentPages();
        },
        error => this.errorMessage = <any>error);
  }

  toggledDropdown(event, optionListId) {
    if (event) {
      if (this.currentOptionId !== optionListId) {
        this.optionList = []; // reset as the front end is quicker than the observable
        this._optionListService.getOptionListChildren(optionListId, false)
          .subscribe(
            optionList => {
              this.optionList = optionList;
              this.currentOptionId = optionListId;
            },
            error => {
              this.errorMessage = <any>error;
              console.log('error -' + JSON.stringify(error));
            });
      }
    }
  }

  setSelection(boardRow, opt, index, rowNo) {
    // save the selection
    if (opt.id !== boardRow.boardSelections[index].selectedOptionId) {
      // changed so save
      this.actualRow = (this.currentPage * this.pageSize) + rowNo;
      if (boardRow.boardSelections[index].id === 0) {
        // add
        this.updatedOption = { optionListId: boardRow.optionListId, selectedOptionId: opt.id };
        this._boardItemService.addBoardItem(boardRow.boardSelections[index].boardId, this.updatedOption).subscribe(
          res => {
            this.updateError = false;
            this.boardItemRows[this.actualRow].boardSelections[index].description = opt.description;
            this.boardItemRows[this.actualRow].boardSelections[index].selectedOptionId = opt.id;
            this.boardItemRows[this.actualRow].boardSelections[index].id = res.id;
            // this.getCurrentPages();
          },
          err => {
            console.log('error code ' + err.status);
            this.updateError = true;
          }
        );
      } else {
        // edit
        this.updatedOption = { selectedOptionId: opt.id };
        this._boardItemService.updateBoardItem(boardRow.boardSelections[index].id, this.updatedOption).subscribe(
          res => {
            this.updateError = false;
            this.boardItemRows[this.actualRow].boardSelections[index].description = opt.description;
            this.boardItemRows[this.actualRow].boardSelections[index].selectedOptionId = opt.id;
            // this.getCurrentPages();
          },
          err => {
            console.log('error code ' + err.status);
            this.updateError = true;
          }
        );
      }
    }
  }

  deleteSelection(boardRow, index, rowNo) {
    // save the selection
    this.actualRow = (this.currentPage * this.pageSize) + rowNo;
    if (boardRow.boardSelections[index].selectedOptionId !== 0) {
      this._boardItemService.deleteBoardItem(boardRow.boardSelections[index].id).subscribe(
        res => {
          this.updateError = false;
          this.boardItemRows[this.actualRow].boardSelections[index].description = '';
          this.boardItemRows[this.actualRow].boardSelections[index].selectedOptionId = 0;
          // this.getCurrentPages();
        },
        err => {
          console.log('error code ' + err.status);
          this.updateError = true;
        }
      );
    }
  }

  // nextPage() {
  //   // move the next page into the shown array
  //   this.currentPage++;
  //   this.getCurrentPages();
  // }

  // previousPage() {
  //   // move to previous page
  //   this.currentPage--;
  //   this.getCurrentPages();
  // }

  // getCurrentPages() {
  //   this.shownItemRows = [];
  //   for (let i = 0; i < this.pageSize; i++) {
  //     if (((this.currentPage * this.pageSize) + i) < this.boardItemRows.length) {
  //       this.shownItemRows[i] = this.boardItemRows[(this.currentPage * this.pageSize) + i];
  //     }
  //   }
  // }

  goBack() {
    this._router.navigate(['/optionBoards']);
  }
}
