import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { IOptionListAttachment } from '../types/option-list-attachment';
import { HttpService } from '../..//services/http.service';


@Injectable({
  providedIn: 'root'
})
export class OptionListAttachmentService {

  baseURL = environment.apiUrl + '/option-list-attachments/';
  imgCache: IOptionListAttachment[] = [];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,

  ) {
    this.httpService.clearCaches.subscribe(c => {
      this.imgCache = null;
    });
  }

  getOptionListAttachment(id): Observable<IOptionListAttachment> {
    const img = this.imgCache.filter(i => i.optionListId === id);
    if (img[0]) {
      return of(img[0]);
    } else {
      const options = this.httpService.getHttpFileOptions();
      return this._http.get<IOptionListAttachment>(this.baseURL + id, options)
        .pipe(
          tap(res => this.imgCache.push(res))
        );
    }
  }

  postOptionListAttachment(optionId, imageFile) {
    this.imgCache = this.imgCache.filter(c => c.optionListId !== optionId);

    const options = this.httpService.getHttpFileOptions();
    // options.headers.delete('Content-Type'); // won't work?
    return this._http.post(this.baseURL + optionId, imageFile, options);
  }

  // patchOptionListAttachment(attachmentId, imageFile) {
  //   this.imgCache = this.imgCache.filter(c => c.id !== attachmentId);

  //   const options = this.httpService.getHttpFileOptions();
  //   return this._http.patch(this.baseURL + attachmentId, imageFile, options);
  // }

  deleteOptionListAttachment(id) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.delete(this.baseURL + id, options)
      .pipe(
        tap(() => {
          const attachmentIndex = this.imgCache.findIndex(i => i.optionListId === id);
          if (attachmentIndex >= 0) {
            // remove the record from the array
            this.imgCache.splice(attachmentIndex, 1);
          }
        }),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }

}


// getOptionListLibraryImage(id): Observable<IOptionImage> {
//   const options = this.globalService.getHttpFileOptions();
//   return this._http.get<IOptionImage>(this.globalService.getApiUrl() + '/option-images/' + id, options)
//   .pipe(
//       catchError(this.handleError.bind(this)));
// }
