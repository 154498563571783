import { GlobalService } from './../../../services/global.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { Category } from '../../../dtos/category';
import { OptionListCategory } from '../../types/option-list-category';
import { HttpService } from '../../../services/http.service';


@Injectable()
export class CategoryApiService {

    baseURL = environment.apiUrl + '/categories/';
    optionBaseURL = environment.apiUrl + '/option-lists/';
    categoryCache: Category[];
    categories: Category[];

    constructor(
        private _http: HttpClient,
        private httpService: HttpService,
        private globalService: GlobalService
    ) { }

    getCategories(): Observable<Category[]> {

        if (this.categoryCache) {
            return of(
                this.categoryCache.map(x => Object.assign({}, x)) // return copy!
            );
        } else {
            const typesObs = this._http.get<Category[]>(this.baseURL, this.httpService.getHttpOptions());
            typesObs.subscribe(cats => {
                this.categoryCache = cats;
            });
            return typesObs;
        }
    }

    getActiveCategories(): Observable<Category[]> {
        const url = this.baseURL + '?activeOnly=true';
        return this._http.get<Category[]>(url, this.httpService.getHttpOptions())
            .pipe(
                tap(res => {
                  this.categories = res;
                }),
                catchError(this.handleError));
    }

    editCategory(category: Category) {
        const url = this.baseURL + category.id;
        return this._http.patch<Category>(url, category, this.httpService.getHttpOptions())
            .pipe(
                map(res => {
                    this.editCatCache(res);
                    return res;
                })
            );
    }

    addCategory(category: Category) {
        return this._http.post<Category>(this.baseURL, JSON.stringify(category), this.httpService.getHttpOptions())
            .pipe(
                map(res => {
                    this.categoryCache.push(res);
                    return res;
                })
            );
    }

    getOptionListCategories(optionListId: number): Observable<OptionListCategory[]> {
        const url = this.optionBaseURL + optionListId + '/categories';
        return this._http.get<OptionListCategory[]>(url, this.httpService.getHttpOptions());
    }

    getCategoriesData(optionListId: number): Observable<OptionListCategory[]> {
        return forkJoin(
            [this.getOptionListCategories(optionListId),
            this.getActiveCategories()
            ]
        )
            .pipe(map(
                ([data]) => {
                    return data;
                }, (err) => {
                    return this.globalService.returnError(err);
                }
            ));
    }

    addOptionListCategory(optionListId: number, categoryId: number) {
        const url = this.optionBaseURL + optionListId + '/categories';
        return this._http.post(url, JSON.stringify({ categoryId: categoryId }), this.httpService.getHttpOptions());
    }

    deleteOptionListCategory(catId: number) {
        const url = environment.apiUrl + '/option-list-categories/' + catId;
        return this._http.delete(url, this.httpService.getHttpOptions());
    }

    editOptionListCategory() {
        //
    }

    editCatCache(inCat: Category) {
        const type = this.categoryCache.filter(t => t.id === inCat.id)[0];
        if (type) {
            const index = this.categoryCache.indexOf(type);
            this.categoryCache[index] = inCat;
        } else {
            console.log('couldnt edit category cache!');
        }
    }

    clearCache() {
        this.categoryCache = null;
    }

    private handleError(err: HttpErrorResponse) {
        console.log(err);
        return observableThrowError(err);
    }

}
