import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';


@Component({
  selector: 'js-btn-spinner',
  templateUrl: './btn-spinner.component.html',
  styleUrls: ['./btn-spinner.component.scss']
})
export class BtnSpinnerComponent {

  @Input()
  scale = 1.3;

  constructor(private _sanitizer: DomSanitizer) { }


  get myTransform(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle('scale(' + this.scale + ')');
  }

}
