import { Component, Input, OnChanges } from '@angular/core';

import { ImageLibraryService } from '../../services/felixApi/image-library.service';
import { OptionListAttachmentService } from '../../option-lists/services/option-list-attachment.service';
import { IOptionListAttachment } from '../../option-lists/types/option-list-attachment';
import { LogService } from '../../services/log.service';


@Component({
  selector: 'js-show-image',
  templateUrl: './show-image.component.html',
  styleUrls: ['./show-image.component.scss']
})
export class ShowImageComponent implements OnChanges {
  @Input() imageId: number;
  @Input() imageLibrary: boolean;
  @Input() attachmentImageId: number; // not used to get attachment but required to trigger onChanges

  @Input() inline = false;

  COMPONENT_NAME = 'show-image';

  optionAttachment: IOptionListAttachment;
  image: Blob;
  imageFound: boolean;
  loading: boolean;
  errorMessage: string;

  constructor(
    private _optionListAttachmentService: OptionListAttachmentService,
    private _imageLibraryService: ImageLibraryService,
    private logService: LogService
  ) { }

  ngOnChanges() {
    this.imageFound = false;
    this.loading = true;

    if (this.imageLibrary) {
      this._imageLibraryService.getLibraryImageAttachment(this.imageId)
        .subscribe(
          optionAttachment => {
            this.image = optionAttachment.attachment;
            this.imageFound = true;
            this.loading = false;
          },
          err => {
            this.imageFound = false;
            this.loading = false;
            this.errorMessage = 'There was an error opening the file. Please try again in a moment.';
            this.logService.log(this.COMPONENT_NAME, '', err,
              'Error reading library image ' + this.imageId, true, false);
          }
        );
    } else {
      this._optionListAttachmentService.getOptionListAttachment(this.imageId)
        .subscribe(
          optionAttachment => {
            this.image = optionAttachment.attachment;
            this.imageFound = true;
            this.loading = false;
          },
          err => {
            this.imageFound = false;
            this.loading = false;
            this.errorMessage = 'There was an error opening the file. Please try again in a moment.';
            this.logService.log(this.COMPONENT_NAME, '', err,
              'Error reading job item attachment ' + this.imageId, true, false);
          }
        );
    }
  }

}
