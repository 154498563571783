import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { IdTokenClaims } from '@azure/msal-common';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { AuthService } from '../services/auth.service';
// import { LogService } from '../services/log.service';
import { GlobalService } from '../services/global.service';
import { environment } from '../../environments/environment';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
  selector: 'js-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {

  isHttps = true;
  showLoginButton = false;
  activelyLoggingIn = false;
  containerHeight: number;
  private readonly _destroying$ = new Subject<void>();
  showRefreshButton = false;
  isAlive = true;

  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      this.containerHeight = window.innerHeight - 100;
    }, 500); // wait for iPhone
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private router: Router,
    private auth: AuthService,
    private notiService: NotificationService,
    // private logger: LogService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    // will get cryptic msal errors if trying to login from http
    if (!this.globalService.isHttps()) {
      this.isHttps = false;
    }

    // this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    // this.msalBroadcastService.msalSubject$
    //   .pipe(
    //     filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    //   )
    //   .subscribe((result: EventMessage) => {
    //     if (this.msalService.instance.getAllAccounts().length === 0) {
    //       window.location.pathname = '/';
    //     } else {
    //       this.setLoginDisplay();
    //     }
    //   });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          || msg.eventType === EventType.SSO_SILENT_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {

        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

        if (idtoken.acr === environment.authority || idtoken.tfp === environment.authority) {
          this.msalService.instance.setActiveAccount(payload.account);
        }

        return result;
      });

    // auto-login if param set
    const session = this.globalService.getSessionObject();
    if (session.login) {
      this.globalService.setSessionAtt('login', false);
      this.loginClicked();
    }


    setTimeout(() => {
      this.showRefreshButton = true;
    }, 2000); // wait for msal as it's possibly reading

    setTimeout(() => {
      if (!this.showLoginButton && !this.activelyLoggingIn && this.isHttps && this.showRefreshButton && this.isAlive) {
        this.refresh();
      }
    }, 8000); // time out
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }


    const allAccounts = this.msalService.instance.getAllAccounts();

    if (allAccounts.length > 1) {
      this.notiService.showError('Multiple logins detected. Logging out...');
      setTimeout(() => {
        this.auth.signOut();
      }, 4000);
    } else if (allAccounts.length > 0 && allAccounts[0].idTokenClaims && allAccounts[0].idTokenClaims['emails'] && allAccounts[0].idTokenClaims['emails'][0]) {
      const email = allAccounts[0].idTokenClaims['emails'][0];
      this.auth.setUserEmail(email);
      this.activelyLoggingIn = false;
      this.router.navigateByUrl('welcome');
    } else {
      setTimeout(() => {
        this.showLoginButton = true;
      }, 4000); // wait for msal as it's possibly reading
      if (this.activelyLoggingIn) {
        this.notiService.showError('There was an error logging you in. Please try again in a moment.');
        this.activelyLoggingIn = false;
      }
    }
  }

  setLoginDisplay() {
    this.showLoginButton = this.msalService.instance.getAllAccounts().length <= 0;
  }

  loginClicked() {
    this.showLoginButton = false;
    this.activelyLoggingIn = true;
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    } else {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    }
  }

  goToHttps() {
    window.location.href = environment.applicationUrl;
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.isAlive = false;
  }

  refresh(): void {
    window.location.reload();
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  clearCache() {
    localStorage.clear();
    this.notiService.showInfo('Cache cleared. Logging out...');
    setTimeout(() => {
      this.auth.signOut();
    }, 2000);
  }
}
