import { NotificationService } from './../../services/notification.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { GridService } from '../../services/grid.service';
import { CompanyActivityService } from './company-activity.service';
import { Subscription } from 'rxjs';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'js-company-activities',
  templateUrl: './company-activities.component.html',
  styleUrls: ['./company-activities.component.scss']
})
export class CompanyActivitiesComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: DataSource;
  loading = false;
  activityTypes = [
    { id: 1, description: 'Slab' },
    { id: 2, description: 'Practical Completion' }
  ];
  gridHeight: number;
  isActiveCampaignEnabled: boolean;
  isHubSpotEnabled: boolean;
  daysToUpdateLeadManagement = 2;
  updateLeadManagementPopupOpen: boolean;
  stageMustBeDifferentToActivity = true;

  constructor(
    private companyActivityService: CompanyActivityService,
    private authService: AuthService,
    private globalService: GlobalService,
    public gridService: GridService,
    private notiService: NotificationService
  ) {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(() => {
        this.gridHeight = window.innerHeight - 110;
      })
    );
  }

  ngOnInit(): void {
    this.gridHeight = window.innerHeight - 110;

    let leadManagement = this.globalService.getCompanyConfigValue(ConfigurationEnum.ActiveCampaignOrHubSpotIntegrationEnabled);
    if (leadManagement === 1 || leadManagement === 2) {
      this.isActiveCampaignEnabled = true;
    }
    else if (leadManagement === 3 || leadManagement === 4) {
      this.isHubSpotEnabled = true;
    }

    this.setUpDataSource();
  }

  refresh() {
    this.companyActivityService.cache = null;
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.companyActivityService.getCompanyActivities().subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.companyActivityService.addCompanyActivity(values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.companyActivityService.updateCompanyActivity(encodeURIComponent(key), values).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.companyActivityService.deleteCompanyActivity(encodeURIComponent(key)).subscribe({
            next: () => {
              return resolve();
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  ngOnDestroy() {
    this.companyActivityService.cache = null;
  }

  toolbar(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          type: 'default',
          stylingMode: 'outlined',
          icon: 'refresh',
          onClick: this.refresh.bind(this)
        }
      });

    let updateLeadManagementButtonText = 'Run Update to Active Campaign';
    if (this.isHubSpotEnabled) {
      updateLeadManagementButtonText = 'Run Update to HubSpot';
    }
    if (this.authService.isAdminOrSuper() && (this.isActiveCampaignEnabled || this.isHubSpotEnabled)) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            type: 'default',
            stylingMode: 'outlined',
            text: updateLeadManagementButtonText,
            onClick: this.updateToLeadManagement.bind(this)
          }
        });
    }
  }

  updateToLeadManagement() {
    this.updateLeadManagementPopupOpen = true;
  }

  runUpdateToLeadManagement() {
    this.updateLeadManagementPopupOpen = false;
    let leadManagementSystem = 'Active Campaign';
    if (this.isHubSpotEnabled) {
      leadManagementSystem = 'HubSpot';
    }
    this.grid.instance.beginCustomLoading('Updating ' + leadManagementSystem + ' (last ' + this.daysToUpdateLeadManagement + ' days)');

    const todaysDate = new Date();
    const newDate = new Date(todaysDate.valueOf());
    newDate.setDate(todaysDate.getDate() - this.daysToUpdateLeadManagement);

    const dateString = newDate.getFullYear() + '-'
      + ('0' + (newDate.getMonth() + 1)).toString().slice(-2) + '-'
      + ('0' + newDate.getDate()).slice(-2);

    this.subscriptions.push(
      this.companyActivityService.runUpdateToLeadManagement(dateString, this.stageMustBeDifferentToActivity).subscribe({
        next: () => {
          this.grid.instance.endCustomLoading();
        }, error: (err) => {
          this.notiService.notify(err);
          this.grid.instance.endCustomLoading();
        }
      })
    );
  }

}
